// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const PrimarySchool = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>primary-school</title>
      <path d="M9.918 16.275l2.702 10.207h-2.144l-0.472-2.158h-2.716l-0.472 2.158h-2.144l2.702-10.207h2.545zM9.646 22.637l-0.986-4.59h-0.029l-0.986 4.59h2.001zM16.065 12.098c0.419 0 0.807 0.043 1.165 0.129s0.664 0.229 0.922 0.43c0.257 0.2 0.457 0.466 0.6 0.8s0.214 0.743 0.214 1.229c0 0.554-0.129 1.027-0.386 1.422s-0.643 0.666-1.158 0.807v0.029c0.572 0.077 1.024 0.32 1.358 0.73s0.5 0.958 0.5 1.644c0 0.4-0.052 0.782-0.157 1.143s-0.284 0.679-0.536 0.95c-0.253 0.271-0.581 0.489-0.986 0.65s-0.908 0.243-1.508 0.243h-3.474v-10.206h3.446zM15.264 16.288c0.572 0 0.989-0.102 1.251-0.307s0.394-0.551 0.394-1.037c0-0.486-0.119-0.83-0.358-1.030s-0.615-0.3-1.129-0.3h-0.743v2.674h0.585zM15.478 20.789c0.495 0 0.896-0.11 1.201-0.328 0.306-0.22 0.458-0.625 0.458-1.216 0-0.294-0.041-0.54-0.122-0.735s-0.19-0.353-0.329-0.472c-0.138-0.118-0.303-0.202-0.494-0.25s-0.4-0.071-0.63-0.071h-0.886v3.072h0.801zM24.663 18.097c-0.177-0.338-0.498-0.507-0.965-0.507-0.257 0-0.47 0.069-0.636 0.207s-0.298 0.362-0.394 0.673c-0.095 0.31-0.162 0.714-0.2 1.214s-0.057 1.114-0.057 1.837c0 0.773 0.026 1.396 0.078 1.873s0.134 0.846 0.243 1.107c0.11 0.263 0.248 0.438 0.414 0.53s0.359 0.136 0.578 0.136c0.181 0 0.35-0.031 0.508-0.093 0.157-0.063 0.293-0.186 0.407-0.372s0.205-0.448 0.272-0.786c0.066-0.338 0.1-0.783 0.1-1.337h2.058c0 0.554-0.043 1.078-0.129 1.573s-0.246 0.93-0.479 1.301c-0.234 0.372-0.562 0.663-0.986 0.873-0.425 0.21-0.975 0.314-1.651 0.314-0.772 0-1.386-0.123-1.844-0.371s-0.808-0.605-1.051-1.073c-0.242-0.466-0.4-1.024-0.472-1.673s-0.107-1.362-0.107-2.144c0-0.771 0.036-1.485 0.107-2.137s0.229-1.212 0.472-1.68c0.243-0.466 0.594-0.83 1.051-1.094 0.457-0.262 1.072-0.394 1.844-0.394 0.734 0 1.313 0.12 1.737 0.358s0.741 0.541 0.95 0.907 0.341 0.762 0.394 1.186c0.052 0.423 0.078 0.822 0.078 1.194h-2.058c-0.001-0.743-0.089-1.285-0.265-1.623z" />
    </g>
  </Icon>
)

export default React.memo(PrimarySchool)
