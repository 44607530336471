import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import styled, { css, ThemeProvider } from 'styled-components'
import { compose } from 'recompose'
import { useRouter } from 'next/router'

import GlobalStyle from '../../styles/global-style'

import Error from '../Error'
import { InfoPopup } from '../../ui'
import { toggleDialog } from '../../store/ui/actions'
import { getNabolagInfo } from '../../store/nabolag/selectors'

import media from './media'
import Menu from './Menu'
import Footer from './Footer'
import { useTranslation } from '../../../i18n'
import { withTheme } from '../../hocs/withTheme'
import { isMobileSelector } from '../../store/ui/selectors'

const Wrapper = styled.div`
  min-height: calc(100vh - 54px);
  height: 100%;
  background: ${({ theme }) => theme.pageBGColor || 'transparent'};
  color: ${({ theme }) => theme.fontColor || 'black'};
  font-family: ${({ theme }) => theme.fontFamily};
  overflow: auto;
  position: relative;
  top: 54px;

  ${({ noScroll }) =>
    noScroll &&
    `
    overflow: hidden;
  `};
`
const Container = styled.section`
  max-width: ${({ theme }) => theme.media.sizes.large}px;
  padding: 0 ${({ theme }) => theme.spacingLarge};
  margin: 0 auto 0 auto;
  background: transparent;

  ${({ styleWide }) =>
    styleWide &&
    `
    max-width: 100%;
  `}

  ${({ styleNopadding }) =>
    styleNopadding &&
    `
    padding: 0;
  `}

  ${({ styleNoPaddingSide }) =>
    styleNoPaddingSide &&
    `
    padding-left: 0;
    padding-right: 0;
  `}

  ${media.small`
    padding: 0;
  `};
`

const Article = styled.article`
  display: block;
  box-sizing: border-box;
  background-clip: padding-box;
  position: relative;
  z-index: 0;
  background-color: ${({ theme }) => theme.contentBGColor};
  width: 100%;
  padding: ${({ theme }) => theme.spacing} 0 0 0;

  ${({ styleNopadding }) =>
    styleNopadding &&
    css`
      padding: 0;
    `};

  ${({ styleFixed }) =>
    styleFixed &&
    css`
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 54px;
    `};
`

const Page = ({
  children,
  fixed,
  error,
  wide,
  nopadding,
  noPaddingSide,
  noMenu,
  noFooter,
  finnkode,
  displayBackToFinnLink,
  displayToOverviewLink,
  nabolag,
  theme,
  ui,
  toggleInfoDialog,
}) => {
  const router = useRouter()
  const { query } = router
  const isApp = !!query.app

  const isMobile = useSelector(isMobileSelector)

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <Wrapper theme={theme} noScroll={ui.dialogOpen}>
        <Container
          theme={theme}
          styleWide={wide}
          styleNopadding={nopadding}
          styleNoPaddingSide={noPaddingSide}
        >
          <Menu
            wide={wide}
            noMenu={noMenu || !finnkode}
            finnkode={finnkode}
            orderLineId={nabolag.orderLineId}
            displayBackToFinnLink={!!(displayBackToFinnLink && !error && !isApp)}
            displayToOverviewLink={!!(displayToOverviewLink && !error)}
          />
          <Article theme={theme} styleNopadding={nopadding} styleFixed={fixed}>
            {error ? (
              <Error
                title={t(`common:error.${error.type}.title`)}
                text={t(`common:error.${error.type}.text`, { id: error.nabolagId })}
                message=""
                type={error.type}
                link={error.link}
              />
            ) : (
              children
            )}
          </Article>
          {ui.dialogOpen ? (
            <InfoPopup
              title={t('common:statisticsTitle')}
              text={t('common:statisticsText')}
              closeCallback={() => toggleInfoDialog()}
            />
          ) : null}
        </Container>
        {!noFooter ? <Footer finnkode={finnkode} isMobile={isMobile} /> : null}

        <GlobalStyle />
      </Wrapper>
    </ThemeProvider>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  finnkode: PropTypes.string,
  error: PropTypes.shape(),
  wide: PropTypes.bool,
  fixed: PropTypes.bool,
  nopadding: PropTypes.bool,
  noPaddingSide: PropTypes.bool,
  noFooter: PropTypes.bool,
  noMenu: PropTypes.bool,
  displayBackToFinnLink: PropTypes.bool,
  displayToOverviewLink: PropTypes.bool,
  nabolag: PropTypes.shape({
    orderLineId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  ui: PropTypes.shape({ dialogOpen: PropTypes.bool.isRequired }).isRequired,
  toggleInfoDialog: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
}

Page.defaultProps = {
  error: null,
  wide: false,
  nopadding: false,
  noPaddingSide: false,
  noMenu: false,
  finnkode: '',
  displayBackToFinnLink: false,
  displayToOverviewLink: false,
  noFooter: false,
  fixed: false,
}

const mapStateToProps = (state) => ({
  nabolag: getNabolagInfo(state),
  ui: state.ui,
})

const mapDispatchToProps = (dispatch) => ({ toggleInfoDialog: () => dispatch(toggleDialog()) })

export default compose(React.memo, withTheme, connect(mapStateToProps, mapDispatchToProps))(Page)
