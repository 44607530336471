// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const SchoolBus = props => (
  <Icon viewBox="0 0 460 460" {...props}>
    <g>
      <circle
        cx="120.476"
        cy="417.399"
        r="40.122"
        style={{
          fill: '#59595A',
        }}
      />
      <circle
        cx="338.949"
        cy="417.399"
        r="40.122"
        style={{
          fill: '#59595A',
        }}
      />
      <rect
        y="123.981"
        width="41.915"
        height="93.412"
        style={{
          fill: '#59595A',
        }}
      />
      <rect
        x="418.085"
        y="123.981"
        width="41.915"
        height="93.412"
        style={{
          fill: '#59595A',
        }}
      />
      <path
        d="M418.085,417.401V82.7c0-52.032-42.173-80.221-94.196-80.221H135.526
	C83.534,2.479,41.32,30.687,41.34,82.7v334.701H418.085z M72.732,285.057V96.675h313.941v188.383H72.732z"
        style={{
          fill: '#E5C400',
        }}
      />
      <rect
        x="72.732"
        y="96.675"
        width="313.941"
        height="188.383"
        style={{
          fill: '#90C5FF',
        }}
      />
      <path
        d="M126.56,358.703c0,6.805-5.515,12.329-12.319,12.329H84.506c-6.785,0-12.309-5.524-12.309-12.329
	v-29.765c0-6.805,5.524-12.3,12.309-12.3h29.735c6.804,0,12.319,5.495,12.319,12.3V358.703z"
        style={{
          fill: '#FFFFFF',
        }}
      />
      <path
        d="M388.012,361.421c0,6.804-5.515,12.329-12.319,12.329h-29.775c-6.795,0-12.289-5.525-12.289-12.329
	v-29.766c0-6.784,5.495-12.299,12.289-12.299h29.775c6.804,0,12.319,5.515,12.319,12.299V361.421z"
        style={{
          fill: '#FFFFFF',
        }}
      />
      <g>
        <g>
          <polygon
            points="218.317,315.398 190.971,327.44 71.612,56.575 
			98.947,44.524 		"
            style={{
              opacity: '0.2',
              fill: '#FFFFFF',
              enableBackground: 'new    ',
            }}
          />
        </g>
        <g>
          <polygon
            points="268.831,307.632 241.476,319.674 122.116,48.809 
			149.433,36.758 		"
            style={{
              opacity: '0.2',
              fill: '#FFFFFF',
              enableBackground: 'new    ',
            }}
          />
        </g>
      </g>
      <polygon
        points="182.452,239.928 174.111,234.411 224.703,157.926 224.703,96.546 234.703,96.546 
	234.703,160.934 "
        style={{
          fill: '#59595A',
        }}
      />
      <rect
        x="122.077"
        y="36.223"
        width="221.094"
        height="32.959"
        style={{
          fill: '#ABCB57',
        }}
      />
      <rect
        x="165.3"
        y="330.224"
        width="135.223"
        height="10"
        style={{
          fill: '#59595A',
        }}
      />
      <rect
        x="165.3"
        y="352.882"
        width="135.223"
        height="10"
        style={{
          fill: '#59595A',
        }}
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(SchoolBus)
