import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const CrossCountry = props => (
  <Icon viewBox="0 0 447.6 447.6" {...props}>
    <g>
      <path
        d="M336.6,342.4h-47.9l0.6-1.5c6.8-22.2,4.2-45.5-7.3-65.7c-6.8-12-16.3-21.8-27.5-29l15.6-61.9
		c3.7-14.6-5.2-29.4-19.8-33.1c-3.2-0.8-6.4-1-9.5-0.7c-0.2,0-0.4,0-0.5,0c-31.5-1.3-62.3,10.3-85.2,31.5l-60.3-38.2
		c-5.2-3.3-12.2-1.8-15.5,3.5c-3.3,5.2-1.8,12.2,3.5,15.5l57.7,36.5c-3.1,7.3-1.2,16,5.3,21.2c3.4,2.7,7.4,4,11.4,4
		c5.3,0,10.6-2.3,14.1-6.8l0.6-0.7c10.5-13,24.8-22.4,40.5-27l-13.4,53.3c-1.3,5.1-1,10.2,0.5,14.9c-15.6,21.7-34.4,40.8-55.9,56.9
		l-17.7,13c-4.8,3.6-7.3,9.4-7.3,14.3h-64c-5.5,0-9.8,5-9.8,10.5v2.5c0,5.5,4.3,10,9.8,10h66.8h195.4h66.8c11,0,19.6-8.9,19.6-20v-3
		L336.6,342.4L336.6,342.4z M230.8,276.4c0.9-0.2,1.8-0.3,2.6-0.6c7,4.1,12.8,10.1,16.9,17.3c6.5,11.4,8,24.7,4.1,37.3l-2.2,6.9
		c-0.5,1.8-0.8,3.9-0.8,5.1h-84.7C191.6,324.2,213,301.7,230.8,276.4z M246.6,113.6c0-18.6,15.1-33.7,33.7-33.7
		c18.6,0,33.7,15.1,33.7,33.7s-15.1,33.7-33.7,33.7C261.7,147.3,246.6,132.2,246.6,113.6z"
      />
    </g>
  </Icon>
)

export default React.memo(CrossCountry)
