// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Chat = props => (
  <Icon viewBox="0 0 58 58" {...props}>
    <style>{'.chat-st0{fill:#3BA58B;}.chat-st1{fill:#FFFFFF;}'}</style>
    <g>
      <g>
        <path
          className="chat-st0"
          d="M0.8,27.5c0,14,12.6,25.3,28.2,25.3c4.3,0,8.3-0.9,12-2.4c6.5,4,16.2,5.3,16.2,5.3c-3.2-3.2-4.3-9.7-4.7-14.3 c2.9-4,4.7-8.8,4.7-13.9c0-14-12.6-25.3-28.2-25.3S0.8,13.6,0.8,27.5z"
        />
        <circle className="chat-st1" cx="43.7" cy="27.5" r="3.4" />
        <circle className="chat-st1" cx="29" cy="27.5" r="3.4" />
        <circle className="chat-st1" cx="14.3" cy="27.5" r="3.4" />
      </g>
      <text
        transform="matrix(1 0 0 1 62 480.28)"
        style={{
          fontFamily: '"MyriadPro-Regular"',
          fontSize: '12px',
        }}
      >
        z
      </text>
    </g>
  </Icon>
)

export default React.memo(Chat)
