// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Businesses = props => (
  <Icon viewBox="-7 0 60 40" {...props}>
    <path d="M28.1253 26.5177C28.1253 26.953 27.966 27.3301 27.6482 27.6477C27.3301 27.9662 26.9532 28.1251 26.5179 28.1251H18.4823C18.0467 28.1251 17.6702 27.9662 17.3521 27.6477C17.034 27.3302 16.875 26.9531 16.875 26.5177V22.5H0V34.5537C0 35.6584 0.393246 36.6041 1.18018 37.3909C1.96693 38.1777 2.91297 38.5711 4.01795 38.5711H40.9823C42.087 38.5711 43.033 38.1777 43.8198 37.3909C44.6069 36.6041 45 35.6584 45 34.5537V22.5H28.1253V26.5177Z" />
    <path d="M25.7145 22.5H19.2854V25.7143H25.7145V22.5Z" />
    <path d="M43.8197 7.60878C43.033 6.82185 42.0869 6.42861 40.9822 6.42861H32.1427V2.41057C32.1427 1.74089 31.9085 1.17182 31.4396 0.703022C30.9712 0.234487 30.4017 0 29.7323 0H15.268C14.5985 0 14.0293 0.234487 13.5604 0.703022C13.0915 1.17156 12.8573 1.7408 12.8573 2.41057V6.42861H4.01795C2.91306 6.42861 1.96702 6.82185 1.18018 7.60878C0.393246 8.39563 0 9.34114 0 10.4461V20.0891H44.9999V10.4461C44.9999 9.34114 44.6069 8.39563 43.8197 7.60878ZM28.9287 6.42861H16.0715V3.21413H28.9287V6.42861Z" />
  </Icon>
)

export default React.memo(Businesses)
