import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import media from '../components/layout/media'

const H1 = styled.h1`
  ${({ theme }) =>
    theme.headings.h1.fontFamily &&
    css`
      font-family: ${theme.headings.h1.fontFamily};
    `};

  ${({ theme }) =>
    theme.headings.h1.color &&
    css`
      color: ${theme.headings.h1.color};
    `};

  font-size: ${props => props.theme.headings.h1.size};
  font-weight: ${props => props.theme.headings.h1.weight};
  letter-spacing: 0;
  margin: 20px 0;

  ${media.small`
    font-size: 30px;
    margin: 15px 0;
  `};
`

H1.propTypes = {
  theme: PropTypes.shape({
    headings: PropTypes.shape({
      h1: PropTypes.shape({
        size: PropTypes.string,
        weight: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }),
}

export { H1 }

const H2 = styled.h2`
 ${({ theme }) =>
   theme.headings.h2.fontFamily &&
   css`
     font-family: ${theme.headings.h2.fontFamily};
   `};
   ${({ theme }) =>
     theme.headings.h2.color &&
     css`
       color: ${theme.headings.h2.color};
     `};
  font-size: ${props => props.theme.headings.h2.size};
  font-weight: 300;
  margin: 20px 0;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${media.small`
    ${({ noResponsive }) =>
      !noResponsive &&
      css`
        font-size: 20px;
      `}
  `}

  ${({ small }) =>
    small &&
    css`
      font-size: 20px;
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}
`

H2.propTypes = {
  noResponsive: PropTypes.bool,
  bold: PropTypes.bool,
  theme: PropTypes.shape({
    headings: PropTypes.shape({
      h2: PropTypes.shape({
        size: PropTypes.string,
        weight: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }),
}

H2.defaultProps = {
  noResponsive: false,
  bold: false,
}

export { H2 }
