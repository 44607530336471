import React from 'react'
import {
  Drive,
  Family,
  People,
  ShoppingMall,
  LivingEnvironment,
  Football,
  Businesses,
} from '../../poi-icons'

export const getIcon = type => {
  switch (type) {
    case 'family':
      return <Family size="small" />
    case 'transport':
      return <Drive size="small" />
    case 'people':
      return <People size="small" />
    case 'shopping':
      return <ShoppingMall size="small" />
    case 'leisure':
    case 'activities':
      return <Football size="small" />
    case 'environment':
      return <LivingEnvironment size="small" />
    case 'businesses':
      return <Businesses size="small" />
    default:
      return null
  }
}
