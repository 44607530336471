// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const ElectricCar = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>electric-car</title>
      <path d="M30.088 9.784h-0.561v-1.822c0-0.239-0.194-0.434-0.434-0.434s-0.434 0.194-0.434 0.434v1.822h-2.321v-1.822c0-0.239-0.194-0.434-0.434-0.434s-0.433 0.194-0.433 0.434v1.822h-0.587c-0.239 0-0.434 0.194-0.434 0.434l0.336 3.134c0 0.24 0.194 0.434 0.434 0.434h0.858l-0.022 1.419c0 0.365 0.289 0.661 0.651 0.677v2.566c-0.010 0.223-0.098 0.674-0.634 0.754h-4.744v-1.667c0-0.674-0.198-1.74-0.44-2.369l-0.346-0.898h0.479c0.619 0 1.124-0.506 1.124-1.124s-0.505-1.123-1.124-1.123h-1.343l-0.974-2.533c-0.242-0.63-0.97-1.295-1.618-1.48 0 0-1.681-0.48-5.153-0.48s-5.153 0.48-5.153 0.48c-0.648 0.186-1.376 0.851-1.618 1.481l-0.974 2.532h-1.343c-0.618 0-1.124 0.505-1.124 1.123s0.506 1.124 1.124 1.124h0.479l-0.346 0.898c-0.242 0.629-0.439 1.695-0.439 2.369v5.31c0 0.786 0.643 1.43 1.429 1.43s1.429-0.644 1.429-1.43v-0.817h13.071v0.817c0 0.786 0.644 1.43 1.429 1.43 0.787 0 1.43-0.644 1.43-1.43v-1.962l5.63 0.007c0.719 0 1.384-0.694 1.427-1.341l0.003-3.67c0.351-0.027 0.631-0.317 0.631-0.676l0.022-1.419h0.756c0.239 0 0.434-0.194 0.434-0.434l0.292-3.134c0-0.239-0.195-0.434-0.434-0.434zM5.335 13.475l1.147-3.113c0.078-0.211 0.325-0.384 0.55-0.384h9.803c0.225 0 0.473 0.173 0.55 0.384l1.147 3.113c0.077 0.211-0.042 0.383-0.266 0.383h-12.664c-0.225 0-0.345-0.172-0.267-0.383zM7.083 17.977c0 0.319-0.26 0.58-0.579 0.58h-1.738c-0.318 0-0.578-0.26-0.578-0.58v-1.089c0-0.318 0.26-0.579 0.578-0.579l1.738 0.173c0.318 0 0.579 0.26 0.579 0.579v0.915zM19.679 17.977c0 0.319-0.26 0.58-0.579 0.58h-1.736c-0.319 0-0.58-0.26-0.58-0.58v-0.915c0-0.318 0.261-0.579 0.58-0.579l1.736-0.173c0.319 0 0.579 0.26 0.579 0.579v1.089z" />
    </g>
  </Icon>
)

export default React.memo(ElectricCar)
