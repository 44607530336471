// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const GasStation = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>gas-station</title>
      <path d="M26.528 11.505l-4.2-3.966c-0.274-0.274-0.716-0.274-0.99 0s-0.274 0.716 0 0.99l1.662 1.565v2.067c0 0.495 0.197 0.97 0.546 1.32l1.786 1.786v8.166c0 0.643-0.523 1.166-1.166 1.166s-1.166-0.523-1.166-1.166v-5.134c0-1.158-0.942-2.1-2.1-2.1h-0.7v-9.333c0-0.774-0.626-1.4-1.4-1.4h-11.2c-0.774 0-1.4 0.626-1.4 1.4v19.134c-0.515 0-0.934 0.418-0.934 0.934v0.933h15.866v-0.934c0-0.515-0.418-0.934-0.934-0.934v-8.4h0.7c0.386 0 0.7 0.314 0.7 0.7v5.134c0 1.415 1.151 2.566 2.566 2.566s2.566-1.152 2.566-2.566v-11.433c0.002-0.186-0.073-0.364-0.204-0.495zM7.6 8.267h11.2v7h-11.2v-7z" />
    </g>
  </Icon>
)

export default React.memo(GasStation)
