// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Groceries = props => (
  <Icon viewBox="0 0 54 54" {...props}>
    <g>
      <path
        style={{ fill: '#436B1C' }}
        d="M11.217,19.913c-0.335,0-0.663-0.169-0.852-0.476c-0.614-0.995-1.761-2.146-3.078-2.104
        c-0.56-0.03-1.014-0.418-1.03-0.969c-0.017-0.553,0.417-1.014,0.969-1.031c2.107-0.063,3.904,1.534,4.841,3.055
        c0.29,0.47,0.144,1.086-0.326,1.376C11.578,19.865,11.396,19.913,11.217,19.913z"
      />
      <g>
        <path
          style={{ fill: '#88C057' }}
          d="M31.684,6.597c-0.134-1.948-1.753-3.488-3.735-3.488c-0.006,0-0.011,0.001-0.017,0.001
          c0.035-0.295,0.015-0.607-0.08-0.932c-0.268-0.914-1.072-1.616-2.018-1.729c-0.776-0.092-1.488,0.186-1.987,0.679
          c-0.475-0.429-1.097-0.697-1.787-0.697c-1.479,0-2.677,1.199-2.677,2.677c0,0.005,0.001,0.009,0.001,0.014
          c-1.797,0.136-3.214,1.635-3.214,3.467c0,0.28,0.037,0.551,0.099,0.812c-2.139,0.089-3.847,1.847-3.847,4.008
          c0,0.732,0.199,1.416,0.541,2.007c-0.657,0.54-1.077,1.359-1.077,2.276c0,0.838,0.355,1.589,0.917,2.124
          c2.412-1.971,4.946,4.621,7.93,9.884c0.991,1.748,11.698-0.114,12.551,0.972c0.972-0.874,1.6-16.094,1.627-17.431
          C34.953,9.096,33.591,7.266,31.684,6.597z"
        />
      </g>
      <path
        style={{ fill: '#EEAF4B' }}
        d="M33.053,26.533l0.713-0.427c1.32-0.792,2.968-0.792,4.287,0l0.713,0.427
        c1.32,0.792,2.968,0.792,4.287,0l2.015-0.896c0.296-0.826,0.502-1.424,0.584-1.689c1.88-6.047,3.091-12.414,3.256-18.765
        c0.066-2.554-2.046-6.537-5.555-4.727c-3.449,1.778-5.919,8.597-7.655,11.835c-2.466,4.601-4.416,9.605-5.805,14.704
        C30.951,27.262,32.089,27.111,33.053,26.533z"
      />
      <path
        style={{ fill: '#ED3F32' }}
        d="M7.055,25.506L8.4,26.313c1.545,0.927,3.475,0.927,5.02,0l0.346-0.208
          c1.32-0.792,2.968-0.792,4.287,0l0.713,0.427c0.775,0.465,1.663,0.649,2.531,0.568c0.64-2.067,0.425-4.651-0.567-6.4
          c-3.336-5.885-6.111-4.686-8.781-2.131c-0.512,0.49-1.138,0.849-1.82,1.044c-2.928,0.837-5.089,2.206-3.86,5.934
          C6.486,25.389,6.788,25.346,7.055,25.506z"
      />
      <path
        style={{ fill: '#A46F3E' }}
        d="M35.592,24.356c-0.106,0-0.215-0.017-0.321-0.053c-0.523-0.178-0.803-0.746-0.625-1.269
        c0.667-1.962,2.809-3.012,4.768-2.352c0.919,0.313,1.92-0.181,2.232-1.1c0.177-0.523,0.743-0.805,1.269-0.626
        c0.523,0.178,0.803,0.746,0.625,1.269c-0.666,1.963-2.806,3.017-4.768,2.352c-0.918-0.312-1.919,0.183-2.232,1.1
        C36.397,24.095,36.008,24.356,35.592,24.356z"
      />
      <path
        style={{ fill: '#A46F3E' }}
        d="M37.957,17.389c-0.106,0-0.215-0.017-0.321-0.053c-0.523-0.178-0.803-0.746-0.625-1.269
        c0.323-0.951,0.997-1.72,1.897-2.164c0.9-0.444,1.919-0.511,2.871-0.188c0.921,0.314,1.92-0.181,2.232-1.101
        c0.177-0.524,0.745-0.805,1.268-0.626c0.523,0.178,0.803,0.746,0.625,1.269c-0.666,1.964-2.806,3.016-4.768,2.353
        c-0.445-0.152-0.923-0.121-1.344,0.087s-0.736,0.567-0.888,1.013C38.763,17.127,38.374,17.389,37.957,17.389z"
      />
      <path
        style={{ fill: '#A46F3E' }}
        d="M40.323,10.421c-0.106,0-0.215-0.017-0.322-0.053c-0.523-0.178-0.803-0.746-0.625-1.269
        c0.584-1.722,2.463-2.646,4.185-2.064c0.33,0.112,0.682,0.09,0.993-0.063c0.311-0.153,0.544-0.419,0.656-0.748
        c0.177-0.523,0.744-0.804,1.269-0.626c0.523,0.178,0.803,0.746,0.625,1.269c-0.284,0.835-0.875,1.51-1.665,1.899
        c-0.791,0.39-1.686,0.447-2.521,0.164c-0.678-0.231-1.418,0.134-1.648,0.813C41.128,10.159,40.739,10.421,40.323,10.421z"
      />
      <g>
        <path
          style={{ fill: '#DAE7EF' }}
          d="M23.37,14.095c-0.945,1.257-2.436,2.745-1.862,5.373l1.544,7.065c0,0,0.001,0,0.001,0l0.713-0.427
          c1.32-0.792,2.968-0.792,4.287,0l0.713,0.427c0.58,0.348,1.127,0.463,1.127,0.463c1.042-3.823,2.399-7.593,4.052-11.179
          c-0.774-1.936-2.427-2.584-3.627-3.247c-0.007-0.004-0.014-0.008-0.02-0.011c-0.759-0.435-0.806-1.474-0.235-2.136
          c0.225-0.261,0.329-0.62,0.251-0.98l-0.121-0.552c-0.133-0.608-0.739-0.996-1.346-0.863L22.77,9.354
          c-0.608,0.133-0.996,0.739-0.863,1.346l0.121,0.552c0.08,0.368,0.334,0.653,0.656,0.792c0.739,0.319,1.19,1.144,0.812,1.855
          C23.46,13.966,23.418,14.031,23.37,14.095z"
        />
      </g>
      <path
        style={{ fill: '#FFFFFF' }}
        d="M29.746,10.899l-6.879,1.503c-0.759,0.166-1.517-0.32-1.683-1.079l-0.099-0.452
        c-0.166-0.759,0.32-1.517,1.079-1.683l6.879-1.503c0.759-0.166,1.517,0.32,1.683,1.079l0.099,0.452
        C30.991,9.976,30.505,10.733,29.746,10.899z"
      />
      <path
        style={{ fill: '#FBD490' }}
        d="M46.816,25.332l-1.745,25.029c-0.128,2.499-2.191,4.459-4.693,4.459H12.307
        c-2.502,0-4.565-1.96-4.693-4.459L5.911,26.153c-0.029-0.569,0.588-0.94,1.077-0.647l1.345,0.807c1.545,0.927,3.475,0.927,5.02,0
        l0.346-0.208c1.32-0.792,2.968-0.792,4.287,0l0.713,0.428c1.32,0.792,2.968,0.792,4.287,0l0.713-0.428
        c1.32-0.792,2.968-0.792,4.287,0l0.713,0.428c1.32,0.792,2.968,0.792,4.287,0l0.713-0.428c1.32-0.792,2.968-0.792,4.287,0
        l0.713,0.428c1.32,0.792,2.968,0.792,4.287,0l3.387-1.505C46.588,24.932,46.828,25.097,46.816,25.332z"
      />
      <path
        style={{ fill: '#E0B877' }}
        d="M17.91,42h-7c-0.552,0-1-0.447-1-1s0.448-1,1-1h7c0.552,0,1,0.447,1,1S18.462,42,17.91,42z"
      />
      <path
        style={{ fill: '#E0B877' }}
        d="M19.91,46h-9c-0.552,0-1-0.447-1-1s0.448-1,1-1h9c0.552,0,1,0.447,1,1S20.462,46,19.91,46z"
      />
      <path
        style={{ fill: '#E0B877' }}
        d="M21.91,50h-11c-0.552,0-1-0.447-1-1s0.448-1,1-1h11c0.552,0,1,0.447,1,1S22.462,50,21.91,50z"
      />
      <path
        style={{ fill: '#E0B877' }}
        d="M27.91,50h-2c-0.552,0-1-0.447-1-1s0.448-1,1-1h2c0.552,0,1,0.447,1,1S28.462,50,27.91,50z"
      />
    </g>
  </Icon>
)

export default React.memo(Groceries)
