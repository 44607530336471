// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Ship = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <style>
      {`
      .ship-st0{fill:#C6C6C6;}
      .ship-st1{fill:#FFFFFF;}
      .ship-st2{fill:#809BAD;}
      .ship-st3{fill:#172C3A;}
      .ship-st4{fill:#64798A;}
      .ship-st5{fill:#27A2DB;}
      .ship-st6{fill:#D5D6DB;}
    `}
    </style>
    <rect x="140.3" y="53" className="ship-st0" width="132" height="66.7" />
    <path
      className="ship-st1"
      d="M295.9,134.8h-179c-16,0-29,13-29,29v64c0,16,13,29,29,29h179c16,0,29-13,29-29v-64
      C324.9,147.8,311.9,134.8,295.9,134.8z"
    />
    <path
      classNameName="ship-st2"
      d="M285.3,119.6H127.4c-30.2,0-54.8,24.6-54.8,54.7v42.9c0,30.2,24.6,54.8,54.8,54.8h157.9
      c30.2,0,54.8-24.6,54.8-54.8v-42.9C340.1,144.2,315.5,119.6,285.3,119.6z M309.7,217.3c0,13.4-10.9,24.4-24.4,24.4H127.4
      c-13.4,0-24.4-10.9-24.4-24.4v-42.9c0-13.4,10.9-24.4,24.4-24.4h157.9c13.4,0,24.4,10.9,24.4,24.4V217.3z"
    />
    <path
      className="ship-st3"
      d="M377,238.5l-172.4-69L34.7,248.7c-9.9,4.6-14.7,16-11,26.3l25.5,71.3v63.9h308.7v-63.9l30.9-80.8
      C392.9,254.8,387.7,242.8,377,238.5z"
    />
    <path
      className="ship-st4"
      d="M34.7,248.7c-9.9,4.6-14.7,16-11,26.3l25.5,71.3v63.9h155.4V169.6L34.7,248.7z"
    />
    <path
      className="ship-st5"
      d="M204.9,426.7c-20.5,0-33.5-8.3-40-14c-50.2,23.3-83.9,7.1-95.7-0.6c-33.1,14-63.4,7.3-64.7,7l3.8-16.6
      c0.3,0.1,28.9,6.2,58.3-7.9l5.1-2.4l4.2,3.7c1.3,1.1,32.3,26.8,87-1.2l6.5-3.3l4.3,5.9c0.8,1.1,22.7,28.6,79.9-2.5l3.8-2.1l3.9,1.8
      c0.4,0.2,42.8,19.3,89.6-0.1l4.1-1.7l3.7,2.4c0.1,0.1,21.1,12.5,60.9,6.7l2.5,16.9c-36.4,5.3-59.5-2.8-68.4-7
      c-44.1,16.8-83.2,4.8-95.6,0C236.6,422.8,219,426.7,204.9,426.7z"
    />
    <g>
      <path
        className="ship-st6"
        d="M284.9,347.4c-11.5,0-22.3-3.5-30.5-10c-8.7-6.9-13.7-16.3-14-26.6l10-0.4c0.3,7.3,3.9,14.1,10.3,19.1
        c6.8,5.4,16.1,8.2,26,7.8c21.9-0.8,29.2-15.7,28.7-29.4l10-0.4c0.7,19-10.8,38.7-38.3,39.8C286.4,347.4,285.7,347.4,284.9,347.4z"
      />
      <polygon className="ship-st6" points="258.6,308.9 240.4,295.5 232.4,316.7 	" />
      <polygon className="ship-st6" points="333.8,315 325,294.2 307.3,308.3 	" />
      <rect x="277.8" y="282.2" className="ship-st6" width="11.3" height="60.3" />
      <rect x="263.3" y="283.4" className="ship-st6" width="40.2" height="8" />
      <path
        className="ship-st6"
        d="M287.1,287.4h-5.5c-8.3,0-15-6.7-15-15v-9.5c0-8.3,6.7-15,15-15h5.5c8.3,0,15,6.7,15,15v9.5
        C302.2,280.7,295.4,287.4,287.1,287.4z M281.6,255.9c-3.9,0-7,3.1-7,7v9.5c0,3.9,3.1,7,7,7h5.5c3.9,0,7-3.1,7-7v-9.5
        c0-3.9-3.1-7-7-7H281.6z"
      />
      <polyline className="ship-st6" points="289.4,346.7 283.5,355.5 277.8,346.7 	" />
    </g>
  </Icon>
)

export default React.memo(Ship)
