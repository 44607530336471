import styled from 'styled-components'

const ViewInMap = styled.div`
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  ${({ theme }) => theme && `
    color: ${theme.brandColor};
    margin-left: ${theme.spacing};
    margin-right: ${theme.spacing};
    margin-top: ${theme.spacingSmall};
    a {
      color: ${theme.brandColor};
      text-decoration: none;
    }
  `}

  ${({ small }) => small && `
    margin: 0;
    margin-bottom: 9px;
    font-size: 15px;
  `}

  &:hover {
    cursor: pointer;
  }
`

export default ViewInMap
