// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Kindergarden = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>kindergarden</title>
      <path d="M25.28 16.24h-16.16c0 3.44 3.6 6.24 8.080 6.24s8.080-2.8 8.080-6.24v0zM16.4 15.52h8.88c-0.16-4.24-3.36-6.32-6.080-6.32-1.36 0.88-2.8 6.32-2.8 6.32zM9.2 15.52h0.96v-1.36c0-2.16-1.76-2.96-2.72-2.96-0.24 0-0.24 0.64 0 0.8 0.16 0.080 1.76 0.4 1.76 2.16v1.36zM12.48 23.12c-1.2 0-2.24 0.96-2.24 2.24 0 1.2 0.96 2.24 2.24 2.24s2.24-0.96 2.24-2.24c0-1.2-0.96-2.24-2.24-2.24v0zM21.92 23.12c-1.2 0-2.24 0.96-2.24 2.24 0 1.2 0.96 2.24 2.24 2.24s2.24-0.96 2.24-2.24c0-1.2-1.040-2.24-2.24-2.24v0z" />
    </g>
  </Icon>
)

export default React.memo(Kindergarden)
