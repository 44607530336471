import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const ParkingMeter = props => (
  <Icon width="60" height="60" viewBox="0 0 60 60" {...props}>
    <path
      d="M33.5156 42.1875V58.2422C33.5156 59.2264 32.7425 60 31.7578 60H28.2422C27.2575 60 26.4844 59.2264 26.4844 58.2422V42.1875H33.5156Z"
      fill="#CDD6E0"
    />
    <path
      d="M30 42.1875H33.5156V58.2422C33.5156 59.2264 32.7425 60 31.7578 60H30V42.1875Z"
      fill="#99A5AD"
    />
    <path
      d="M30 0C19.3474 0 10.6641 8.68332 10.6641 19.3359C10.6641 33.7148 21.2462 44.7185 21.703 45.1758C22.0546 45.5273 22.5119 45.7031 22.9688 45.7031H37.0312C37.4881 45.7031 37.9454 45.5273 38.297 45.1758C38.7538 44.7185 49.3359 33.7148 49.3359 19.3359C49.3359 8.68332 40.6526 0 30 0Z"
      fill="#99A5AD"
    />
    <path
      d="M49.3359 19.3359C49.3359 33.7148 38.7538 44.7185 38.297 45.1758C37.9454 45.5273 37.4881 45.7031 37.0312 45.7031H30V0C40.6526 0 49.3359 8.68332 49.3359 19.3359Z"
      fill="#555E64"
    />
    <path
      d="M42.3047 19.3359C42.3047 20.3201 41.5315 21.0938 40.5469 21.0938H31.7578L30 17.5781L28.2422 21.0938H19.4531C18.4685 21.0938 17.6953 20.3201 17.6953 19.3359C17.6953 12.551 23.215 7.03125 30 7.03125C36.785 7.03125 42.3047 12.551 42.3047 19.3359Z"
      fill="#DEECF1"
    />
    <path
      d="M33.5156 24.6094H26.4844C25.4997 24.6094 24.7266 25.3825 24.7266 26.3672V36.9141C24.7266 37.8983 25.4997 38.6719 26.4844 38.6719H33.5156C34.5003 38.6719 35.2734 37.8983 35.2734 36.9141V26.3672C35.2734 25.3825 34.5003 24.6094 33.5156 24.6094Z"
      fill="#BAECFD"
    />
    <path
      d="M35.2734 26.3672V36.9141C35.2734 37.8983 34.5003 38.6719 33.5156 38.6719H30V24.6094H33.5156C34.5003 24.6094 35.2734 25.3825 35.2734 26.3672Z"
      fill="#9DD2FB"
    />
    <path
      d="M42.3047 19.3359C42.3047 20.3201 41.5315 21.0938 40.5469 21.0938H31.7578L30 17.5781V7.03125C36.785 7.03125 42.3047 12.551 42.3047 19.3359Z"
      fill="#C5D3DD"
    />
    <path
      d="M31.7578 15.8203V21.0938H28.2422V15.8203C28.2422 14.8357 29.0154 14.0625 30 14.0625C30.9846 14.0625 31.7578 14.8357 31.7578 15.8203Z"
      fill="#F3654D"
    />
    <path
      d="M31.7578 15.8203V21.0938H30V14.0625C30.9846 14.0625 31.7578 14.8357 31.7578 15.8203Z"
      fill="#E63950"
    />
  </Icon>
)

export default ParkingMeter
