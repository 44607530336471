// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const School = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>school</title>
      <path d="M16 8.898l11.2 6.101v8.15h-2.050v-7.054l-9.15 5.004-11.2-6.1 11.2-6.101zM8.898 19.241l7.102 3.908 7.102-3.908v4.098l-7.102 3.861-7.102-3.861v-4.098z" />
    </g>
  </Icon>
)

export default React.memo(School)
