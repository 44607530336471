import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const DownhillSkiing = props => (
  <Icon width="51" height="55" viewBox="0 0 51 55" {...props}>
    <path
      d="M16.4623 36.9226L0.478606 29.3502C0.0647532 29.1488 -0.11421 28.6567 0.0759384 28.2317C0.277272 27.8178 0.769422 27.6388 1.19446 27.829L17.1781 35.3902C17.592 35.5915 17.771 36.0837 17.5808 36.5087C17.3907 36.9338 16.8761 37.1127 16.4623 36.9226Z"
      fill="#D7F7FF"
    />
    <path
      d="M20.4115 34.0144L4.42782 26.4532C4.01397 26.2519 3.83501 25.7597 4.02516 25.3347C4.22649 24.9208 4.71864 24.7419 5.14368 24.932L21.1274 32.4932C21.5412 32.6946 21.7202 33.1867 21.53 33.6118C21.3287 34.0368 20.8254 34.2046 20.4115 34.0144Z"
      fill="#D7F7FF"
    />
    <path
      d="M19.6381 29.1264L3.65439 21.554C3.24053 21.3527 3.06157 20.8606 3.25172 20.4355C3.45305 20.0217 3.9452 19.8427 4.37024 20.0328L20.3539 27.6053C20.7678 27.8066 20.9467 28.2987 20.7566 28.7238C20.5553 29.1376 20.0519 29.3166 19.6381 29.1264Z"
      fill="#D7F7FF"
    />
    <path
      d="M34.8411 31.1733L29 24L24.5 25L31.5079 30.7595C32.1119 31.4753 32.1902 32.7169 31.6868 33.511L26.0607 42.3586C25.8929 42.627 25.6468 42.8395 25.3672 42.9961L26.5 44L34.9977 33.9361C35.5122 33.1531 35.4339 31.9004 34.8411 31.1733Z"
      fill="#9DD2FB"
    />
    <path
      d="M31.5188 30.7595L26.2841 24.4622L20.4454 21.6995C19.8973 22.3147 19.8638 23.2654 20.4006 23.9141L26.2393 30.9497C26.8433 31.6655 26.9216 32.9071 26.4183 33.7012L23 40.5C22.8322 40.7684 24 41.7092 24 42L25.3893 43.0073C25.6689 42.8619 25.915 42.6382 26.0827 42.3698L31.7089 33.5223C32.2011 32.7169 32.1116 31.4865 31.5188 30.7595Z"
      fill="#9DD2FB"
    />
    <path
      d="M24.6222 36.229L22.0014 39.8981C21.2412 40.9623 21.6902 42.4578 22.9109 42.9273L25.6217 43.9699C26.441 44.285 27.37 44.0291 27.9123 43.3389L30.7835 39.6847C31.6275 38.6105 31.17 37.025 29.8835 36.5655L26.9223 35.508C26.0806 35.2074 25.1416 35.5017 24.6222 36.229Z"
      fill="#3A5792"
    />
    <path
      d="M30.7218 45.3562L26.2812 42.5C26.5609 42.6342 28.0038 44.3495 27.8696 44.6404L27.0418 46.3853C26.9076 46.6649 26.5721 46.7879 26.2812 46.6537L29.4019 48.1302C29.6816 48.2644 30.0283 48.1413 30.1625 47.8617L30.9902 46.1168C31.1245 45.8372 31.0014 45.4904 30.7218 45.3562Z"
      fill="#3A5792"
    />
    <path
      d="M38.0291 5.88354C36.2954 7.98637 36.2507 11.0847 38.0515 13.2546C40.0984 15.7154 43.756 16.0621 46.2167 14.0264C47.2793 13.1428 47.9504 11.9571 48.1965 10.7044L38.0291 5.88354Z"
      fill="#E9CFA5"
    />
    <path
      d="M38.0291 5.88354C36.2954 7.98637 36.2507 11.0847 38.0515 13.2546C38.0627 13.2658 38.085 13.2882 38.0962 13.2994L40.9485 7.25933L38.0291 5.88354Z"
      fill="#8E5B48"
    />
    <path
      d="M36.8646 7.44948C36.7863 7.61726 36.8534 7.81859 37.0212 7.89689L43.6876 11.0511C43.8553 11.1294 44.0567 11.0623 44.135 10.8945L44.7502 9.58586C44.8285 9.41808 44.7613 9.21675 44.5936 9.13845L37.9272 5.98421C37.7594 5.90592 37.5581 5.97303 37.4798 6.14081L36.8646 7.44948Z"
      fill="#3B88C3"
    />
    <path
      d="M22.4477 20.0077C22.4896 19.9722 22.5524 19.9778 22.5874 20.02L27.3217 25.7221C28.0266 26.571 29.2857 26.6887 30.1357 25.9852L38.1383 19.3617C40.1629 17.6839 40.4425 14.6863 38.7535 12.6617C37.0757 10.6372 34.0669 10.3576 32.0424 12.0354L22.3738 20.0497C22.3703 20.0526 22.37 20.058 22.3733 20.0613V20.0613C22.3762 20.0642 22.3808 20.0643 22.3839 20.0617L22.4477 20.0077Z"
      fill="#F3654D"
    />
    <path
      d="M27.3016 46.4971C27.1674 46.7767 26.8318 46.8998 26.541 46.7655L20.2213 43.7791C19.9417 43.6449 19.8187 43.3093 19.9529 43.0185L20.7806 41.2736C20.9148 40.994 21.2504 40.8709 21.5412 41.0051L27.8608 43.9916C28.1405 44.1258 28.2635 44.4614 28.1293 44.7522L27.3016 46.4971Z"
      fill="#3A5792"
    />
    <path
      d="M21.194 21.4757L21.619 20.5697L1.55271 11.0735C1.13885 10.8721 0.63552 11.0511 0.434186 11.4761C0.232852 11.89 0.411816 12.3933 0.836854 12.5947L21.1045 22.1916C21.0597 21.9455 21.0821 21.6994 21.194 21.4757Z"
      fill="#C1C4C6"
    />
    <path
      d="M28.2818 23.545L25.6309 22.2922L25.1388 23.3436C24.9934 23.6457 24.7473 23.8582 24.4453 23.9477L27.4206 25.357C27.7002 25.4912 28.047 25.3682 28.1812 25.0885L28.5503 24.3056C28.6845 24.0148 28.5615 23.6792 28.2818 23.545Z"
      fill="#526675"
    />
    <path
      d="M4.91635 16.0733C4.78213 16.3529 4.44657 16.476 4.15576 16.3417L3.27213 15.9167C2.9925 15.7825 2.86946 15.4469 3.00368 15.1561L4.91635 11.1294C5.05058 10.8498 5.38613 10.7268 5.67695 10.861L6.56058 11.286C6.84021 11.4202 6.96325 11.7558 6.82903 12.0466L4.91635 16.0733Z"
      fill="#526675"
    />
    <path
      d="M21.5749 22.9522C20.9933 22.6726 20.736 21.9791 21.0157 21.3863L21.519 20.3237C21.7986 19.7421 22.4921 19.4848 23.0849 19.7644L25.2101 20.7711C25.7917 21.0507 26.049 21.7442 25.7694 22.337L25.266 23.3996C24.9864 23.9813 24.2929 24.2385 23.7001 23.9589L21.5749 22.9522Z"
      fill="#3B88C3"
    />
    <path
      d="M36.1366 14.1046L30.7006 11.7334C29.4143 11.1741 27.8484 11.6215 27.0542 12.7624L23.6875 17.628C23.7658 17.6392 23.8441 17.6616 23.9224 17.7063L26.4502 19.3058C26.5174 19.3505 26.5733 19.4065 26.618 19.4736L29.6492 14.9659L34.7832 17.203C35.6445 17.5721 36.64 17.1918 37.0091 16.3305C37.3782 15.4804 36.9867 14.4738 36.1366 14.1046Z"
      fill="#FE9785"
    />
    <path
      d="M42.9935 8.24365L42.8258 11.0176C42.8034 11.3308 43.0271 11.6887 43.3067 11.8229L46.5952 13.3777C46.8748 13.5119 47.2216 13.3889 47.3558 13.1092L48.4407 10.8051L42.9935 8.24365Z"
      fill="#9DD2FB"
    />
    <path
      d="M49.0664 8.67982L49.7039 7.34878C50.856 4.92158 49.8158 2.0246 47.3774 0.872523L46.5161 0.469854C44.089 -0.682225 41.1808 0.358002 40.0399 2.7852L39.4023 4.11624L49.0664 8.67982Z"
      fill="#3B88C3"
    />
    <path
      d="M49.2679 10.7267C49.1337 11.0064 48.7981 11.1294 48.5073 10.9952L37.9596 5.99539C37.68 5.86117 37.557 5.52561 37.6912 5.2348L38.5189 3.4899C38.6531 3.21027 38.9887 3.08723 39.2795 3.22145L49.8271 8.22126C50.1068 8.35548 50.2298 8.69104 50.0956 8.98185L49.2679 10.7267Z"
      fill="white"
    />
    <path
      d="M49.2679 10.7267C49.1337 11.0064 48.7981 11.1294 48.5073 10.9952L37.9596 5.99539C37.68 5.86117 37.557 5.52561 37.6912 5.2348L38.5189 3.4899C38.6531 3.21027 38.9887 3.08723 39.2795 3.22145L49.8271 8.22126C50.1068 8.35548 50.2298 8.69104 50.0956 8.98185L49.2679 10.7267Z"
      fill="#3A5792"
    />
    <g opacity="0.1">
      <path
        opacity="0.1"
        d="M40.9461 6.77828L41.7738 5.03339C41.908 4.75375 42.2436 4.63072 42.5344 4.76494L39.2683 3.23256C38.9887 3.09834 38.6419 3.22138 38.5077 3.50101L37.6912 5.24591C37.557 5.52554 37.68 5.87228 37.9596 6.0065L41.2145 7.55006C40.9349 7.40466 40.8119 7.0691 40.9461 6.77828Z"
        fill="#1B003F"
      />
    </g>
    <path
      d="M6.28407 39.1708C6.12748 39.0925 6.06036 38.9024 6.12748 38.7458L7.03348 36.8331C7.11178 36.6765 7.30192 36.6094 7.45852 36.6877L40.0858 52.1345C40.7234 52.4365 41.4728 52.1681 41.7748 51.5305C42.0768 50.893 41.7971 50.1436 41.1708 49.8416L40.2088 49.3941C40.0522 49.3158 39.9851 49.1257 40.0522 48.9691L40.9582 47.0564C41.0365 46.8998 41.2267 46.8327 41.3833 46.911L42.3452 47.3584C44.3474 48.3092 45.2086 50.7028 44.2691 52.705C43.3295 54.7071 40.9247 55.5572 38.9114 54.6176L6.28407 39.1708Z"
      fill="#F3654D"
    />
    <g opacity="0.1">
      <path
        opacity="0.1"
        d="M10.4102 40.7703L11.3162 38.8576C11.3945 38.7011 11.5847 38.6339 11.7413 38.7122L7.45734 36.6877C7.30075 36.6094 7.1106 36.6765 7.03231 36.8331L6.1263 38.7458C6.04801 38.9024 6.11512 39.0925 6.2829 39.1708L10.5668 41.1954C10.4102 41.1171 10.3431 40.9269 10.4102 40.7703Z"
        fill="#1B003F"
      />
    </g>
    <path
      d="M22.3921 20.1111C22.1237 19.9433 22.0454 19.5966 22.2132 19.3281L23.1863 17.7846C23.354 17.5161 23.7008 17.4378 23.9692 17.6056L26.4971 19.2051C26.7655 19.3729 26.8438 19.7196 26.6761 19.9881L25.7029 21.5316C25.5352 21.8001 25.1884 21.8784 24.92 21.7106L22.3921 20.1111Z"
      fill="#3B88C3"
    />
  </Icon>
)

export default DownhillSkiing
