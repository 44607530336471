// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const ShoppingMall = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>shopping-mall</title>
      <path d="M24.784 9.947l0.746 1.494h-19.061l0.747-1.494h17.567zM25.707 8.454h-19.414l-1.494 2.986v16.426h22.4v-16.426l-1.493-2.986zM21.6 14.054c0 0.332-0.144 0.63-0.373 0.834v1.032c0 2.862-2.365 5.227-5.227 5.227s-5.227-2.365-5.227-5.227v-1.032c-0.229-0.205-0.373-0.502-0.373-0.834 0-0.618 0.502-1.12 1.12-1.12s1.12 0.502 1.12 1.12c0 0.332-0.145 0.63-0.374 0.834v1.032c0 2.038 1.696 3.734 3.734 3.734s3.734-1.696 3.734-3.734v-1.032c-0.229-0.205-0.374-0.502-0.374-0.834 0-0.618 0.502-1.12 1.12-1.12s1.12 0.502 1.12 1.12z" />
    </g>
  </Icon>
)

export default React.memo(ShoppingMall)
