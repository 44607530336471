// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const JusticeScale = props => (
  <Icon viewBox="0 0 64 64" {...props}>
    <g id="XMLID_103_">
      <path
        id="XMLID_127_"
        style={{ fill: '#F0C41B' }}
        d="M30.028,45.512c-0.553,0-1-0.447-1-1v-26c0-0.553,0.447-1,1-1c0.553,0,1,0.447,1,1
        v26C31.028,45.065,30.581,45.512,30.028,45.512"
      />
      <path
        id="XMLID_126_"
        style={{ fill: '#F0C41B' }}
        d="M30.028,9.512c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1c0.553,0,1,0.447,1,1v4
        C31.028,9.065,30.581,9.512,30.028,9.512"
      />
      <path
        id="XMLID_125_"
        style={{ fill: '#F3D55C' }}
        d="M6.028,24.512c-0.397,0-0.773-0.237-0.929-0.629
        c-0.205-0.513,0.045-1.095,0.558-1.3l19.525-7.81c0.508-0.205,1.095,0.044,1.3,0.557c0.205,0.513-0.045,1.095-0.558,1.3
        L6.399,24.441C6.278,24.49,6.152,24.512,6.028,24.512"
      />
      <path
        id="XMLID_124_"
        style={{ fill: '#F3D55C' }}
        d="M34.775,13.014c-0.385,0-0.751-0.222-0.916-0.596
        c-0.223-0.506,0.006-1.095,0.511-1.319l19.254-8.502c0.509-0.222,1.098,0.006,1.319,0.511c0.223,0.506-0.006,1.095-0.511,1.319
        l-19.254,8.502C35.046,12.987,34.91,13.014,34.775,13.014"
      />
      <path
        id="XMLID_123_"
        style={{ fill: '#F3D55C' }}
        d="M6.028,28.512c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1c0.553,0,1,0.447,1,1v4
        C7.028,28.065,6.581,28.512,6.028,28.512"
      />
      <path
        id="XMLID_122_"
        style={{ fill: '#F3D55C' }}
        d="M54.028,8.512c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1s1,0.447,1,1v4
        C55.028,8.065,54.581,8.512,54.028,8.512"
      />
      <path
        id="XMLID_119_"
        style={{ fill: '#F3D55C' }}
        d="M60.023,18.512h-11.99v-1c0-1.058,0.271-2.063,0.806-2.989l5.189-8.989l5.189,8.989
        c0.534,0.926,0.806,1.931,0.806,2.989V18.512z M50.161,16.512h7.734c-0.09-0.342-0.227-0.672-0.41-0.989l-3.457-5.989l-3.457,5.989
        C50.388,15.84,50.251,16.17,50.161,16.512"
      />
      <path
        id="XMLID_118_"
        style={{ fill: '#556180' }}
        d="M49.033,17.512c0,2.608,2.081,5,4.995,5s4.995-2.392,4.995-5H49.033z"
      />
      <path
        id="XMLID_115_"
        style={{ fill: '#556180' }}
        d="M54.028,23.512c-3.306,0-5.995-2.691-5.995-6v-1h11.989v1
        C60.022,20.821,57.334,23.512,54.028,23.512 M50.167,18.512c0.423,1.58,1.82,3,3.861,3s3.438-1.42,3.861-3H50.167z"
      />
      <path
        id="XMLID_112_"
        style={{ fill: '#F3D55C' }}
        d="M12.057,38.512H0l0.044-1.042c0.041-0.977,0.309-1.899,0.795-2.742l5.19-8.989
        l5.189,8.989c0.485,0.843,0.754,1.765,0.795,2.742L12.057,38.512z M2.225,36.512h7.607c-0.087-0.271-0.201-0.533-0.347-0.784
        l-3.457-5.989l-3.457,5.989C2.426,35.979,2.31,36.241,2.225,36.512"
      />
      <path
        id="XMLID_111_"
        style={{ fill: '#556180' }}
        d="M1.043,37.512c-0.111,2.686,1.995,5.205,4.985,5.205
        c2.99,0,5.097-2.519,4.985-5.205H1.043z"
      />
      <path
        id="XMLID_108_"
        style={{ fill: '#556180' }}
        d="M6.028,43.717c-1.648,0-3.176-0.647-4.303-1.824
        c-1.137-1.185-1.75-2.797-1.682-4.423l0.04-0.958h11.889l0.04,0.958c0.068,1.626-0.545,3.238-1.682,4.423
        C9.204,43.07,7.676,43.717,6.028,43.717 M2.12,38.512c0.151,0.742,0.514,1.439,1.049,1.997c0.747,0.779,1.763,1.208,2.859,1.208
        c1.097,0,2.113-0.429,2.859-1.208c0.535-0.558,0.897-1.255,1.049-1.997H2.12z"
      />
      <path
        id="XMLID_107_"
        style={{ fill: '#806F5E' }}
        d="M45.028,57.512h-30c0-4.418,3.582-8,8-8h14
        C41.446,49.512,45.028,53.094,45.028,57.512"
      />
      <rect
        id="XMLID_106_"
        x="24.028"
        y="44.512"
        style={{ fill: '#CBB292' }}
        width="12"
        height="5"
      />
      <path
        id="XMLID_102_"
        style={{ fill: '#556180' }}
        d="M35.028,13.512c0,2.761-2.239,5-5,5c-2.761,0-5-2.239-5-5c0-2.761,2.239-5,5-5
        C32.789,8.512,35.028,10.751,35.028,13.512"
      />
    </g>
  </Icon>
)

export default React.memo(JusticeScale)
