// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Neighborhood = props => (
  <Icon viewBox="0 0 150 150" {...props}>
    <defs>
      <polygon id="path-1" points="0 86 149.79872 86 149.79872 0 0 0" />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Naboskap" transform="translate(-114.000000, -215.000000)">
        <g id="Group" transform="translate(114.000000, 215.000000)">
          <g id="Page-1" transform="translate(0.000000, 39.000000)">
            <polygon
              id="Fill-1"
              fill="#FFFFFF"
              points="85.1569338 36.8572004 21.6763393 36.8572004 21.6763393 79.8572004 85.1569338 79.8572004 148.637528 79.8572004 148.637528 36.8572004"
            />
            <polygon
              id="Fill-2"
              fill="#4E342E"
              points="69.0932468 3.07148616 84.5763186 3.07148616 84.5763186 38.7793388 69.0932468 32.2500576"
            />
            <polygon
              id="Fill-3"
              fill="#BCAAA4"
              points="67.5449009 3.07142857 86.1245871 3.07142857 86.1245871 0.000191964286 67.5449009 0.000191964286"
            />
            <polygon
              id="Fill-4"
              fill="#B0BEC5"
              points="0 86 94.4467382 86 94.4467382 79.8573348 0 79.8573348"
            />
            <polyline
              id="Fill-5"
              fill="#E8F9FC"
              points="21.6763393 36.8572004 85.1569338 36.8572004 85.1569338 79.8572004"
            />
            <g id="Group-48">
              <path
                d="M49.5458686,76.7857719 L49.5458686,48.0677228 C49.5458686,46.6855799 49.0813764,46.0714862 47.6877064,46.0714862 L33.2886432,46.0714862 C31.8951667,46.0714862 30.9661824,46.8393433 30.9661824,48.0677228 L30.9661824,76.7857719 L49.5458686,76.7857719 Z"
                id="Fill-6"
                fill="#FCA12A"
              />
              <path
                d="M49.5458686,62.9643433 L49.5458686,48.0677228 C49.5458686,46.6855799 49.0813764,46.0714862 47.6877064,46.0714862 L33.2886432,46.0714862 C31.8951667,46.0714862 30.9661824,46.8393433 30.9661824,48.0677228"
                id="Fill-8"
                fill="#E58613"
              />
              <path
                d="M47.6877839,61.5821621 C47.6877839,62.9643049 46.6039688,64.1928763 45.0556616,64.1928763 C43.6621852,64.1928763 42.423733,63.1178763 42.423733,61.5821621 C42.423733,60.2000192 43.5073545,58.9712558 45.0556616,58.9712558 C46.4493316,58.9712558 47.6877839,60.0464478 47.6877839,61.5821621"
                id="Fill-10"
                fill="#DB5435"
              />
              <path
                d="M46.9136303,59.7391897 C47.9976388,60.6608103 47.8428081,62.350096 46.9136303,63.424904 C45.984646,64.500096 44.2815081,64.3463326 43.1978866,63.424904"
                id="Fill-12"
                fill="#C43D27"
              />
              <path
                d="M66.5772476,53.7500576 L75.8670907,53.7500576 L75.8670907,48.0677228 C75.8670907,46.6855799 74.3187835,46.0714862 72.9253071,46.0714862 L66.5772476,46.0714862 L66.5772476,53.7500576 Z"
                id="Fill-14"
                fill="#0E75AF"
              />
              <path
                d="M65.0289404,53.7500576 L65.0289404,46.0714862 L58.0615581,46.0714862 C56.6680816,46.0714862 55.7390973,46.8393433 55.7390973,48.0677228 L55.7390973,53.7500576 L65.0289404,53.7500576 Z"
                id="Fill-16"
                fill="#1EA4C4"
              />
              <path
                d="M65.0289404,55.2857719 L55.7390973,55.2857719 L55.7390973,61.2750576 C55.7390973,62.6570085 56.6680816,62.9643433 58.0615581,62.9643433 L65.0289404,62.9643433 L65.0289404,55.2857719 Z"
                id="Fill-18"
                fill="#0E75AF"
              />
              <path
                d="M66.5772476,55.2857719 L66.5772476,62.9643433 L72.9253071,62.9643433 C74.3187835,62.9643433 75.8670907,62.6570085 75.8670907,61.2750576 L75.8670907,55.2857719 L66.5772476,55.2857719 Z"
                id="Fill-20"
                fill="#1EA4C4"
              />
              <polygon
                id="Fill-22"
                fill="#6D4C41"
                points="85.1569338 36.8572004 53.4166366 3.99291473 21.6763393 36.8572004"
              />
              <polygon
                id="Fill-24"
                fill="#8D6E63"
                points="85.1569338 36.8572004 53.4166366 3.99291473 31.8951667 36.8572004"
              />
              <polygon
                id="Fill-26"
                fill="#6D4C41"
                points="31.895128 27.6429147 76.257806 27.6429147 73.291443 24.5714862 34.4144173 24.5714862"
              />
              <polygon
                id="Fill-28"
                fill="#6D4C41"
                points="41.5047546 16.8929147 65.8754967 16.8929147 64.3922185 15.3572004 43.1978285 15.3572004"
              />
              <polygon
                id="Fill-30"
                fill="#4E342E"
                points="132.573841 3.07148616 148.056913 3.07148616 148.056913 38.7793388 132.573841 32.2500576"
              />

              <g id="Clip-33" />
              <polygon
                id="Fill-32"
                fill="#BCAAA4"
                mask="url(#mask-2)"
                points="131.219034 3.07142857 149.79872 3.07142857 149.79872 0 131.219034 0"
              />
              <polygon
                id="Fill-34"
                fill="#B0BEC5"
                mask="url(#mask-2)"
                points="85.1568951 86 148.63749 86 148.63749 79.8571429 85.1568951 79.8571429"
              />
              <polyline
                id="Fill-35"
                fill="#E8F9FC"
                mask="url(#mask-2)"
                points="85.1569338 36.8572004 148.637528 36.8572004 148.637528 79.8572004"
              />
              <path
                d="M113.026463,76.7857719 L113.026463,48.0677228 C113.026463,46.6855799 112.561971,46.0714862 111.168301,46.0714862 L96.7692377,46.0714862 C95.3757613,46.0714862 94.4467769,46.8393433 94.4467769,48.0677228 L94.4467769,76.7857719 L113.026463,76.7857719 Z"
                id="Fill-36"
                fill="#FCA12A"
                mask="url(#mask-2)"
              />
              <path
                d="M113.026463,62.9643433 L113.026463,48.0677228 C113.026463,46.6855799 112.561971,46.0714862 111.168301,46.0714862 L96.7692377,46.0714862 C95.3757613,46.0714862 94.4467769,46.8393433 94.4467769,48.0677228"
                id="Fill-37"
                fill="#E58613"
                mask="url(#mask-2)"
              />
              <path
                d="M111.168378,61.5821621 C111.168378,62.9643049 110.084563,64.1928763 108.536256,64.1928763 C107.14278,64.1928763 105.904328,63.1178763 105.904328,61.5821621 C105.904328,60.2000192 106.987949,58.9712558 108.536256,58.9712558 C109.929926,58.9712558 111.168378,60.0464478 111.168378,61.5821621"
                id="Fill-38"
                fill="#DB5435"
                mask="url(#mask-2)"
              />
              <path
                d="M110.394225,59.7391897 C111.478233,60.6608103 111.323403,62.350096 110.394225,63.424904 C109.465241,64.500096 107.762103,64.3463326 106.678481,63.424904"
                id="Fill-39"
                fill="#C43D27"
                mask="url(#mask-2)"
              />
              <path
                d="M130.057842,53.7500576 L139.347685,53.7500576 L139.347685,48.0677228 C139.347685,46.6855799 137.799378,46.0714862 136.405902,46.0714862 L130.057842,46.0714862 L130.057842,53.7500576 Z"
                id="Fill-40"
                fill="#0E75AF"
                mask="url(#mask-2)"
              />
              <path
                d="M128.509535,53.7500576 L128.509535,46.0714862 L121.542153,46.0714862 C120.148676,46.0714862 119.219692,46.8393433 119.219692,48.0677228 L119.219692,53.7500576 L128.509535,53.7500576 Z"
                id="Fill-41"
                fill="#1EA4C4"
                mask="url(#mask-2)"
              />
              <path
                d="M128.509535,55.2857719 L119.219692,55.2857719 L119.219692,61.2750576 C119.219692,62.6570085 120.148676,62.9643433 121.542153,62.9643433 L128.509535,62.9643433 L128.509535,55.2857719 Z"
                id="Fill-42"
                fill="#0E75AF"
                mask="url(#mask-2)"
              />
              <path
                d="M130.057842,55.2857719 L130.057842,62.9643433 L136.405902,62.9643433 C137.799378,62.9643433 139.347685,62.6570085 139.347685,61.2750576 L139.347685,55.2857719 L130.057842,55.2857719 Z"
                id="Fill-43"
                fill="#1EA4C4"
                mask="url(#mask-2)"
              />
              <polygon
                id="Fill-44"
                fill="#6D4C41"
                mask="url(#mask-2)"
                points="148.637528 36.8572004 116.897231 3.99291473 85.1569338 36.8572004"
              />
              <polygon
                id="Fill-45"
                fill="#8D6E63"
                mask="url(#mask-2)"
                points="148.637528 36.8572004 116.897231 3.99291473 95.3757613 36.8572004"
              />
              <polygon
                id="Fill-46"
                fill="#6D4C41"
                mask="url(#mask-2)"
                points="95.3757225 27.6429147 139.738401 27.6429147 136.772038 24.5714862 97.8950119 24.5714862"
              />
              <polygon
                id="Fill-47"
                fill="#6D4C41"
                mask="url(#mask-2)"
                points="104.985349 16.8929147 129.356091 16.8929147 127.872813 15.3572004 106.678423 15.3572004"
              />
            </g>
          </g>
          <g
            id="hearts"
            transform="translate(83.000000, 0.000000)"
            fill="#E64C3C"
            fillRule="nonzero"
          >
            <path
              d="M16.4849087,31 C25.4172616,24.6468501 29.8860007,18.8356644 31.9873958,14.4030508 C34.4424403,9.22396892 32.3068762,2.80377773 26.9503119,0.649441901 C20.6540993,-1.88179003 16.4849087,3.95812766 16.4849087,3.95812766 C16.4849087,3.95812766 12.3453312,-1.89700108 6.04968814,0.635357592 C0.69312383,2.78969342 -1.44244029,9.20988461 1.01260422,14.3889665 C3.11399932,18.8210167 7.5525558,24.6474135 16.4849087,31 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default React.memo(Neighborhood)
