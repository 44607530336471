// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Parkering = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>parkering</title>
      <path d="M17.915 14.911c-0.157-0.094-0.337-0.158-0.542-0.19s-0.418-0.049-0.641-0.049h-1.503v2.637h1.503c0.222 0 0.436-0.016 0.641-0.049s0.386-0.096 0.542-0.19c0.156-0.095 0.282-0.229 0.376-0.401s0.142-0.398 0.142-0.678c0-0.279-0.048-0.505-0.142-0.678s-0.22-0.306-0.376-0.401zM16 6.267c-6.16 0-11.2 5.040-11.2 11.2s5.040 11.2 11.2 11.2 11.2-5.040 11.2-11.2-5.040-11.2-11.2-11.2zM20.14 17.031c-0.111 0.341-0.288 0.643-0.53 0.906s-0.557 0.474-0.942 0.635c-0.386 0.159-0.854 0.24-1.405 0.24h-2.034v3.155h-1.934v-8.8h3.968c0.55 0 1.019 0.080 1.405 0.241s0.7 0.372 0.942 0.635c0.242 0.262 0.419 0.562 0.53 0.9s0.166 0.686 0.166 1.047c0.001 0.352-0.054 0.699-0.166 1.041z" />
    </g>
  </Icon>
)

export default React.memo(Parkering)
