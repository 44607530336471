import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const SkiLift = props => (
  <Icon viewBox="0 0 447.6 447.6" {...props}>
    <g transform="translate(0 -1)">
      <g>
        <g>
          <path d="M215.8,119.1c-19,0-34.4,15.5-34.4,34.4s15.5,34.4,34.4,34.4s34.4-15.5,34.4-34.4S234.8,119.1,215.8,119.1z" />
          <path
            d="M199.4,78.9c1.9,7.3,7.3,13.2,14.4,15.8c-1,0.9-2.3,1.4-3.7,1.4h-23c-15.8,0-28.7,12.9-28.7,28.7v103.3v80.4
				c0,15.8,12.9,28.7,28.7,28.7h51.7c3.2,0,5.7-2.6,5.7-5.7c0-6.5-2.2-12.4-5.9-17.2h18.8l10.4,36.4c1.3,3.6,2.9,6.8,4.7,9.6H152.6
				c-6.3,0-11.5,5.1-11.5,11.5v11.5c0,6.3,5.1,11.5,11.5,11.5h212.4c15.8,0,28.7-12.9,28.7-28.7c0-4.4-2.5-8.4-6.4-10.3
				c-3.9-1.9-8.4-1.5-11.9,1.1c-3,2.3-6.6,3.5-10.4,3.5h-55.2c2.9-5.4,3.6-11.9,1.7-18.2v0L295,288.6c-3.7-12.1-14.8-20.2-27.4-20.2
				h-17.3v-39c0-18.3-13.7-33.9-31.1-35.5c-9.7-0.9-19.4,2.3-26.5,8.8c-7.2,6.5-11.3,15.8-11.3,25.5v0.1c-3.4-2.5-7.2-4.4-11.5-5.3
				v-98.2c0-9.5,7.7-17.2,17.2-17.2h23c7.7,0,14.1-5.1,16.3-12c8.5-1.8,15.1-8.3,17.3-16.7 M243.7,67.4
				c-2.6-9.9-11.5-17.2-22.2-17.2c-10.7,0-19.6,7.3-22.2,17.2 M187.1,325.8c-9.5,0-17.2-7.7-17.2-17.2v-73.7
				c6.7,2.4,11.5,8.8,11.5,16.2v45.9c0,9.5,7.7,17.2,17.2,17.2h17.2c7.5,0,13.9,4.8,16.2,11.5L187.1,325.8L187.1,325.8z
				 M365.1,383.2H152.6v-11.5h212.4c6.3,0,12.3-2,17.2-5.7C382.3,375.5,374.6,383.2,365.1,383.2z"
          />
        </g>
      </g>
    </g>
    <path
      d="M361.7,386.9H153c-2.2,0-4-1.8-4-4v-14c0-2.2,1.8-4,4-4h208.7c2.2,0,4,1.8,4,4v14
	C365.7,385.1,363.9,386.9,361.7,386.9z"
    />
    <path
      d="M368.3,376H382c2.2,0,4-1.8,4-4v-4.7c0-2.2-1.8-4-4-4h-13.7c-2.2,0-4,1.8-4,4v4.7
	C364.3,374.2,366.1,376,368.3,376z"
    />
    <path
      d="M375.3,383.9h-12.7c-2.2,0-4-1.8-4-4v-5.3c0-2.2,1.8-4,4-4h12.7c2.2,0,4,1.8,4,4v5.3
	C379.3,382.1,377.5,383.9,375.3,383.9z"
    />
    <circle cx="221.5" cy="71.9" r="24.6" />
    <path
      d="M383,30.2L57.9,104.2c-2.6,0.6-4.2,3.2-3.6,5.7l0,0c0.6,2.6,3.2,4.2,5.7,3.6l325.2-74.1
	c2.6-0.6,4.2-3.2,3.6-5.7v0C388.2,31.2,385.6,29.6,383,30.2z"
    />
  </Icon>
)

export default React.memo(SkiLift)
