import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Football = props => (
  <Icon viewBox="-35 0 370 297" {...props}>
    <g>
      <path
        style={{ fill: 'transparent' }}
        d="M245.414,108.341l11.585-35.805c-16.934-24.115-41.86-42.35-70.724-50.991l-30.108,21.71v43.49
    l48.248,34.863L245.414,108.341z"
      />
      <path
        style={{ fill: 'transparent' }}
        d="M280.966,145.838l-30.605-22.281l-42.417,13.782l-17.952,55.184l26.064,35.874l38.024,0.064
    C270.964,206.221,281,178.512,281,148.5C281,147.609,280.983,146.724,280.966,145.838z"
      />
      <path
        style={{ fill: 'transparent' }}
        d="M88.872,136.796l-42.654-13.858L16.052,144.87C16.019,146.076,16,147.286,16,148.5
    c0,29.614,9.768,56.988,26.247,79.076l37.722,0.117l26.579-36.584L88.872,136.796z"
      />
      <path
        style={{ fill: 'transparent' }}
        d="M92.737,121.283l47.43-34.536V43.253L110.409,21.54c-28.995,8.677-53.763,27.034-70.707,51.307
    l11.262,34.85L92.737,121.283z"
      />
      <path
        style={{ fill: 'transparent' }}
        d="M119.502,200.5l-26.686,36.729l11.812,36.297C118.366,278.36,133.13,281,148.5,281
    c14.917,0,29.265-2.48,42.656-7.046l11.838-36.315L176.011,200.5H119.502z"
      />
      <path
        d="M148.5,0C66.617,0,0,66.617,0,148.5S66.617,297,148.5,297S297,230.383,297,148.5S230.383,0,148.5,0z M156.167,43.255
    l30.108-21.71c28.864,8.642,53.706,26.876,70.641,50.991l-11.543,35.805l-40.979,13.268l-48.227-34.863V43.255z M110.409,21.54
    l29.758,21.713v43.494l-47.43,34.536l-41.731-13.587L39.66,72.847C56.604,48.574,81.414,30.217,110.409,21.54z M42.247,227.576
    C25.768,205.488,16,178.114,16,148.5c0-1.214,0.019-2.424,0.052-3.63l30.166-21.933l42.654,13.858l17.676,54.314l-26.579,36.584
    L42.247,227.576z M191.156,273.954C177.765,278.52,163.417,281,148.5,281c-15.37,0-30.134-2.64-43.872-7.475l-11.812-36.297
    l26.686-36.729h56.509l26.983,37.139L191.156,273.954z M216.056,228.396l-26.064-35.874l17.952-55.184l42.417-13.782l30.605,22.281
    c0.018,0.886,0.034,1.771,0.034,2.662c0,30.012-10.036,57.721-26.92,79.96L216.056,228.396z"
      />
    </g>
  </Icon>
)

export default React.memo(Football)
