// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Waiting = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>waiting</title>
      <path d="M22 6.667v6.3l-4 4.2 4 4.2v6.3h-12v-6.3l4-4.2-4-4.2v-6.3h12zM16 17.667l-4 4.225v3.775h8v-3.775l-4-4.225zM20 12.442v-3.775h-8v3.775l4 4.225 4-4.225zM14 10.667h4v1l-2 2-2-2v-1z" />
    </g>
  </Icon>
)

export default React.memo(Waiting)
