/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Router from 'next/router'

import { trackEvent } from '../../../utils/analytics'
import {
  getShowCompareBox,
  getNabolagCategories,
  getShowRouteCalculation,
} from '../../../store/nabolag/selectors'

import InternalLink from '../../../utils/internal-link'
import { MenuWrapper, MenuContent, MenuBack } from './style'

import MenuNavigation from './components/MenuNavigation'

import { useTranslation } from '../../../../i18n'

const Menu = ({ finnkode, displayBackToFinnLink, displayToOverviewLink, wide, noMenu }) => {
  const [isOpen, setIsOpen] = useState(false)
  const categories = useSelector(getNabolagCategories)
  const showCompareBox = useSelector(getShowCompareBox)
  const showTravelTime = useSelector(getShowRouteCalculation)

  const { t } = useTranslation()

  const toggleMenu = () => {
    if (isOpen) {
      trackEvent('Hide main menu', 'menu', 'Lukk')
    } else {
      trackEvent('Show main menu', 'menu', 'Meny')
    }
    setIsOpen(!isOpen)
  }

  const handleExit = () => {
    setIsOpen(false)
  }

  if (noMenu) {
    return (
      <MenuWrapper>
        <MenuContent wide={wide}>
          {displayToOverviewLink ? (
            <MenuBack>
              <InternalLink route="index" passHref>
                <a
                  onClick={() => {
                    trackEvent('Clicked back to frontpage', 'menu', 'Til forsiden')
                  }}
                  role="navigation"
                >
                  {t('common:menu.toFrontpage')}
                </a>
              </InternalLink>
            </MenuBack>
          ) : null}
        </MenuContent>
      </MenuWrapper>
    )
  }

  return (
    <MenuWrapper isOpen={isOpen}>
      <MenuContent wide={wide}>
        <MenuBack isOpen={isOpen}>
          {displayBackToFinnLink ? (
            <a
              href={`https://finn.no/${finnkode}`}
              onClick={() => {
                trackEvent('Clicked back to finn', 'menu', t('common:menuBackTo'))
              }}
            >
              {t('common:menuBackTo')}
            </a>
          ) : null}
          {displayToOverviewLink ? (
            <InternalLink route="oversikt" passHref>
              <a
                onClick={() => {
                  trackEvent('Clicked back to overview', 'menu', 'Til oversikt')
                }}
                role="navigation"
              >
                {t('common:menuToOverview')}
              </a>
            </InternalLink>
          ) : null}
        </MenuBack>

        <MenuNavigation
          wide={wide}
          isOpen={isOpen}
          categories={categories}
          showTravelTime={showTravelTime}
          showCompareBox={showCompareBox}
          toggleMenu={toggleMenu}
          handleExit={handleExit}
        />
      </MenuContent>
    </MenuWrapper>
  )
}

Menu.propTypes = {
  finnkode: PropTypes.string.isRequired,
  displayBackToFinnLink: PropTypes.bool,
  displayToOverviewLink: PropTypes.bool,
  noMenu: PropTypes.bool,
  wide: PropTypes.bool,
}

Menu.defaultProps = {
  noMenu: false,
  displayBackToFinnLink: false,
  displayToOverviewLink: false,
  wide: false,
}

export default Menu
