import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import FocusTrap from 'react-focus-lock'
import { FocusOn } from 'react-focus-on'
import { Clear } from '../components/icons'

import { withTranslation } from '../../i18n'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;
`

const Dialog = styled.div`
  color: ${({ theme }) => theme.infoPopupColor};
  max-width: 280px;
  min-height: 200px;
  background: ${({ theme }) => theme.infoPopupBackground};
  width: auto;
  overflow: hidden;
  padding: 20px 24px 20px 24px;
  box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px 2px 2px 2px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;

  h2 {
    ${({ theme }) =>
      theme.headings.h2.fontFamily &&
      css`
        font-family: ${theme.headings.h2.fontFamily};
      `};
    margin-top: 0;
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 24px;
  }

  div.focus-trap {
    &:focus {
      outline: none;
    }
  }
`

const DialogText = styled.p`
  font-size: 16px;
  margin-bottom: 41px;
  line-height: 24px;
`

const CloseButton = styled.button`
  color: ${(props) => props.theme.infoPopupCloseButtonColor};
  background: transparent;
  border: none;
  float: right;
  position: absolute;
  bottom: 20px;
  right: 24px;
  font-size: 18px;
  padding: 0;

  span,
  svg {
    vertical-align: middle;
  }

  &:hover {
    cursor: pointer;
  }
`

const InfoPopup = ({ title, text, closeCallback, t }) => (
  <Wrapper>
    <Dialog role="dialog" aria-hidden="false">
      <FocusTrap>
        <FocusOn onEscapeKey={closeCallback}>
          <h2>{title}</h2>
          <DialogText>{text}</DialogText>
          <CloseButton onClick={closeCallback}>
            <span>{t('common:close')}</span>
            <Clear width={24} height={24} />
          </CloseButton>
        </FocusOn>
      </FocusTrap>
    </Dialog>
  </Wrapper>
)

InfoPopup.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  closeCallback: PropTypes.func,
}

InfoPopup.defaultProps = {
  closeCallback: () => {},
}
export default withTranslation(['common'])(InfoPopup)
