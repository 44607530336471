import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme, css } from 'styled-components'

import { Drive, Walking, Airplane } from '../components/poi-icons'

const IconWrapper = styled.i`
  flex: 0 1 auto;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  svg {
    vertical-align: middle;
    position: relative;
  }

  ${({ light, theme }) =>
    light &&
    css`
      color: ${theme.fontColorLight};
    `}
`

function getTravelIcon(type) {
  switch (type) {
    case 'walk':
      return <Walking width={20} height={20} />
    case 'drive':
      return <Drive width={20} height={20} />
    case 'air':
      return <Walking width={20} height={20} />
    default:
      return type
  }
}

const TravelIcon = ({ type, light }) => (
  <IconWrapper light={light}>{getTravelIcon(type)}</IconWrapper>
)

TravelIcon.propTypes = {
  type: PropTypes.string.isRequired,
  light: PropTypes.bool,
}

TravelIcon.defaultProps = {
  light: false,
}

export default withTheme(TravelIcon)
