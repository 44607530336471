import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const ParkingStreet = props => (
  <Icon width="60" height="60" viewBox="0 0 60 60" {...props}>
    <path
      d="M26.6016 60C23.6938 60 21.3281 57.6343 21.3281 54.7266V51.2109C21.3281 50.2396 22.1146 49.4531 23.0859 49.4531H30.1172C31.0886 49.4531 31.875 50.2396 31.875 51.2109V54.7266C31.875 57.6343 29.5093 60 26.6016 60Z"
      fill="#526675"
    />
    <path
      d="M51.2109 60C48.3032 60 45.9375 57.6343 45.9375 54.7266V51.2109C45.9375 50.2396 46.7239 49.4531 47.6953 49.4531H54.7266C55.6979 49.4531 56.4844 50.2396 56.4844 51.2109V54.7266C56.4844 57.6343 54.1187 60 51.2109 60Z"
      fill="#526675"
    />
    <path
      d="M54.7266 37.1484H23.0859L25.7932 28.992C26.2852 27.5505 27.621 26.6016 29.133 26.6016H48.68C50.1915 26.6016 51.5277 27.5505 52.0198 28.992L54.7266 37.1484Z"
      fill="#BAECFD"
    />
    <path
      d="M56.3786 36.5858L53.6719 28.4647C52.9687 26.2852 50.9647 24.8438 48.68 24.8438H29.1325C26.8478 24.8438 24.8437 26.2852 24.1406 28.4647L21.4339 36.5858C21.2224 37.1132 21.3281 37.711 21.6444 38.1679C21.996 38.6247 22.5233 38.9062 23.0859 38.9062H54.7266C55.2891 38.9062 55.8165 38.6247 56.1681 38.1679C56.4844 37.711 56.5901 37.1132 56.3786 36.5858V36.5858ZM25.5116 35.3906L27.4805 29.5546C27.6915 28.8515 28.3946 28.3594 29.1325 28.3594H48.68C49.4179 28.3594 50.121 28.8515 50.332 29.5546L52.3009 35.3906H25.5116Z"
      fill="#F3654D"
    />
    <path
      d="M30.1172 38.9062C29.6672 38.9062 29.2177 38.7346 28.8744 38.3913C28.1877 37.7046 28.1877 36.5923 28.8744 35.9056L32.39 32.39C33.0766 31.7033 34.189 31.7033 34.8756 32.39C35.5623 33.0766 35.5623 34.189 34.8756 34.8756L31.36 38.3913C31.0167 38.7346 30.5672 38.9062 30.1172 38.9062Z"
      fill="#526675"
    />
    <path
      d="M44.1797 38.9062C43.7297 38.9062 43.2802 38.7346 42.9369 38.3913C42.2502 37.7046 42.2502 36.5923 42.9369 35.9056L46.4525 32.39C47.1391 31.7033 48.2515 31.7033 48.9381 32.39C49.6248 33.0766 49.6248 34.189 48.9381 34.8756L45.4225 38.3913C45.0792 38.7346 44.6297 38.9062 44.1797 38.9062Z"
      fill="#526675"
    />
    <path
      d="M54.7266 35.3906H23.0859C20.1677 35.3906 17.8125 37.7458 17.8125 40.6641V51.2109C17.8125 52.1951 18.5857 52.9688 19.5703 52.9688H58.2422C59.2268 52.9688 60 52.1951 60 51.2109V40.6641C60 37.7458 57.6448 35.3906 54.7266 35.3906Z"
      fill="#F3654D"
    />
    <path
      d="M10.5469 26.3672V51.2109C10.5469 52.1951 9.77371 52.9688 8.78906 52.9688C7.80441 52.9688 7.03125 52.1951 7.03125 51.2109V26.3672C7.03125 25.3825 7.80441 24.6094 8.78906 24.6094C9.77371 24.6094 10.5469 25.3825 10.5469 26.3672Z"
      fill="#99A5AD"
    />
    <path
      d="M14.0625 51.2109V58.2422H3.51562V51.2109C3.51562 50.2263 4.28879 49.4531 5.27344 49.4531H12.3047C13.2893 49.4531 14.0625 50.2263 14.0625 51.2109Z"
      fill="#526675"
    />
    <path
      d="M15.8203 0H5.27344C2.35519 0 0 2.35519 0 5.27344V22.8516C0 25.7693 2.35519 28.125 5.27344 28.125H15.8203C20.6717 28.125 24.6094 24.1873 24.6094 19.3359V8.78906C24.6094 3.93768 20.6717 0 15.8203 0Z"
      fill="#40BAF3"
    />
    <path
      d="M12.3047 7.03125H8.78906C7.80441 7.03125 7.03125 7.80441 7.03125 8.78906V19.3359C7.03125 20.3201 7.80441 21.0938 8.78906 21.0938C9.77371 21.0938 10.5469 20.3201 10.5469 19.3359V17.5781H12.3047C15.2229 17.5781 17.5781 15.2225 17.5781 12.3047C17.5781 9.38644 15.2229 7.03125 12.3047 7.03125ZM12.3047 14.0625H10.5469V10.5469H12.3047C13.2893 10.5469 14.0625 11.32 14.0625 12.3047C14.0625 13.2889 13.2893 14.0625 12.3047 14.0625Z"
      fill="white"
    />
    <path
      d="M30.1172 45.9375H26.6016C25.6302 45.9375 24.8438 45.1511 24.8438 44.1797C24.8438 43.2083 25.6302 42.4219 26.6016 42.4219H30.1172C31.0886 42.4219 31.875 43.2083 31.875 44.1797C31.875 45.1511 31.0886 45.9375 30.1172 45.9375Z"
      fill="#BAECFD"
    />
    <path
      d="M51.2109 45.9375H47.6953C46.7239 45.9375 45.9375 45.1511 45.9375 44.1797C45.9375 43.2083 46.7239 42.4219 47.6953 42.4219H51.2109C52.1823 42.4219 52.9688 43.2083 52.9688 44.1797C52.9688 45.1511 52.1823 45.9375 51.2109 45.9375Z"
      fill="#BAECFD"
    />
    <path
      d="M17.5781 58.2422C17.5781 59.2264 16.805 60 15.8203 60H1.75781C0.773163 60 0 59.2264 0 58.2422C0 57.2575 0.773163 56.4844 1.75781 56.4844H15.8203C16.805 56.4844 17.5781 57.2575 17.5781 58.2422Z"
      fill="#384949"
    />
  </Icon>
)

export default ParkingStreet
