// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Hiking = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <style>
      {
        '.st0{fill:#F0C087;}.st1{opacity:0.32;fill:#831232;enable-background:new;}.st2{fill:#998269;}.st3{fill:#836F5A;}.st4{fill:#347642;}.st5{opacity:0.53;}.st6{fill:#2B5448;}.st7{fill:#AA6518;}.st8{fill:#FFB057;}.st9{opacity:0.48;fill:#AA6518;enable-background:new;}.st10{fill:#71CE93;}.st11{fill:#3F8E50;}'
      }
    </style>
    <g>
      <polygon className="st0" points="269.9,130.7 275.5,93.1 244.4,91.1 238.2,125.4" />
      <polygon className="st1" points="272.3,116.9 275.5,93.1 244.6,89.9 240.6,111.5" />
      <rect x="402.9" y="84.1" className="st2" width="21" height="180.7" />
      <rect x="402.9" y="229" className="st2" width="21.5" height="283" />
      <rect x="402.9" y="217.4" className="st3" width="21" height="47.4" />
      <path
        className="st4"
        d="M89,424.9l70.5-67.6l25.4-87.1h38.9l-28,101.8c-0.7,2.8-2.1,5.3-3.8,7.4c-0.7,0.9-1.4,1.9-2.2,2.7l-82.7,80 L89,424.9z"
      />
      <g className="st5">
        <polygon className="st6" points="185,270.3 175.2,303.9 214.7,303.9 223.9,270.3" />
      </g>
      <path
        className="st0"
        d="M300.4,8.9c-4.5-4.8-10.6-7.6-17.1-7.9l-28.9-1c-13.6-0.5-25,10.2-25.5,23.7L227,74.4 c-0.2,6.6,2.1,12.8,6.6,17.7s10.6,7.6,17.1,7.9l28.9,1c0.3,0,0.6,0,0.9,0c6.2,0,12.1-2.3,16.8-6.6c4.8-4.5,7.6-10.6,7.9-17.1 l1.8-50.7C307.2,20,304.9,13.7,300.4,8.9z"
      />
      <path
        className="st7"
        d="M162.4,90.5l-24.5-5.8c-2.5-0.6-5,1-5.5,3.6l-13.3,72.8c-0.6,3.2,1.5,6.3,4.6,7l24,5.5L162.4,90.5z"
      />
      <path
        className="st8"
        d="M182.6,226.7l-39.9-7c-5.4-0.9-9-6.1-8.1-11.5l24.6-140.6c0.9-5.4,6.1-9,11.5-8.1l39.9,7 c5.4,0.9,9,6.1,8.1,11.5L194,218.7C193.1,224,187.9,227.7,182.6,226.7z"
      />
      <path
        className="st9"
        d="M210.6,66.6l-1.5-0.3l-24.1,137.8c-0.9,5.4-6.1,9-11.5,8.1l-38.3-6.7l-0.5,2.8c-0.9,5.4,2.7,10.5,8.1,11.5 l39.9,7c5.4,0.9,10.5-2.7,11.5-8.1l24.6-140.6C219.6,72.7,216,67.6,210.6,66.6z"
      />
      <g>
        <path
          className="st7"
          d="M305.1,15.9c-1.1-2.5-2.6-4.9-4.6-7c-4.5-4.8-10.6-7.6-17.1-7.9l-28.9-1c-13.6-0.5-25,10.2-25.5,23.7l-1.1,30 C239.7,51.6,281.7,42.3,305.1,15.9z"
        />
      </g>
      <path
        className="st10"
        d="M282.6,159.9c3.1-17.9-8.9-35-26.8-38.1l-33.7-5.9c-5.4-0.9-10.5,2.7-11.5,8.1L185,270.3l32.1,5.6 c0.2,0.4,0.4,0.7,0.6,1.1l53.6,94.7v104.4l39,4.5V367.9c0-1.2-0.1-2.3-0.3-3.5c-0.2-2.7-1-5.4-2.5-7.9l-45.2-79.9l12.5-71.2 L282.6,159.9z"
      />
      <polygon
        className="st10"
        points="228.1,158.8 302.1,232.9 302.6,232.4 302.6,233 395.8,229 394.2,193 316.6,196.3 257.9,140"
      />
      <path
        className="st3"
        d="M123.3,499.4L68.9,445L89,424.9l41.3,52.1c3.9,4.9,3.5,11.9-0.9,16.3L123.3,499.4z"
      />
      <polygon className="st0" points="394.2,193 443.1,190.5 428.9,228.2 395.8,229" />
      <path
        className="st11"
        d="M309.9,364.4c-0.2-2.7-1-5.4-2.5-7.9l-45.2-79.9l4.8-27.5l-76-12.9l-6,34.1l32.1,5.6c0.2,0.4,0.4,0.7,0.6,1.1 l53.6,94.7v104.4l39,9.9V367.9C310.2,366.7,310.1,365.5,309.9,364.4z"
      />
      <circle className="st0" cx="258.4" cy="50.5" r="14.5" />
      <path
        className="st3"
        d="M348.2,504.6h-76.9v-28.4l66,7.7c6.2,0.7,10.8,5.9,10.9,12.1L348.2,504.6z"
      />
    </g>
  </Icon>
)

export default React.memo(Hiking)
