// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Buss = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>buss</title>
      <path d="M26.4 13.467v-0.757c0.024-0.193 0.046-0.696-0.298-1.114-0.198-0.242-0.585-0.53-1.302-0.53h-0.4c0-0.134 0-0.266 0-0.4-0.001-0.874-0.695-1.865-1.561-2.129-4.46-1.34-9.218-1.34-13.678 0-0.866 0.264-1.561 1.255-1.561 2.129 0 0.134 0 0.266 0 0.4h-0.4c-0.718 0-1.104 0.288-1.302 0.53-0.343 0.418-0.322 0.921-0.298 1.114v0.757c-0.44 0-0.8 0.36-0.8 0.8v3.2c0 0.44 0.36 0.8 0.8 0.8h0.8c0.44 0 0.8-0.36 0.8-0.8v-3.2c0-0.294-0.162-0.549-0.4-0.688v-0.912l-0.010-0.106c-0.007-0.049-0.004-0.158 0.036-0.206 0.038-0.043 0.154-0.089 0.374-0.089h0.4c0 1.882 0 3.763 0 5.645 0 0.88 0 2.32 0 3.2 0 1.718 0 3.437 0 5.155 0 0.737 0.513 1.358 1.2 1.541v0.859c0 0.44 0.36 0.8 0.8 0.8h1.2c0.44 0 0.8-0.36 0.8-0.8v-0.8c2.934 0 5.866 0 8.8 0v0.8c0 0.44 0.36 0.8 0.8 0.8h1.2c0.44 0 0.8-0.36 0.8-0.8v-0.859c0.687-0.182 1.2-0.804 1.2-1.541 0-1.718 0-3.437 0-5.155 0-0.88 0-2.32 0-3.2 0-1.882 0-3.763 0-5.645h0.4c0.219 0 0.336 0.046 0.373 0.089 0.041 0.047 0.044 0.157 0.038 0.194l-0.006 1.026c-0.24 0.138-0.406 0.395-0.406 0.691v3.2c0 0.44 0.36 0.8 0.8 0.8h0.8c0.44 0 0.8-0.36 0.8-0.8v-3.2c0-0.44-0.36-0.8-0.8-0.8zM12.4 25.467h-2c-0.44 0-0.8-0.36-0.8-0.8v-0.8c0-0.44 0.342-0.686 0.759-0.547l2.082 0.694c0.418 0.139 0.759 0.523 0.759 0.853s-0.36 0.601-0.8 0.601zM22.4 24.667c0 0.44-0.36 0.8-0.8 0.8h-2c-0.44 0-0.8-0.27-0.8-0.6s0.342-0.714 0.759-0.853l2.082-0.694c0.417-0.14 0.758 0.106 0.758 0.546v0.8zM22.4 18.267c0 0.866-0.709 1.75-1.584 1.917-3.186 0.585-6.446 0.585-9.631 0-0.877-0.166-1.585-1.050-1.585-1.917 0-2 0-4 0-6 0-0.866 0.709-1.764 1.585-1.931 0.669-0.119 1.342-0.208 2.016-0.277 0 0.002-0.001 0.006-0.001 0.008 0 0.33 0.36 0.6 0.8 0.6h4c0.44 0 0.8-0.27 0.8-0.6 0-0.002-0.001-0.006-0.001-0.009 0.674 0.070 1.347 0.158 2.016 0.278 0.876 0.167 1.585 1.065 1.585 1.931 0 2 0 4 0 6z" />
    </g>
  </Icon>
)

export default React.memo(Buss)
