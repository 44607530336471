import React from 'react'
import PropTypes from 'prop-types'

import { Menu as MenuIcon } from '../../../../icons'
import { Button, ButtonContent, ButtonText, ButtonIcon } from '../../style'

const NavToggleButton = ({ isOpen, onClick }) => (
  <Button isOpen={isOpen} onClick={onClick}>
    <ButtonContent>
      <ButtonText>{isOpen ? 'Lukk' : 'Meny'}</ButtonText>
      {!isOpen ? (
        <ButtonIcon>
          <MenuIcon size="small" />
        </ButtonIcon>
      ) : null}
    </ButtonContent>
  </Button>
)

NavToggleButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default NavToggleButton
