// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Stopwatch = props => (
  <Icon viewBox="0 0 80 90" {...props}>
    <path
      d="M69.3264 11.4944L73.0549 15.2229L64.7485 23.53L61.02 19.8015L69.3264 11.4944Z"
      fill="#444444"
    />
    <path d="M31.6406 2.63672H47.4609V13.3594H31.6406V2.63672Z" fill="#5A5A5A" />
    <path d="M39.5508 2.63672H47.4609V13.3594H39.5508V2.63672Z" fill="#444444" />
    <path
      d="M39.5508 10.7227C17.7189 10.7227 0 28.4415 0 50.2734C0 72.1053 17.7189 90 39.5508 90C61.3827 90 79.1016 72.1053 79.1016 50.2734C79.1016 28.4415 61.3827 10.7227 39.5508 10.7227Z"
      fill="#B6C4CF"
    />
    <path
      d="M79.1016 50.2734C79.1016 72.1053 61.3827 90 39.5508 90V10.7227C61.3827 10.7227 79.1016 28.4415 79.1016 50.2734Z"
      fill="#9FACBA"
    />
    <path
      d="M39.5508 81.9141C22.1038 81.9141 7.91016 67.7204 7.91016 50.2734C7.91016 32.8265 22.1038 18.6328 39.5508 18.6328C56.9978 18.6328 71.1914 32.8265 71.1914 50.2734C71.1914 67.7204 56.9978 81.9141 39.5508 81.9141Z"
      fill="#D9E7EC"
    />
    <path
      d="M71.1914 50.2734C71.1914 32.8265 56.9978 18.6328 39.5508 18.6328V81.9141C56.9978 81.9141 71.1914 67.7204 71.1914 50.2734Z"
      fill="#C5D3DD"
    />
    <path d="M26.3672 0H52.7344V5.27344H26.3672V0Z" fill="#B6C4CF" />
    <path
      d="M67.7815 6.22101L78.3283 16.7679L74.5999 20.4964L64.053 9.94949L67.7815 6.22101Z"
      fill="#9FACBA"
    />
    <path d="M36.9141 23.9062H42.1875V31.8164H36.9141V23.9062Z" fill="#B6C4CF" />
    <path d="M36.9141 68.7305H42.1875V76.6406H36.9141V68.7305Z" fill="#B6C4CF" />
    <path d="M13.1836 47.6367H21.0938V52.9102H13.1836V47.6367Z" fill="#B6C4CF" />
    <path d="M58.0078 47.6367H65.918V52.9102H58.0078V47.6367Z" fill="#9FACBA" />
    <path
      d="M24.6347 61.4609L28.3632 65.1894L22.7807 70.7719L19.0522 67.0434L24.6347 61.4609Z"
      fill="#B6C4CF"
    />
    <path
      d="M56.3208 29.7736L60.0492 33.5021L54.4675 39.0845L50.739 35.356L56.3208 29.7736Z"
      fill="#9FACBA"
    />
    <path
      d="M54.4675 61.4609L60.0499 67.0427L56.3214 70.7712L50.739 65.1894L54.4675 61.4609Z"
      fill="#9FACBA"
    />
    <path
      d="M22.7802 29.7743L28.3627 35.3567L24.6342 39.0852L19.0518 33.5028L22.7802 29.7743Z"
      fill="#B6C4CF"
    />
    <path d="M50.0977 47.6367V52.9102H36.9141V39.7266H42.1875V47.6367H50.0977Z" fill="#5A5A5A" />
    <path d="M39.5508 0H52.7344V5.27344H39.5508V0Z" fill="#9FACBA" />
    <path d="M39.5508 23.9062H42.1875V31.8164H39.5508V23.9062Z" fill="#9FACBA" />
    <path d="M39.5508 68.7305H42.1875V76.6406H39.5508V68.7305Z" fill="#9FACBA" />
    <path d="M50.0977 47.6367V52.9102H39.5508V39.7266H42.1875V47.6367H50.0977Z" fill="#444444" />
  </Icon>
)

export default React.memo(Stopwatch)
