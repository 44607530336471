import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const TransportRating = props => (
  <Icon width="60" height="60" viewBox="0 0 60 60" {...props}>
    <path
      d="M41.8637 8.53732H18.1378L18.9707 4.0432C19.0621 3.55484 19.4875 3.19981 19.9849 3.19981H23.348L23.9427 0.783474C24.0566 0.323576 24.4685 0 24.9434 0H35.0567C35.5315 0 35.945 0.323576 36.0573 0.783474L36.6521 3.19981H40.0167C40.5125 3.19981 40.9395 3.55484 41.0293 4.0432L41.8637 8.53732Z"
      fill="#D84519"
    />
    <path
      d="M50.2033 14.3033V49.6495C50.2033 50.4255 49.5741 51.0546 48.7982 51.0546H11.2019C10.4259 51.0546 9.79675 50.4255 9.79675 49.6495V14.3033C9.79675 11.1184 12.3779 8.53732 15.5627 8.53732H18.1378H41.8637H44.4389C47.6222 8.53732 50.2033 11.1184 50.2033 14.3033ZM46.9076 38.4217V34.3785H42.1349C41.0174 34.3785 40.0077 34.8309 39.2751 35.5619C38.5426 36.2945 38.0902 37.3057 38.0902 38.4217H46.9076ZM46.9076 30.7832V14.6748C46.9076 13.1063 45.6358 11.833 44.0659 11.833H31.6486V30.7832H46.9076ZM28.3529 30.7832V11.833H15.9342C14.3643 11.833 13.0924 13.1063 13.0924 14.6748V30.7832H28.3529ZM13.0924 38.4217H21.9099C21.9099 36.1881 20.0988 34.3785 17.8652 34.3785H13.0924V38.4217Z"
      fill="#F3654D"
    />
    <path
      d="M46.9077 34.3785V38.4217H38.0902C38.0902 37.3056 38.5426 36.2945 39.2752 35.5619C40.0077 34.8309 41.0174 34.3785 42.1349 34.3785H46.9077Z"
      fill="#BAECFD"
    />
    <path
      d="M46.9076 14.6748V30.7832H31.6486V11.833H44.0659C45.6358 11.833 46.9076 13.1063 46.9076 14.6748Z"
      fill="#9DD2FB"
    />
    <path
      d="M28.3529 11.833V30.7832H13.0924V14.6748C13.0924 14.3063 13.1628 13.9542 13.2916 13.6306C13.7066 12.5775 14.7328 11.833 15.9342 11.833H28.3529Z"
      fill="#9DD2FB"
    />
    <path
      d="M21.9099 38.4217H13.0924V34.3785H17.8652C20.0987 34.3785 21.9099 36.1881 21.9099 38.4217Z"
      fill="#BAECFD"
    />
    <path d="M45.893 54.1133H14.0894V57.2592H45.893V54.1133Z" fill="#A9B3BD" />
    <path d="M17.4025 51.0543L14.0416 60H9.89355L14.1279 51.0543" fill="#CDD6E0" />
    <path d="M42.5982 51.0543L45.9591 60H50.1071L45.8728 51.0543" fill="#CDD6E0" />
    <path d="M33.8203 2.92056H26.1805V5.16762H33.8203V2.92056Z" fill="white" />
    <path d="M43.8593 41.7927H16.1415V44.0398H43.8593V41.7927Z" fill="#D84519" />
    <path d="M43.8593 46.079H16.1415V48.3261H43.8593V46.079Z" fill="#D84519" />
    <path d="M17.9581 11.833H28.353V22.2279L17.9581 11.833Z" fill="#BAECFD" />
    <path
      d="M46.9076 14.6748V30.7832H36.9067L31.6486 25.5251V11.833H44.0659C45.6358 11.833 46.9076 13.1063 46.9076 14.6748Z"
      fill="#BAECFD"
    />
  </Icon>
)

export default TransportRating
