// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Blackboard = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <g>
          <g>
            <path
              d="M360,408H152c-4.422,0-8-3.582-8-8s3.578-8,8-8h208c4.422,0,8,3.582,8,8S364.422,408,360,408z"
              style={{
                fill: '#C9A07C',
              }}
            />
          </g>
        </g>
        <g>
          <path
            d="M359.953,7.102C359.492,3.059,356.07,0,352,0h-32.203c-2.281,0-4.445,0.973-5.969,2.668
			c-1.516,1.699-2.234,3.965-1.984,6.23l4.516,40c0.461,4.043,3.883,7.102,7.953,7.102h32.203c2.281,0,4.445-0.973,5.969-2.668
			c1.516-1.699,2.234-3.965,1.984-6.23L359.953,7.102z"
            style={{
              fill: '#E6BE8A',
            }}
          />
        </g>
        <g>
          <path
            d="M393.375,303.101c-0.461-4.043-3.883-7.102-7.953-7.102h-32.203c-2.281,0-4.445,0.973-5.969,2.668
			c-1.516,1.699-2.234,3.965-1.984,6.23l22.578,200c0.461,4.043,3.883,7.102,7.953,7.102H408c2.281,0,4.445-0.973,5.969-2.668
			c1.516-1.699,2.234-3.965,1.984-6.23L393.375,303.101z"
            style={{
              fill: '#E6BE8A',
            }}
          />
        </g>
        <g>
          <path
            d="M198.07,2.668C196.547,0.973,194.383,0,192.102,0h-32.203c-4.07,0-7.492,3.059-7.953,7.102
			l-4.516,40c-0.25,2.266,0.469,4.531,1.984,6.23c1.523,1.695,3.688,2.668,5.969,2.668h32.203c4.07,0,7.492-3.059,7.953-7.102
			l4.516-40C200.305,6.633,199.586,4.367,198.07,2.668z"
            style={{
              fill: '#E6BE8A',
            }}
          />
        </g>
        <g>
          <path
            d="M164.648,298.668c-1.523-1.695-3.688-2.668-5.969-2.668h-32.203c-4.07,0-7.492,3.059-7.953,7.102
			l-22.578,200c-0.25,2.266,0.469,4.531,1.984,6.23c1.523,1.695,3.687,2.668,5.969,2.668h32.203c4.07,0,7.492-3.059,7.953-7.102
			l22.578-200C166.883,302.633,166.164,300.367,164.648,298.668z"
            style={{
              fill: '#E6BE8A',
            }}
          />
        </g>
        <g>
          <path
            d="M440,40H72c-8.82,0-16,7.176-16,16v240c0,8.824,7.18,16,16,16h368c8.82,0,16-7.176,16-16V56
			C456,47.176,448.82,40,440,40z"
            style={{
              fill: '#00A077',
            }}
          />
        </g>
        <g>
          <g>
            <path
              d="M456,344H56c-4.422,0-8-3.582-8-8s3.578-8,8-8h400c4.422,0,8,3.582,8,8S460.422,344,456,344z"
              style={{
                fill: '#C9A07C',
              }}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M144,512H88c-4.422,0-8-3.582-8-8s3.578-8,8-8h56c4.422,0,8,3.582,8,8S148.422,512,144,512z"
              style={{
                fill: '#C9A07C',
              }}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M424,512h-56c-4.422,0-8-3.582-8-8s3.578-8,8-8h56c4.422,0,8,3.582,8,8S428.422,512,424,512z"
              style={{
                fill: '#C9A07C',
              }}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M344,104H240c-3.5,0-6.594,2.273-7.633,5.613L199.07,216.144l-23.641-59.117
				c-1.219-3.035-4.156-5.027-7.43-5.027h-32c-4.422,0-8,3.582-8,8s3.578,8,8,8h26.586l29.984,74.973
				c1.219,3.043,4.172,5.027,7.43,5.027c0.102,0,0.203-0.004,0.313-0.008c3.383-0.129,6.312-2.375,7.32-5.605L245.883,120H344
				c4.422,0,8-3.582,8-8S348.422,104,344,104z"
              style={{
                fill: '#FFFFFF',
              }}
            />
          </g>
          <g>
            <path
              d="M332.703,145.531c-3.586-2.605-8.578-1.813-11.172,1.762L296,182.398l-25.531-35.105
				c-2.594-3.574-7.594-4.363-11.172-1.762c-3.578,2.598-4.367,7.602-1.766,11.176L286.108,196l-28.577,39.293
				c-2.602,3.574-1.813,8.578,1.766,11.176c1.422,1.035,3.063,1.531,4.695,1.531c2.477,0,4.914-1.141,6.477-3.293L296,209.601
				l25.531,35.105c1.563,2.152,4,3.293,6.477,3.293c1.633,0,3.273-0.496,4.695-1.531c3.578-2.598,4.367-7.602,1.766-11.176
				L305.892,196l28.577-39.293C337.07,153.133,336.281,148.129,332.703,145.531z"
              style={{
                fill: '#FFFFFF',
              }}
            />
          </g>
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(Blackboard)
