// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Subway = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>subway</title>
      <path d="M16 6.267c-6.16 0-11.2 5.040-11.2 11.2s5.040 11.2 11.2 11.2 11.2-5.040 11.2-11.2-5.040-11.2-11.2-11.2zM19.605 15.666h-2.637v7.173h-1.934v-7.173h-2.638v-1.627h7.21v1.627z" />
    </g>
  </Icon>
)

export default React.memo(Subway)
