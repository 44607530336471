// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Posten = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>posten</title>
      <path d="M20.687 11.884c-2.934 0-5.367 2.29-5.582 5.153h-10.305c0.214-5.94 5.153-10.735 11.164-10.735s10.95 4.795 11.164 10.735h-0.93c-0.142-2.862-2.504-5.153-5.51-5.153v0zM20.687 23.049c2.934 0 5.367-2.29 5.582-5.153h0.93c-0.214 5.94-5.153 10.735-11.164 10.735s-10.95-4.795-11.164-10.735h10.306c0.214 2.934 2.576 5.153 5.51 5.153v0z" />
    </g>
  </Icon>
)

export default React.memo(Posten)
