import React from 'react'
import PropTypes from 'prop-types'

const SVGWrapper = ({ children, size, height, width, ...props }) => {
  const computedSize = size ? '24px' : '6em'
  return (
    <svg
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      height={height || computedSize}
      width={width || computedSize}
      {...props}
    >
      {children}
    </svg>
  )
}

SVGWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SVGWrapper.defaultProps = {
  width: null,
  height: null,
  size: '',
}

export default React.memo(SVGWrapper)
