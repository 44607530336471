// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const People = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>people</title>
      <path d="M10.627 7.699c0 1.186-0.977 2.233-2.233 2.233s-2.233-0.977-2.233-2.233 0.976-2.233 2.232-2.233 2.234 0.977 2.234 2.233zM5.951 17.609h0.21v4.396c0 0.628 0.489 1.117 1.117 1.117h2.302c0.628 0 1.117-0.489 1.117-1.117v-0.279c-0.21-0.349-0.349-0.838-0.349-1.256v-5.722c0-1.117 0.698-2.094 1.605-2.512v-0.418c0-0.628-0.489-1.117-1.117-1.117h-4.885c-0.628 0-1.117 0.489-1.117 1.117v4.745c0.001 0.558 0.489 1.047 1.117 1.047zM25.839 7.699c0 1.186-0.977 2.233-2.233 2.233-1.186 0-2.233-0.977-2.233-2.233s0.977-2.233 2.233-2.233c1.256 0 2.233 0.977 2.233 2.233zM20.047 11.747v0.418c0.977 0.418 1.605 1.396 1.605 2.512v5.722c0 0.489-0.139 0.907-0.349 1.256v0.279c0 0.628 0.489 1.117 1.117 1.117h2.302c0.628 0 1.117-0.489 1.117-1.117v-4.396h0.21c0.628 0 1.117-0.489 1.117-1.117v-4.675c0-0.628-0.489-1.117-1.117-1.117h-4.885c-0.558 0.070-1.117 0.559-1.117 1.117zM11.953 14.747v0 5.722c0 0.628 0.489 1.117 1.117 1.117h0.418v5.164c0 0.628 0.489 1.117 1.117 1.117h2.931c0.628 0 1.117-0.489 1.117-1.117v-5.164h0.418c0.628 0 1.117-0.489 1.117-1.117v-5.722c0-0.628-0.489-1.117-1.117-1.117h-5.931c-0.699 0.001-1.187 0.489-1.187 1.117v0zM18.582 10.211c0 1.396-1.117 2.512-2.512 2.512s-2.512-1.117-2.512-2.512 1.117-2.512 2.512-2.512c1.326-0.070 2.512 1.117 2.512 2.512z" />
    </g>
  </Icon>
)

export default React.memo(People)
