// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Walking = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>walking</title>
      <path d="M24.104 17.435c-0.169 0.366-0.525 0.578-0.901 0.578-0.134 0-0.274-0.023-0.409-0.086l-3.248-1.477c-0.24-0.111-0.422-0.308-0.515-0.554l-0.543-1.434-0.702 3.455 2.266 3.45c0.053 0.068 0.096 0.139 0.125 0.222l1.974 4.927c0.202 0.51 0.128 1.042-0.378 1.246-0.119 0.048-0.416 0.106-0.536 0.106-0.395 0-0.762-0.23-0.92-0.621l-1.838-4.594-2.445-2.446-0.711 2.931c-0.043 0.217-0.149 0.381-0.29 0.515l-2.867 3.821c-0.191 0.258-0.485 0.393-0.788 0.393-0.202 0-0.409-0.063-0.587-0.197-0.438-0.328-0.525-0.943-0.197-1.376l2.795-3.729 0.818-5.235c0.009-0.098 1.041-4.398 1.082-4.605l-2.246 1.126-1.828 2.738c-0.189 0.283-0.5 0.438-0.818 0.438-0.189 0-0.381-0.053-0.55-0.162-0.452-0.303-0.571-0.915-0.274-1.367l1.972-2.954c0.096-0.144 0.226-0.26 0.381-0.338l3.94-1.972c0.135-0.068 0.434-0.101 0.443-0.101l1.972 0.005c0.394 0 0.77 0.24 0.919 0.63l1.534 4.056 2.878 1.31c0.495 0.224 0.711 0.807 0.49 1.302zM18.426 9.586c1.134 0 2.059-0.922 2.059-2.059s-0.925-2.060-2.059-2.060c-1.139 0-2.059 0.922-2.059 2.059s0.921 2.060 2.059 2.060z" />
    </g>
  </Icon>
)

export default React.memo(Walking)
