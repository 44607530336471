// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Activities = props => (
  <Icon viewBox="0 0 39 32" {...props}>
    <g>
      <title>activities</title>
      <path d="M23.902 10.9l-13.059 7.305c-0.16 0.090-0.215 0.288-0.123 0.443l3.773 6.332c0.092 0.155 0.298 0.209 0.458 0.119l13.059-7.305c0.16-0.090 0.215-0.288 0.123-0.443l-3.773-6.332c-0.092-0.155-0.298-0.208-0.458-0.119v0zM21.933 16.059l-0.717 1.766c-0.021 0.071-0.004 0.147 0.045 0.204l1.389 1.297c0.111 0.129 0.063 0.311-0.071 0.386-0.035 0.020-0.076 0.032-0.122 0.034l-1.933-0.113c-0.038 0.001-0.074 0.012-0.106 0.030s-0.060 0.043-0.080 0.074l-0.866 1.679c-0.024 0.037-0.056 0.066-0.091 0.086-0.134 0.075-0.321 0.024-0.381-0.134l-0.465-1.814c-0.026-0.069-0.086-0.121-0.16-0.139l-1.937-0.281c-0.213-0.052-0.271-0.318-0.099-0.45l1.659-0.987c0.060-0.046 0.092-0.117 0.087-0.19l-0.344-1.874c-0.007-0.106 0.050-0.193 0.132-0.239s0.188-0.050 0.279 0.009l1.503 1.226c0.063 0.041 0.143 0.049 0.214 0.022l1.711-0.899c0.204-0.079 0.412 0.103 0.353 0.308v0zM28.172 8.129c-1.146 0.641-2.612 0.261-3.274-0.85-0.189-0.317-0.608-0.426-0.935-0.243l-16.604 9.288c-0.328 0.183-0.44 0.589-0.251 0.906 0.662 1.111 0.269 2.531-0.877 3.172-0.328 0.183-0.44 0.589-0.251 0.906l3.766 6.32c0.189 0.317 0.608 0.426 0.935 0.243 1.146-0.641 2.612-0.261 3.274 0.85 0.189 0.317 0.608 0.426 0.935 0.243l16.604-9.288c0.328-0.183 0.44-0.589 0.251-0.906-0.662-1.111-0.269-2.531 0.877-3.172 0.328-0.183 0.44-0.589 0.251-0.906l-3.766-6.32c-0.189-0.317-0.608-0.426-0.935-0.243v0zM29.022 16.854c0.376 0.63 0.152 1.439-0.498 1.803l-13.059 7.305c-0.65 0.364-1.485 0.147-1.861-0.483l-3.773-6.332c-0.376-0.63-0.152-1.439 0.498-1.803l13.059-7.305c0.651-0.364 1.485-0.147 1.861 0.483l3.773 6.333z" />
    </g>
  </Icon>
)

export default React.memo(Activities)
