// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Like = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <path
        d="M500.555,270.85c25.8-28.801,5.101-75-33.6-75H353.554c26.7-47.1,33.003-90,17.402-119.401
	c-9.902-18.898-28.502-30.298-49.801-30.298h-0.3c-32.399,0-37.8,25.199-42.301,45.298c-5.999,27.601-14.099,65.101-71.1,104.401
	H150c-8.401,0-15,6.599-15,15v210c0,8.399,6.599,15,15,15h29.853l12.004,3.3c46.498,13.2,94.797,26.7,144.895,26.7h70.203
	c31.802,0,53.701-32.1,41.7-61.5c26.1-7.202,39.899-35.402,30-60c15.3-4.2,26.1-15.3,30.597-28.5
	C514.658,301.15,512.255,284.049,500.555,270.85z"
        style={{
          fill: '#FEDBAB',
        }}
      />
      <path
        d="M478.655,344.35c9.899,24.598-3.9,52.798-30,60c12.001,29.399-9.899,61.5-41.7,61.5h-70.203
	c-50.098,0-98.397-13.5-144.895-26.7l-12.004-3.3H150c-8.401,0-15-6.601-15-15v-105h374.252
	C504.755,329.049,493.956,340.15,478.655,344.35z"
        style={{
          fill: '#FEC478',
        }}
      />
      <path
        d="M120,165.85H15c-8.401,0-15,6.599-15,15v270c0,8.399,6.599,15,15,15h105c24.902,0,45-20.101,45-45
	v-210C165,185.949,144.902,165.85,120,165.85z"
        style={{
          fill: '#17ACE8',
        }}
      />
      <path
        d="M165,315.85v105c0,24.899-20.098,45-45,45H15c-8.401,0-15-6.601-15-15v-135H165z"
        style={{
          fill: '#1689FC',
        }}
      />
      <circle
        cx="90"
        cy="390.85"
        r="15"
        style={{
          fill: '#C5C9F7',
        }}
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(Like)
