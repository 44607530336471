// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const GreenTransport = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <path
        style={{ fill: '#88CC2A' }}
        d="M270.365,442.023c-51.341,0-102.682-19.541-141.764-58.629l0,0
        c-37.809-37.809-58.64-88.16-58.64-141.769s20.83-103.954,58.64-141.764C238.876-10.425,485.247-0.015,495.635,0.469
        c8.604,0.413,15.489,7.293,15.892,15.897c0.5,10.431,10.899,256.743-99.387,367.028
        C373.059,422.476,321.718,442.023,270.365,442.023z"
      />
      <g>
        <path
          style={{ fill: '#7FB335' }}
          d="M270.365,442.023c51.352,0,102.693-19.547,141.775-58.629
          c110.286-110.286,99.887-356.596,99.387-367.028c-0.202-4.304-2.026-8.176-4.874-11.023L128.602,383.394
          C167.684,422.482,219.025,442.023,270.365,442.023z"
        />
        <path
          style={{ fill: '#7FB335' }}
          d="M294.002,234.706c-9.224,0-16.708-7.478-16.708-16.708V99.855c0-9.229,7.484-16.708,16.708-16.708
          c9.224,0,16.708,7.478,16.708,16.708v118.144C310.71,227.228,303.226,234.706,294.002,234.706z"
        />
      </g>
      <path
        style={{ fill: '#73A62A' }}
        d="M412.141,234.706H294.002c-9.224,0-16.708-7.478-16.708-16.708c0-9.229,7.484-16.708,16.708-16.708
        h118.139c9.224,0,16.708,7.478,16.708,16.708C428.849,227.228,421.365,234.706,412.141,234.706z"
      />
      <path
        style={{ fill: '#7FB335' }}
        d="M187.676,341.032c-9.224,0-16.708-7.478-16.708-16.708V206.181c0-9.229,7.484-16.708,16.708-16.708
        c9.224,0,16.708,7.478,16.708,16.708v118.144C204.384,333.554,196.9,341.032,187.676,341.032z"
      />
      <path
        style={{ fill: '#73A62A' }}
        d="M305.816,341.032H187.676c-9.224,0-16.708-7.478-16.708-16.708c0-9.229,7.484-16.708,16.708-16.708
        h118.138c9.224,0,16.708,7.478,16.708,16.708C322.522,333.554,315.039,341.032,305.816,341.032z"
      />
      <path
        style={{ fill: '#7FB335' }}
        d="M16.707,511.996c-4.275,0-8.55-1.632-11.812-4.895c-6.526-6.52-6.526-17.104,0-23.626L388.516,99.861
        c6.526-6.526,17.1-6.526,23.626,0c6.526,6.52,6.526,17.104,0,23.626L28.519,507.102C25.257,510.365,20.982,511.996,16.707,511.996z"
      />
      <path
        style={{ fill: '#73A62A' }}
        d="M16.707,511.996c4.275,0,8.55-1.632,11.812-4.895l383.622-383.615c6.526-6.52,6.526-17.104,0-23.626
        c0-0.001-0.002-0.001-0.002-0.001L4.894,507.102C8.157,510.365,12.432,511.996,16.707,511.996z"
      />
    </g>
  </Icon>
)

export default React.memo(GreenTransport)
