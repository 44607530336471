import styled, { css } from 'styled-components'
import transition from 'styled-transition-group'

import media from '../media'

export const MenuWrapper = styled.div`
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: ${({ theme }) => `${theme.headerMenuShadow || '0 0 0 1px'} ${theme.borderColor}`};
  z-index: 4;
  color: ${({ theme }) => theme.headerFontColor};

  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.headerColor || 'transparent'};
      padding: 0 ${theme.spacing};

      ${media.small`
        padding: 0 ${theme.spacingSmall};
      `};
    `};
`

export const MenuContent = styled.div`
  max-width: ${({ wide, theme }) => (!wide ? `${theme.media.sizes.large}px` : '100%')};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const MenuBack = styled.div`
  flex: 1 1 150px;

  a {
    color: currentColor;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;

    ${media.small`
      font-size: 16px;
      line-height: 19px;
    `};
  }
`

export const MenuNavigation = styled.nav`
  flex: 0 1 0;
  line-height: 30px;
  text-align: right;
  top: 0;
  position: relative;

  button {
    height: ${({ theme }) => theme.headerMenuBoxHeight || '54px'};
    box-sizing: border-box;
  }

  ${media.small`
    flex: 1 1 0;
    
    ${({ isOpen }) =>
      isOpen &&
      css`
        text-align: center;
        button {
          text-align: center;
        }
      `};
  `};

  .focus-trap {
    outline: none;
  }
`

export const FocusWrapper = styled.div`
  outline: none;
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.headerMenuBGColor || theme.white};
  border-radius: 4px;

  ${media.small`
    border-radius: 0;
  `};

  &:focus {
    outline: none;
  }
`

export const MenuNavigationContent = transition.div`
  position: fixed;
  display: block;
  top: 54px;
  right: ${({ wide, theme }) => (wide ? 0 : `calc(50vw - ${theme.media.sizes.large}px / 2)`)};
  max-width: 375px;
  width: 100%;
  min-height: 200px;
  height: auto;
  font-family: ${({ theme }) =>
    theme.fontFamily}; // required to render correct font-family inside portal
  background-color: ${({ theme }) => theme.headerMenuBGColor || theme.white};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  border-radius: 5px;
  transform: translateX(0px);
  z-index: 99;

  ${media.small`
    max-width: 100%;
    bottom: 0;
    right: 0;
    height: calc(100vh - 54px);
    border-radius: 0;
    overflow-y: scroll;
  `};

  &:enter {
    opacity: 0;
    transform: translateX(100px);
  }

  &:enter-active {
    opacity: 1;
    transition: opacity 400ms ease, transform 400ms ease;
    transform: translateX(0px);
  }

  &:exit {
    opacity: 1;
    transform: translateX(0px);
  }

  &:exit-active {
    opacity: 0;
    transform: translateX(250px);
    transition: opacity 400ms ease, transform 400ms ease;
  }
`

export const Button = styled.button`
  padding: 0;
  min-width: 5rem;
  border: ${({ theme }) => theme.headerMenuBorder || 'none'};
  border-radius: ${({ theme }) => theme.headerMenuBorderRadius || '4px'};
  color: currentColor;
  user-select: none;
  background-color: ${({ theme }) => theme.headerMenuBackground || 'transparent'};

  &:hover {
    cursor: pointer;
  }

  ${media.small`
    ${({ isOpen }) =>
      isOpen &&
      css`
        width: 100%;
      `};
    `};
`

export const ButtonContent = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${({ theme }) => theme.headerMenuBoxHeight || '54px'};
  height: ${({ theme }) => theme.headerMenuBoxHeight || '54px'};
  padding: 0 1.25rem;
`

export const ButtonText = styled.span`
  vertical-align: middle;
  line-height: ${({ theme }) => theme.headerMenuBoxHeight || '54px'};
  font-size: 18px;
  height: ${({ theme }) => theme.headerMenuBoxHeight || '54px'};
  position: relative;
  top: 0;
`

export const ButtonIcon = styled.span`
  padding-left: 10px;
  line-height: ${({ theme }) => theme.headerMenuBoxHeight || '54px'};

  svg {
    top: 7px;
    position: relative;
  }
`

export const MenuList = styled.ul`
  margin: 0;
  line-height: 34px;
  display: block;
  padding: ${({ theme }) => `${theme.spacing} 0 ${theme.spacing} ${theme.spacing}`};
  color: ${({ theme }) => theme.headerMenuFontColor};
`

export const MenuItem = styled.li`
  list-style: none;
  font-size: 18px;
  line-height: 45px;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`

export const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  &:hover {
    i {
      color: ${({ theme }) => theme.brandColor};
    }
    svg {
      fill: ${({ theme }) => theme.headerMenuIconHoverColor};
    }
  }
`

export const LinkText = styled.span`
  flex: 1 1 87.5%;
  ${({ isLast, theme }) =>
    isLast ? '' : `border-bottom: 1px solid ${theme.headerMenuBorderColor};`};
  vertical-align: middle;
`

export const LinkIcon = styled.i`
  flex: 0 1 34px;
  color: ${({ theme }) => theme.headerMenuIconColor};
  height: 45px;
  width: 28px;
  vertical-align: middle;

  svg {
    vertical-align: middle;
    position: relative;
    top: -3px;
  }

  ${({ topoffset }) =>
    topoffset &&
    css`
      svg {
        top: -5px;
      }
    `};
`

export const MenuHeader = styled.h4`
  text-transform: uppercase;
  text-align: left;
  display: block;
  margin: 0;
  color: ${({ theme }) => theme.headerMenuHeading};
  letter-spacing: 0.7px;
`

export const MenuBackdrop = transition.div`
  position: fixed;
  top: ${({ theme }) => theme.headerMenuBackDrop || '54px'};
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.black};
  opacity: .5;
  z-index: 1;

  &:enter {
    opacity: 0;
  }

  &:enter-active {
    opacity: .5;
    transition: opacity 400ms ease;
  }

  &:exit {
    opacity: .5;
  }

  &:exit-active {
    opacity: 0;
    transition: opacity 400ms ease;
  }
`
