import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const SkiSlope = props => (
  <Icon viewBox="0 0 447.6 447.6" {...props}>
    <g>
      <path
        d="M298.8,56.3H203c0,0-6.5,14.3-17.5,27.4c-14.6,17.4-36.7,43.7-36.7,91.7c0,48,22.1,74.3,36.7,91.7
		c11,13.1,13.3,16.2,13.3,27.4c0,11.2-2.3,14.3-13.3,27.4c-14.6,17.4-36.7,43.7-36.7,91.7h97.5c0,0,4.9-14.3,15.8-27.4
		c14.6-17.4,36.7-43.7,36.7-91.7c0-48-22.1-74.3-36.7-91.7c-11-13.1-13.3-16.2-13.3-27.4c0-11.2,2.3-14.3,13.3-27.4
		C276.7,130.6,298.8,104.3,298.8,56.3z"
      />
      <path
        d="M148.2,260.5l-49.9-24c-0.8-0.8-2.1-0.8-2.9,0v29.4v21.4v49.4c0,2.2,1.8,4,4,4h2.1c2.2,0,4-1.8,4-4v-52.9
		l42.7-20.5C149,262.6,149,261.3,148.2,260.5z"
      />
      <path
        d="M364.6,150.5l-49.9-24c-0.8-0.8-2.1-0.8-2.9,0v29.4v21.4v49.4c0,2.2,1.8,4,4,4h2.1c2.2,0,4-1.8,4-4v-52.9
		l42.7-20.5C365.4,152.6,365.4,151.3,364.6,150.5z"
      />
    </g>
  </Icon>
)

export default React.memo(SkiSlope)
