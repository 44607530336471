import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const SkiResort = props => (
  <Icon viewBox="0 0 447.6 447.6" {...props}>
    <path
      d="M365.6,292.4l-34.3-19.8l29.7-17.5c5.5-3.2,7.3-10.3,4.1-15.8c-3.2-5.5-10.3-7.3-15.8-4.1l-40.9,24.1
	l-24.1-13.9v-43.4l24.1-13.9l40.9,24.1c1.8,1.1,3.8,1.6,5.8,1.6c3.9,0,7.8-2,9.9-5.7c3.2-5.5,1.4-12.5-4.1-15.8L331.4,175l34.3-19.8
	c5.5-3.2,7.4-10.2,4.2-15.7c-3.2-5.5-10.2-7.4-15.7-4.2L319.9,155l-0.3-34.5c-0.1-6.4-5.3-11.5-11.6-11.4
	c-6.4,0.1-11.5,5.3-11.4,11.6l0.4,47.5l-24.1,13.9l-37.6-21.7v-27.8l41.3-23.4c5.5-3.1,7.5-10.1,4.4-15.7
	c-3.1-5.5-10.2-7.5-15.7-4.4l-30,17V66.6c0-6.4-5.2-11.5-11.5-11.5c-6.4,0-11.5,5.2-11.5,11.5v39.6l-30-17
	c-5.5-3.1-12.6-1.2-15.7,4.4c-3.1,5.5-1.2,12.6,4.4,15.7l41.3,23.4v27.8l-37.6,21.7l-24.1-13.9l0.4-47.5c0.1-6.4-5-11.6-11.4-11.6
	c0,0-0.1,0-0.1,0c-6.3,0-11.5,5.1-11.5,11.4l-0.3,34.5l-34.3-19.8c-5.5-3.2-12.5-1.3-15.7,4.2c-3.2,5.5-1.3,12.5,4.2,15.7l34.3,19.8
	l-29.7,17.5c-5.5,3.2-7.3,10.3-4.1,15.8c2.1,3.6,6,5.7,9.9,5.7c2,0,4-0.5,5.8-1.6l40.9-24.1l24.1,13.9v43.4l-24.1,13.9l-40.9-24.1
	c-5.5-3.2-12.5-1.4-15.8,4.1c-3.2,5.5-1.4,12.5,4.1,15.8l29.7,17.5l-34.3,19.8c-5.5,3.2-7.4,10.2-4.2,15.7c2.1,3.7,6,5.8,10,5.8
	c2,0,3.9-0.5,5.7-1.5l34.3-19.8L128,327c0.1,6.3,5.2,11.4,11.5,11.4c0,0,0.1,0,0.1,0c6.4-0.1,11.5-5.3,11.4-11.6l-0.4-47.5
	l24.1-13.9l37.6,21.7v27.8l-41.3,23.4c-5.5,3.1-7.5,10.1-4.4,15.7c2.1,3.7,6,5.8,10,5.8c1.9,0,3.9-0.5,5.7-1.5l30-17v39.6
	c0,6.4,5.2,11.5,11.5,11.5c6.4,0,11.5-5.2,11.5-11.5v-39.6l30,17c1.8,1,3.7,1.5,5.7,1.5c4,0,7.9-2.1,10-5.8
	c3.1-5.5,1.2-12.6-4.4-15.7l-41.3-23.4v-27.8l37.6-21.7l24.1,13.9l-0.4,47.5c-0.1,6.4,5,11.6,11.4,11.6c0,0,0.1,0,0.1,0
	c6.3,0,11.5-5.1,11.5-11.4l0.3-34.5l34.3,19.8c1.8,1,3.8,1.5,5.7,1.5c4,0,7.8-2.1,10-5.8C373,302.6,371.1,295.6,365.6,292.4z
	 M186.2,202.1l37.6-21.7l37.6,21.7v43.4l-37.6,21.7l-37.6-21.7V202.1z"
    />
  </Icon>
)

export default React.memo(SkiResort)
