import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Bullets = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path
        fill="currentColor"
        d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </g>
  </Icon>
)

export default React.memo(Bullets)
