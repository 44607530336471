import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled, { css, withTheme } from 'styled-components'

import media from './media'
import { withTranslation } from '../../../i18n'
import InternalLink from '../../utils/internal-link'

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledNav = styled.nav`
  display: block;
  box-sizing: box-sizing;
  width: 100%;
`

const StyledNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacingLarge};

  ${media.small`
    padding: ${({ theme }) => theme.spacing};
    flex-direction: column;
  `};

  /* Override to be used in travel-time column */
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: ${({ theme }) => theme.spacing};
      flex-direction: column;
    `};
`
const StyledNavItem = styled.li`
  padding: 10px;
`

const StyledNavLink = styled.a`
  text-decoration: ${({ theme }) => (theme.linkDecoration === 'none' ? 'none' : 'underline')};
  color: ${({ theme }) => theme.footerLinkColor};
  font-size: 18px;

  &:hover {
    color: ${({ theme }) => theme.footerLinkHoverColor};
    text-decoration: ${({ theme }) => (theme.linkDecoration === 'none' ? 'underline' : 'none')};
  }
`

const Footer = React.memo(({ finnkode, t, isMobile }) => {
  return (
    <StyledFooter>
      <StyledNav>
        <StyledNavList isMobile={isMobile}>
          <StyledNavItem>
            <InternalLink route={finnkode ? 'about' : 'aboutNoId'} passHref>
              <StyledNavLink>{t('common:footerMenu.about')}</StyledNavLink>
            </InternalLink>
          </StyledNavItem>
          <StyledNavItem>
            <InternalLink route={finnkode ? 'terms' : 'termsNoId'} passHref>
              <StyledNavLink>{t('common:footerMenu.terms')}</StyledNavLink>
            </InternalLink>
          </StyledNavItem>
          <StyledNavItem>
            <InternalLink route={finnkode ? 'sources' : 'sourcesNoId'} passHref>
              <StyledNavLink>{t('common:footerMenu.sources')}</StyledNavLink>
            </InternalLink>
          </StyledNavItem>
        </StyledNavList>
      </StyledNav>
    </StyledFooter>
  )
})

Footer.propTypes = {
  finnkode: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

Footer.defaultProps = {}

export default compose(withTranslation(['common']), withTheme)(Footer)
