// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Places = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>places</title>
      <path d="M23.722 20.319l3.424 7.058c0.14 0.279 0 0.489-0.35 0.489h-21.594c-0.35 0-0.489-0.21-0.35-0.489l3.424-7.058c0.070-0.14 0.21-0.21 0.35-0.21h2.935c0.070 0 0.21 0.070 0.279 0.14 0.21 0.21 0.419 0.489 0.629 0.699s0.419 0.419 0.559 0.629h-3.494c-0.14 0-0.279 0.070-0.35 0.21l-2.306 4.682h18.1l-2.306-4.682c-0.070-0.14-0.21-0.21-0.35-0.21h-3.494c0.21-0.21 0.419-0.419 0.559-0.629 0.21-0.21 0.419-0.419 0.629-0.699 0.070-0.070 0.21-0.14 0.279-0.14h2.935c0.281 0.001 0.42 0.070 0.49 0.21v0zM15.978 9.563c-2.488 0-4.505 2.059-4.505 4.59 0 0.815 0.214 1.587 0.601 2.274l3.475 6.35c0.086 0.172 0.258 0.258 0.472 0.258s0.343-0.086 0.472-0.258l3.432-6.35c0.386-0.686 0.601-1.502 0.601-2.274 0-2.531-2.059-4.59-4.548-4.59zM15.978 15.527c-0.73 0-1.373-0.601-1.373-1.373s0.601-1.373 1.373-1.373c0.73 0 1.373 0.601 1.373 1.373s-0.6 1.373-1.373 1.373z" />
    </g>
  </Icon>
)

export default React.memo(Places)
