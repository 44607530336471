// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Attraction = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>attraction</title>
      <path d="M22.934 20.535h-2.585v-5.070h2.585c2.426 0 4.399-1.973 4.399-4.399s-1.973-4.399-4.399-4.399c-2.425 0-4.399 1.973-4.399 4.399v2.585h-5.070v-2.585c0-2.425-1.974-4.399-4.399-4.399s-4.399 1.973-4.399 4.399c0 2.426 1.973 4.399 4.399 4.399h2.585v5.070h-2.585c-2.425 0-4.399 1.973-4.399 4.399s1.973 4.399 4.399 4.399 4.399-1.973 4.399-4.399v-2.585h5.070v2.585c0 2.426 1.974 4.399 4.399 4.399s4.399-1.973 4.399-4.399c0-2.425-1.973-4.399-4.399-4.399v0zM20.349 11.066c0-1.425 1.16-2.585 2.585-2.585s2.585 1.16 2.585 2.585c0 1.426-1.16 2.585-2.585 2.585h-2.585v-2.585zM9.066 13.651c-1.425 0-2.585-1.16-2.585-2.585s1.16-2.585 2.585-2.585c1.426 0 2.585 1.16 2.585 2.585v2.585h-2.585zM11.651 24.934c0 1.426-1.159 2.585-2.585 2.585s-2.585-1.159-2.585-2.585c0-1.425 1.16-2.585 2.585-2.585h2.585v2.585zM13.465 20.535v-5.070h5.070v5.070h-5.070zM22.934 27.519c-1.425 0-2.585-1.159-2.585-2.585v-2.585h2.585c1.426 0 2.585 1.16 2.585 2.585s-1.16 2.585-2.585 2.585v0z" />
    </g>
  </Icon>
)

export default React.memo(Attraction)
