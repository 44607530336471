// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Bike = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>bike</title>
      <path d="M26.122 14.233c-0.198 0-0.395 0.011-0.588 0.030l-1.534-6.387c-0.075-0.312-0.354-0.531-0.675-0.531h-2.387c-0.383 0-0.694 0.311-0.694 0.694s0.311 0.694 0.694 0.694h1.84l0.519 2.162h-10.346l-0.523-1.881h1.927c0.383 0 0.694-0.311 0.694-0.694s-0.311-0.694-0.694-0.694h-2.84c-0.217 0-0.421 0.101-0.552 0.274s-0.174 0.396-0.116 0.605l0.837 3.012-2.806 3.552c-0.88-0.526-1.905-0.834-3.002-0.834-3.241-0-5.878 2.637-5.878 5.878s2.636 5.877 5.877 5.877c3.049 0 5.562-2.334 5.849-5.309h3.033c0 0 0.001 0 0.001 0 0.061 0 0.123-0.008 0.185-0.025 0.017-0.005 0.032-0.013 0.049-0.019 0.012-0.004 0.023-0.009 0.035-0.013 0.071-0.030 0.135-0.070 0.19-0.119 0.004-0.003 0.009-0.005 0.012-0.008l8.483-7.869 0.467 1.945c-2.288 0.805-3.935 2.981-3.935 5.541 0 3.241 2.636 5.877 5.877 5.877s5.877-2.636 5.877-5.877-2.637-5.878-5.878-5.878zM12.145 13.171l1.701 6.12h-2.156c-0.185-1.316-0.802-2.493-1.71-3.38l2.165-2.739zM9.114 17.008c0.594 0.619 1.012 1.407 1.175 2.284h-2.979l1.804-2.284zM5.877 24.601c-2.476 0-4.49-2.014-4.49-4.49s2.014-4.49 4.49-4.49c0.773 0 1.5 0.197 2.136 0.542l-2.681 3.393c-0.165 0.209-0.196 0.493-0.080 0.733s0.358 0.391 0.624 0.391h4.45c-0.281 2.208-2.167 3.922-4.45 3.922zM15.123 18.702l-1.784-6.42h8.706l-6.921 6.42zM26.122 24.601c-2.476 0-4.49-2.014-4.49-4.49 0-1.906 1.195-3.534 2.874-4.184l0.941 3.921c0.077 0.318 0.361 0.531 0.674 0.531 0.053 0 0.108-0.006 0.163-0.019 0.372-0.089 0.602-0.464 0.512-0.837l-0.934-3.889c0.086-0.005 0.172-0.013 0.259-0.013 2.476 0 4.49 2.014 4.49 4.49s-2.014 4.49-4.49 4.49z" />
    </g>
  </Icon>
)

export default React.memo(Bike)
