import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const TrafficLight = props => (
  <Icon width="60" height="60" viewBox="0 0 60 60" {...props}>
    <path
      d="M33.5156 49.3359H26.4844C25.4997 49.3359 24.7266 50.1091 24.7266 51.0937V58.2422C24.7266 59.2264 25.4997 60 26.4844 60H33.5156C34.5003 60 35.2734 59.2264 35.2734 58.2422V51.0937C35.2734 50.1091 34.5003 49.3359 33.5156 49.3359Z"
      fill="#384949"
    />
    <path
      d="M19.4531 17.5781C14.6072 17.5781 10.6641 13.6349 10.6641 8.78906C10.6641 7.81769 11.4505 7.03125 12.4219 7.03125H19.4531C20.4245 7.03125 21.2109 7.81769 21.2109 8.78906V15.8203C21.2109 16.7917 20.4245 17.5781 19.4531 17.5781Z"
      fill="#384949"
    />
    <path
      d="M19.4531 31.7578C14.6072 31.7578 10.6641 27.8146 10.6641 22.9687C10.6641 21.9974 11.4505 21.2109 12.4219 21.2109H19.4531C20.4245 21.2109 21.2109 21.9974 21.2109 22.9687V30C21.2109 30.9714 20.4245 31.7578 19.4531 31.7578Z"
      fill="#384949"
    />
    <path
      d="M19.4531 45.8203C14.6072 45.8203 10.6641 41.8771 10.6641 37.0312C10.6641 36.0599 11.4505 35.2734 12.4219 35.2734H19.4531C20.4245 35.2734 21.2109 36.0599 21.2109 37.0312V44.0625C21.2109 45.0339 20.4245 45.8203 19.4531 45.8203Z"
      fill="#384949"
    />
    <path
      d="M37.0312 0H22.9687C20.0505 0 17.6953 2.35519 17.6953 5.27344V47.5781C17.6953 50.4959 20.0505 52.8516 22.9687 52.8516H37.0312C39.9495 52.8516 42.3047 50.4959 42.3047 47.5781V5.27344C42.3047 2.35519 39.9495 0 37.0312 0Z"
      fill="#526675"
    />
    <path
      d="M40.5469 17.5781C39.5755 17.5781 38.7891 16.7917 38.7891 15.8203V8.78906C38.7891 7.81769 39.5755 7.03125 40.5469 7.03125H47.5781C48.5495 7.03125 49.3359 7.81769 49.3359 8.78906C49.3359 13.6349 45.3928 17.5781 40.5469 17.5781Z"
      fill="#384949"
    />
    <path
      d="M40.5469 31.7578C39.5755 31.7578 38.7891 30.9714 38.7891 30V22.9688C38.7891 21.9974 39.5755 21.2109 40.5469 21.2109H47.5781C48.5495 21.2109 49.3359 21.9974 49.3359 22.9688C49.3359 27.8146 45.3928 31.7578 40.5469 31.7578Z"
      fill="#384949"
    />
    <path
      d="M40.5469 45.8203C39.5755 45.8203 38.7891 45.0339 38.7891 44.0625V37.0313C38.7891 36.0599 39.5755 35.2734 40.5469 35.2734H47.5781C48.5495 35.2734 49.3359 36.0599 49.3359 37.0313C49.3359 41.8771 45.3928 45.8203 40.5469 45.8203Z"
      fill="#384949"
    />
    <path
      d="M42.3047 5.27344V47.5781C42.3047 50.4959 39.9495 52.8516 37.0312 52.8516H30V0H37.0312C39.9495 0 42.3047 2.35519 42.3047 5.27344Z"
      fill="#526675"
    />
    <path
      d="M30 7.03125C27.0818 7.03125 24.7266 9.38644 24.7266 12.3047C24.7266 15.2225 27.0818 17.5781 30 17.5781C32.9182 17.5781 35.2734 15.2225 35.2734 12.3047C35.2734 9.38644 32.9182 7.03125 30 7.03125Z"
      fill="#F3654D"
    />
    <path
      d="M30 21.2109C27.0818 21.2109 24.7266 23.5661 24.7266 26.4844C24.7266 29.4022 27.0818 31.7578 30 31.7578C32.9182 31.7578 35.2734 29.4022 35.2734 26.4844C35.2734 23.5661 32.9182 21.2109 30 21.2109Z"
      fill="#F3BF3F"
    />
    <path
      d="M30 35.2734C27.0818 35.2734 24.7266 37.6286 24.7266 40.5469C24.7266 43.4647 27.0818 45.8203 30 45.8203C32.9182 45.8203 35.2734 43.4647 35.2734 40.5469C35.2734 37.6286 32.9182 35.2734 30 35.2734Z"
      fill="#56C696"
    />
  </Icon>
)

export default TrafficLight
