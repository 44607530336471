// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Down = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>down</title>
      <path d="M22 13.686l-1-1.019-5 5-4.981-5-1.019 1.019 6 5.981z" />
    </g>
  </Icon>
)

export default React.memo(Down)
