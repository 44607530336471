// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Gym = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>gym</title>
      <path d="M26.303 26.051c0.248 0 0.459 0.087 0.634 0.262s0.262 0.387 0.262 0.635-0.087 0.463-0.262 0.646-0.386 0.273-0.634 0.273h-18.878c-0.218 0-0.412-0.073-0.58-0.218s-0.274-0.328-0.317-0.547l-1.706-9.844c-0.014-0.014-0.022-0.036-0.022-0.066s0-0.058 0-0.087v-0.11c0-0.262 0.087-0.482 0.262-0.656s0.386-0.262 0.634-0.262h4.659c0.248 0 0.463 0.087 0.646 0.262s0.274 0.394 0.274 0.656c0 0.248-0.091 0.459-0.274 0.634s-0.398 0.262-0.646 0.262h-3.587l1.422 8.159h18.113zM14.162 14.545c-1.079 0.321-2.111 0.295-3.095-0.077s-1.849-1.075-2.592-2.111c-0.379-0.525-0.277-0.955 0.306-1.29s1.065-0.241 1.444 0.284c0.569 0.802 1.229 1.262 1.98 1.378s1.542-0.073 2.374-0.569c0.044-0.029 0.161-0.094 0.35-0.197s0.314-0.175 0.372-0.218c0.656-0.394 1.313-0.653 1.969-0.777s1.29-0.117 1.903 0.022 1.192 0.401 1.739 0.787c0.547 0.386 1.039 0.894 1.477 1.52 0.379 0.525 0.277 0.955-0.306 1.29s-1.058 0.241-1.422-0.284c-0.466-0.642-0.988-1.061-1.564-1.258s-1.185-0.194-1.826 0.011c0.335 0.569 0.66 1.156 0.974 1.761s0.674 1.309 1.083 2.111c0.394 0.802 0.995 1.444 1.805 1.925s1.644 0.466 2.505-0.044c0.613-0.35 1.108-0.23 1.487 0.361s0.255 1.068-0.372 1.433c-0.583 0.335-1.185 0.543-1.805 0.623s-1.218 0.054-1.794-0.077c-0.576-0.131-1.116-0.354-1.618-0.667s-0.93-0.696-1.28-1.149c-0.029 0.014-0.054 0.029-0.077 0.044s-0.047 0.029-0.077 0.044c-0.569 0.19-1.17 0.496-1.805 0.918s-1.068 0.729-1.302 0.918c-0.234 0.19-0.518 0.66-0.853 1.411s-0.62 1.367-0.853 1.849c-0.292 0.583-0.74 0.733-1.346 0.449s-0.77-0.718-0.492-1.302c0.262-0.554 0.613-1.276 1.050-2.166s0.795-1.451 1.072-1.684c0.277-0.218 0.656-0.503 1.138-0.853s0.948-0.642 1.4-0.875c-0.306-0.598-0.62-1.192-0.941-1.783-0.324-0.59-0.659-1.177-1.010-1.76zM13.528 11.919c-0.554 0-1.028-0.194-1.422-0.58s-0.59-0.857-0.59-1.411 0.197-1.028 0.59-1.422c0.394-0.394 0.868-0.59 1.422-0.59 0.539 0 1.006 0.197 1.4 0.59s0.59 0.868 0.59 1.422-0.197 1.025-0.59 1.411-0.86 0.58-1.4 0.58z" />
    </g>
  </Icon>
)

export default React.memo(Gym)
