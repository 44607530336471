// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Airplane = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>airplane</title>
      <path d="M26.57 10.812c-0.455-0.788-1.507-1.070-2.295-0.615l-5.334 3.080-9.33-4.959-1.94 1.12 6.905 6.359-5.334 3.080-2.575-1.1-1.455 0.84 2.93 2.835 0.99 3.954 1.455-0.84 0.335-2.78 5.334-3.080 2.055 9.16 1.94-1.12 0.37-10.559 5.334-3.080c0.789-0.455 1.070-1.507 0.615-2.295z" />
    </g>
  </Icon>
)

export default React.memo(Airplane)
