import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
  if (typeof document !== 'undefined') {
    const portalRoot = document.getElementById('portalRoot')

    return createPortal(children, portalRoot)
  }

  return null
}

export default Portal
