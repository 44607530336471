import React from 'react'
import PropTypes from 'prop-types'

import { MenuNavigation as Nav, MenuBackdrop } from '../../style'
import Portal from '../../../../Portal'

import NavToggleButton from './NavToggleButton'
import PopOverNav from './PopOverNav'

const MenuNavigation = ({
  wide,
  isOpen,
  toggleMenu,
  handleExit,
  showTravelTime,
  showCompareBox,
  categories,
}) => {
  return (
    <Nav>
      <NavToggleButton isOpen={isOpen} onClick={toggleMenu} />
      <Portal>
        <MenuBackdrop onClick={handleExit} in={isOpen} unmountOnExit timeout={400} />
        <PopOverNav
          wide={wide}
          isOpen={isOpen}
          handleExit={handleExit}
          showTravelTime={showTravelTime}
          showCompareBox={showCompareBox}
          categories={categories}
        />
      </Portal>
    </Nav>
  )
}

MenuNavigation.propTypes = {
  wide: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
  showTravelTime: PropTypes.bool.isRequired,
  showCompareBox: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
}

export default MenuNavigation
