import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Businesses = props => (
  <Icon width="60" height="60" viewBox="0 0 60 60" {...props}>
    <path
      d="M42.3047 8.90625V12.4219C42.3047 13.4061 41.5314 14.1797 40.5469 14.1797H38.7891V8.90625C38.7891 7.92176 38.0157 7.14844 37.0312 7.14844H22.9688C21.9843 7.14844 21.2109 7.92176 21.2109 8.90625V14.1797H19.4531C18.4686 14.1797 17.6953 13.4061 17.6953 12.4219V8.90625C17.6953 5.98816 20.0507 3.63281 22.9688 3.63281H37.0312C39.9493 3.63281 42.3047 5.98816 42.3047 8.90625Z"
      fill="#6E6E6E"
    />
    <path
      d="M37.0312 7.14844H30V3.63281H37.0312C39.9493 3.63281 42.3047 5.98816 42.3047 8.90625V12.4219C42.3047 13.4061 41.5314 14.1797 40.5469 14.1797H38.7891V8.90625C38.7891 7.92176 38.0157 7.14844 37.0312 7.14844Z"
      fill="#444444"
    />
    <path
      d="M60 12.4219V51.0938C60 54.0116 57.6446 56.3672 54.7266 56.3672H5.27344C2.35535 56.3672 0 54.0116 0 51.0938V12.4219C0 11.9297 0.210937 11.5078 0.527461 11.1913L9.49219 31.3711H50.5078L59.4727 11.1912C59.7891 11.5078 60 11.9297 60 12.4219Z"
      fill="#5A5A5A"
    />
    <path
      d="M60 12.4219V51.0938C60 54.0116 57.6446 56.3672 54.7266 56.3672H30V31.3711H50.5078L59.4727 11.1912C59.7891 11.5078 60 11.9297 60 12.4219Z"
      fill="#444444"
    />
    <path
      d="M59.4725 11.1913L54.0234 31.3711C53.3906 33.6913 51.3165 35.2734 48.9258 35.2734H11.0743C8.68348 35.2734 6.60938 33.6914 5.97657 31.3711L0.527466 11.1913C0.843755 10.875 1.26563 10.6641 1.75782 10.6641H58.2422C58.7344 10.6641 59.1563 10.875 59.4725 11.1913Z"
      fill="#6E6E6E"
    />
    <path
      d="M59.4725 11.1913L54.0234 31.3711C53.3906 33.6913 51.3165 35.2734 48.9258 35.2734H30V10.6641H58.2422C58.7344 10.6641 59.1562 10.875 59.4725 11.1913Z"
      fill="#5A5A5A"
    />
    <path
      d="M37.0312 30H22.9688C21.9843 30 21.2109 30.7733 21.2109 31.7578V38.7891C21.2109 39.7733 21.9843 40.5469 22.9688 40.5469H37.0312C38.0157 40.5469 38.7891 39.7733 38.7891 38.7891V31.7578C38.7891 30.7733 38.0157 30 37.0312 30Z"
      fill="#FFD400"
    />
    <path
      d="M38.7891 31.7578V38.7891C38.7891 39.7733 38.0157 40.5469 37.0312 40.5469H30V30H37.0312C38.0157 30 38.7891 30.7733 38.7891 31.7578Z"
      fill="#FDBF00"
    />
  </Icon>
)

export default Businesses
