// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const FoodNightlife = props => (
  <Icon viewBox="0 0 30 32" {...props}>
    <g>
      <title>food_nightlife</title>
      <path d="M4.5 10.065v0c0.136-0.107 0.489-0.161 0.761 0.161l4.321 4.656 1.141-0.963-3.94-4.977c-0.272-0.294-0.163-0.642-0.027-0.749v0c0.136-0.107 0.489-0.161 0.761 0.161l4.321 4.656 1.141-0.963-3.94-4.978c-0.272-0.294-0.163-0.642-0.027-0.749v0c0.136-0.107 0.489-0.161 0.761 0.161l4.321 4.656c1.005 1.151 1.141 2.756 0.489 4.041-0.217 0.428-0.027 0.723 0.027 0.776l9.892 10.918c0.435 0.482 0.381 1.258-0.109 1.659l-0.217 0.187c-0.516 0.428-1.277 0.348-1.685-0.161l-9.267-11.373c0 0-0.245-0.348-0.788-0.161v0c-0.027 0-0.054 0-0.082 0.027v0c-1.359 0.375-2.881-0.027-3.832-1.151 0 0 0 0-0.027-0.027l-3.94-4.977c-0.299-0.375-0.19-0.723-0.054-0.83v0zM7.598 26.791c-0.435 0.482-0.408 1.258 0.109 1.686l0.217 0.187c0.516 0.428 1.277 0.348 1.685-0.161l5.734-6.958-1.386-1.713-6.359 6.958zM22.735 15.765c2.636-3.051 3.832-6.289 2.69-7.279v0c-0.652-0.508-2.011 0.696-2.473 1.231l-4.511 5.192-0.679 0.803h-0.027l-0.87 0.937 2.011 2.221c1.305-0.749 2.772-1.873 3.859-3.104v0z" />
    </g>
  </Icon>
)

export default React.memo(FoodNightlife)
