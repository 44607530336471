import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import { getCDNURL } from '../utils/cdn'

const MetaTags = ({ title, description, imageUrl, twitterHandle, url, siteName }) => (
  <Head>
    <title>{title}</title>
    {description ? <meta key="description" name="description" content={description} /> : null}

    <meta key="name" itemProp="name" content={title} />
    {description ? (
      <meta key="itemprop-decription" itemProp="description" content={description} />
    ) : null}
    {imageUrl ? <meta key="itemprop-image" itemProp="image" content={getCDNURL(imageUrl)} /> : null}

    <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
    {twitterHandle ? <meta key="twitter:site" name="twitter:site" content={twitterHandle} /> : null}
    <meta key="twitter:title" name="twitter:title" content={title} />
    {description ? (
      <meta key="twitter:description" name="twitter:description" content={description} />
    ) : null}
    {twitterHandle ? (
      <meta key="twitter:creator" name="twitter:creator" content={twitterHandle} />
    ) : null}

    {imageUrl ? (
      <meta key="twitter:image:src" name="twitter:image:src" content={getCDNURL(imageUrl)} />
    ) : null}

    <meta key="og:title" property="og:title" content={title} />
    <meta key="og:type" property="og:type" content="website" />
    {url ? <meta key="og:url" property="og:url" content={url} /> : null}
    {imageUrl ? <meta key="og:image" property="og:image" content={getCDNURL(imageUrl)} /> : null}
    {description ? (
      <meta key="og:description" property="og:description" content={description} />
    ) : null}
    {siteName ? <meta key="og:site_name" property="og:site_name" content={siteName} /> : null}
  </Head>
)

MetaTags.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  twitterHandle: PropTypes.string,
  siteName: PropTypes.string,
  url: PropTypes.string,
}
MetaTags.defaultProps = {
  description: 'Relevant og nyttig informasjon om nærområdet for boliginteressenter',
  imageUrl: '/static/images/nabolagsprofil-image.jpg',
  twitterHandle: null,
  siteName: 'Digital Nabolagsprofil',
  url: '',
}

export default MetaTags
