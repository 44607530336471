export const PoiTypeNames = {
  ACTIVITIES: 'ACTIVITIES',
  ATTRACTIONS: 'ATTRACTIONS',
  AIRPORT: 'AIRPORT',
  AIRPORT_REGIONAL: 'AIRPORT_REGIONAL',
  AIRPORT_TRAIN: 'AIRPORT_TRAIN',
  AIRPORT_BUS: 'AIRPORT_BUS',
  BUS: 'BUS',
  CENTRAL_STATION: 'CENTRAL_STATION',
  TRAIN_STATION: 'TRAIN_STATION',
  ELEMENTARY_SCHOOL: 'ELEMENTARY_SCHOOL',
  EXPERIENCE: 'EXPERIENCE',
  FERRY: 'FERRY',
  FITNESS_CENTER: 'FITNESS_CENTER',
  FOOD_ENTERTAINMENT: 'FOOD_ENTERTAINMENT',
  GAS_STATION: 'GAS_STATION',
  GROCERIES: 'GROCERIES',
  KINDERGARTEN: 'KINDERGARTEN',
  KIOSK: 'KIOSK',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
  HOTEL: 'HOTEL',
  LIGHT_RAIL: 'LIGHT_RAIL',
  LIVING_ENVIRONMENT: 'LIVING_ENVIRONMENT',
  NATURE_AREA: 'NATURE_AREA',
  OTHER: 'OTHER',
  PLACES: 'PLACES',
  POSTEN: 'POSTEN',
  PHARMACY: 'PHARMACY',
  PRIMARY_SCHOOL: 'PRIMARY_SCHOOL',
  SCHOOL: 'SCHOOL',
  SKI_LIFT: 'SKI_LIFT',
  SPORT_FACILITY: 'SPORT_FACILITY',
  TAXI: 'TAXI',
  VINMONOPOL: 'VINMONOPOL',
  SUBWAY: 'SUBWAY',
  WINTER_SPORT: 'WINTER_SPORT',
  BICYCLE: 'BICYCLE',
  CHARGER: 'CHARGER',
  SHOPPING_MALL: 'SHOPPING_MALL',
  SKI_SLOPE: 'SKI_SLOPE',
  SKI_RESORT: 'SKI_RESORT',
  CROSS_COUNTRY: 'CROSS_COUNTRY',
  PARKING_GARAGE: 'PARKING_GARAGE',
  BUSINESS_LARGE: 'BUSINESS_LARGE',
  TRAFFIC_GATEWAY: 'TRAFFIC_GATEWAY',
  HARBOUR: 'HARBOUR',
}

export const PoiTypesNameMapping = {
  100: PoiTypeNames.PLACES,
  110: PoiTypeNames.LIVING_ENVIRONMENT,
  210: PoiTypeNames.AIRPORT,
  211: PoiTypeNames.AIRPORT_REGIONAL,
  220: PoiTypeNames.TRAIN_STATION,
  221: PoiTypeNames.CENTRAL_STATION,
  222: PoiTypeNames.SUBWAY,
  223: PoiTypeNames.AIRPORT_TRAIN,
  230: PoiTypeNames.LIGHT_RAIL,
  235: PoiTypeNames.TRAFFIC_GATEWAY,
  240: PoiTypeNames.BUS,
  241: PoiTypeNames.AIRPORT_BUS,
  250: PoiTypeNames.FERRY,
  260: PoiTypeNames.TAXI,
  270: PoiTypeNames.CHARGER,
  280: PoiTypeNames.BICYCLE,
  310: PoiTypeNames.SPORT_FACILITY,
  320: PoiTypeNames.SKI_LIFT,
  330: PoiTypeNames.FITNESS_CENTER,
  340: PoiTypeNames.NATURE_AREA,
  350: PoiTypeNames.ACTIVITIES,
  351: PoiTypeNames.FOOD_ENTERTAINMENT,
  352: PoiTypeNames.EXPERIENCE,
  353: PoiTypeNames.HOTEL,
  354: PoiTypeNames.ATTRACTIONS,
  355: PoiTypeNames.SKI_LIFT,
  356: PoiTypeNames.NATURE_AREA,
  357: PoiTypeNames.SKI_RESORT,
  358: PoiTypeNames.SKI_SLOPE,
  359: PoiTypeNames.CROSS_COUNTRY,
  360: PoiTypeNames.HARBOUR,
  400: PoiTypeNames.SCHOOL,
  401: PoiTypeNames.PRIMARY_SCHOOL,
  402: PoiTypeNames.ELEMENTARY_SCHOOL,
  500: PoiTypeNames.HIGH_SCHOOL,
  550: PoiTypeNames.HIGH_SCHOOL,
  551: PoiTypeNames.HIGH_SCHOOL,
  600: PoiTypeNames.KINDERGARTEN,
  700: PoiTypeNames.SHOPPING_MALL,
  800: PoiTypeNames.POSTEN,
  900: PoiTypeNames.PHARMACY,
  1000: PoiTypeNames.VINMONOPOL,
  1100: PoiTypeNames.GROCERIES,
  1200: PoiTypeNames.KIOSK,
  1300: PoiTypeNames.GAS_STATION,
  2500: PoiTypeNames.PARKING_GARAGE,
  3002: PoiTypeNames.BUSINESS_LARGE,
}

export default {
  PoiTypesNameMapping,
  PoiTypeNames,
}
