// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Hotel = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>hotel</title>
      <path d="M16.64 5.467h-1.2c-2.64 0-4.8 2.32-5.28 5.28h2.88c0.080-1.28 1.040-2.32 2.32-2.64 1.6-0.4 3.2 0.64 3.6 2.24s-0.64 3.2-2.24 3.6v0l-6.64 1.6v11.68c0 0.24 0.32 0.64 0.72 0.64h10.4c0.4 0 0.72-0.48 0.72-0.64v-15.2c0-3.6-2.32-6.56-5.28-6.56zM19.6 25.227h-1.84v-3.68h-3.44v3.68h-1.84v-8.48h1.84v3.28h3.44v-3.28h1.84v8.48z" />
    </g>
  </Icon>
)

export default React.memo(Hotel)
