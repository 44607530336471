// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Bike = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <path
        d="M271.55,243.388h-99.003l-33.312-71.773c-3.487-7.514-12.405-10.78-19.921-7.291
	c-7.514,3.488-10.779,12.407-7.291,19.921l34.27,73.836c2.026,4.365,5.886,7.289,10.238,8.296c2.657,4.208,7.336,7.011,12.68,7.011
	h102.34c8.284,0,15-6.716,15-15C286.55,250.104,279.834,243.388,271.55,243.388z"
        style={{
          fill: '#C3000A',
        }}
      />
      <path
        d="M235.817,243.388h-63.271l-33.312-71.773c-3.487-7.514-12.405-10.78-19.921-7.291
	c-7.514,3.488-10.779,12.407-7.291,19.921l34.27,73.836c2.026,4.365,5.886,7.289,10.238,8.296c2.657,4.208,7.336,7.011,12.68,7.011
	h66.608v-30H235.817z"
        style={{
          fill: '#FF3632',
        }}
      />
      <path
        d="M107.709,221.169C48.318,221.169,0,269.487,0,328.878s48.318,107.708,107.709,107.708
	s107.708-48.318,107.708-107.708C215.417,269.487,167.1,221.169,107.709,221.169z M107.709,406.587
	C64.86,406.587,30,371.727,30,328.878s34.86-77.709,77.709-77.709s77.708,34.86,77.708,77.709
	C185.417,371.727,150.558,406.587,107.709,406.587z"
        style={{
          fill: '#333940',
        }}
      />
      <path
        d="M107.709,406.587C64.86,406.587,30,371.727,30,328.878s34.86-77.709,77.709-77.709v-30
	C48.318,221.169,0,269.487,0,328.878s48.318,107.708,107.709,107.708V406.587z"
        style={{
          fill: '#636978',
        }}
      />
      <path
        d="M404.292,221.169c-59.391,0-107.709,48.318-107.709,107.709s48.318,107.708,107.709,107.708
	S512,388.268,512,328.878C512,269.487,463.682,221.169,404.292,221.169z M404.292,406.587c-42.849,0-77.709-34.86-77.709-77.708
	c0-42.849,34.86-77.709,77.709-77.709S482,286.03,482,328.879C482,371.727,447.14,406.587,404.292,406.587z"
        style={{
          fill: '#333940',
        }}
      />
      <path
        d="M404.292,406.587c-42.849,0-77.709-34.86-77.709-77.708c0-42.849,34.86-77.709,77.709-77.709v-30
	c-59.391,0-107.709,48.318-107.709,107.709s48.318,107.708,107.709,107.708V406.587z"
        style={{
          fill: '#636978',
        }}
      />
      <path
        d="M140.935,157.388H84.137c-8.284,0-15,6.716-15,15s6.716,15,15,15h56.798c8.284,0,15-6.716,15-15
	S149.219,157.388,140.935,157.388z"
        style={{
          fill: '#FFD15E',
        }}
      />
      <path
        d="M416.641,320.364c-0.795-1.155-75.978-111.718-71.573-218.849h-36.464l-70.577,211.873H107.709
	c-8.284,0-15,6.716-15,15s6.716,15,15,15h141.135c6.459,0,12.193-3.89,14.233-10.019l56.627-170.088
	c5.356,29.884,15.131,61.475,29.302,94.264c20.277,46.917,42.025,78.577,42.941,79.904c2.91,4.216,7.591,6.453,12.353,6.453
	c2.937,0,5.903-0.873,8.503-2.665C419.621,336.536,421.337,327.184,416.641,320.364z"
        style={{
          fill: '#C3000A',
        }}
      />
      <path
        d="M235.817,313.388H107.709c-8.284,0-15,6.716-15,15s6.716,15,15,15h128.108V313.388z"
        style={{
          fill: '#FF3632',
        }}
      />
      <path
        d="M342.016,75.413h-56.798c-8.284,0-15,6.716-15,15s6.716,15,15,15h56.798c8.284,0,15-6.716,15-15
	S350.3,75.413,342.016,75.413z"
        style={{
          fill: '#636978',
        }}
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(Bike)
