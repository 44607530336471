// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const MarkerFill = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>marker_fill</title>
      <path d="M15.964 5.467c-4.138 0-7.49 3.424-7.49 7.633 0 1.355 0.357 2.639 0.998 3.781l5.778 10.558c0.143 0.286 0.429 0.429 0.786 0.429s0.57-0.142 0.785-0.428l5.707-10.558c0.642-1.142 0.998-2.497 0.998-3.781 0-4.21-3.425-7.634-7.562-7.634zM15.964 15.383c-1.213 0-2.283-0.998-2.283-2.283s0.998-2.283 2.283-2.283c1.213 0 2.283 0.998 2.283 2.283s-0.998 2.283-2.283 2.283z" />
    </g>
  </Icon>
)

export default React.memo(MarkerFill)
