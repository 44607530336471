// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const ArrowForward = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </g>
  </Icon>
)

export default React.memo(ArrowForward)
