import { css } from 'styled-components'
import { em } from 'polished'

import base from '../../styles/base'

const { sizes } = base.media

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${em(sizes[label])}) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export default media
