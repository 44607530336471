import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const TrafficGateway = props => (
  <Icon viewBox="0 0 59 60" {...props}>
    <g>
      <title>traffic-gateway</title>
      <path d="M24.2105 33.6842H0L11.5789 41.0526V37.8947H23.1579C24.3205 37.8947 25.2632 38.8374 25.2632 40V60H29.4737V38.9474C29.4737 36.0408 27.1171 33.6842 24.2105 33.6842Z" />
      <path d="M25.2632 29.4737H11.5789V26.3158L0 33.6842H24.2105C27.1171 33.6842 29.4737 36.0408 29.4737 38.9474V60H33.6842V37.8947C33.6842 33.2437 29.9142 29.4737 25.2632 29.4737Z" />
      <path d="M33.6842 49.4736H47.3684V52.6315L58.9474 45.2631H33.6842V49.4736Z" />
      <path d="M47.3684 41.0526H33.6842V45.2632H58.9474L47.3684 37.8947V41.0526Z" />
      <path d="M29.4736 21.0526V30.6086C32.079 32.1099 33.6842 34.888 33.6842 37.8948V22.1053C33.6842 20.9427 34.6268 20 35.7894 20H47.3684V23.1579L58.9473 15.7895H34.7368C31.8302 15.7895 29.4736 18.1461 29.4736 21.0526Z" />
      <path d="M47.3684 11.5789H33.6842C29.0332 11.5789 25.2632 15.349 25.2632 20V29.4737C26.7424 29.4732 28.1949 29.8648 29.4737 30.6086V21.0526C29.4737 18.1461 31.8303 15.7895 34.7369 15.7895H58.9474L47.3684 8.42105V11.5789Z" />
    </g>
  </Icon>
)

export default React.memo(TrafficGateway)
