// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Sports = props => (
  <Icon viewBox="0 0 504.47 504.47" {...props}>
    <style>{'.football-st0{fill:#4FBA6F;}.football-st1{fill:#E6E7E8;}'}</style>
    <g>
      <path
        className="football-st0"
        d="M512,113.8v284.4c0,7.7-3.2,15.1-8.8,20.5c-5.2,5.1-12.3,8-19.6,8H28.4c-7.3,0-14.4-2.8-19.6-8
        C3.2,413.4,0,406,0,398.2V113.8c0-7.7,3.2-15.1,8.8-20.5c5.2-5.1,12.3-8,19.6-8h455.1c7.3,0,14.4,2.8,19.6,8
        C508.8,98.6,512,106,512,113.8L512,113.8z"
      />
      <g>
        <rect x="248.9" y="85.3" className="football-st1" width="14.2" height="341.3" />
        <path
          className="football-st1"
          d="M256,192c-35.3,0-64,28.7-64,64s28.7,64,64,64s64-28.7,64-64C319.9,220.7,291.3,192.1,256,192z M256,305.8 c-27.5,0-49.8-22.3-49.8-49.8s22.3-49.8,49.8-49.8s49.8,22.3,49.8,49.8C305.7,283.5,283.5,305.7,256,305.8z"
        />
        <path
          className="football-st1"
          d="M56.9,192.7v-7.8c0-1.9-0.7-3.7-2.1-5s-3.2-2.1-5-2.1H0V192h42.7v128H0v14.2h49.8c1.9,0,3.7-0.7,5-2.1
          s2.1-3.2,2.1-5v-7.8c32.4-3.5,56.9-30.8,56.9-63.3S89.2,196.2,56.9,192.7z M56.9,305.1v-98.1c24.5,3.4,42.7,24.4,42.7,49.1
          C99.6,280.7,81.4,301.6,56.9,305.1L56.9,305.1z M56.9,192.7v-7.8c0-1.9-0.7-3.7-2.1-5s-3.2-2.1-5-2.1H0V192h42.7v128H0v14.2h49.8
          c1.9,0,3.7-0.7,5-2.1s2.1-3.2,2.1-5v-7.8c32.4-3.5,56.9-30.8,56.9-63.3S89.2,196.2,56.9,192.7L56.9,192.7z M56.9,305.1v-98.1
          c24.5,3.4,42.7,24.4,42.7,49.1C99.6,280.7,81.4,301.6,56.9,305.1L56.9,305.1z"
        />
        <path
          className="football-st1"
          d="M512,192v-14.2h-49.8c-1.9,0-3.7,0.7-5,2.1c-1.3,1.3-2.1,3.2-2.1,5v7.8c-32.4,3.5-56.9,30.8-56.9,63.3
          s24.5,59.8,56.9,63.3v7.8c0,1.9,0.7,3.7,2.1,5c1.3,1.3,3.2,2.1,5,2.1H512V320h-42.7V192H512z M455.1,305.1
          c-24.5-3.4-42.7-24.4-42.7-49.1c0-24.7,18.2-45.6,42.7-49.1V305.1z"
        />
      </g>
    </g>
  </Icon>
)

export default React.memo(Sports)
