// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Train = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <path
        style={{ fill: '#5B5D6E' }}
        d="M161.278,404.211C146.405,431.699,103.291,512,103.291,512h26.947c3.088,0,6.097-1.597,7.772-4.456
        l60.277-103.333H161.278z"
      />
      <path
        style={{ fill: '#5B5D6E' }}
        d="M350.714,404.211C365.588,431.699,408.702,512,408.702,512h-26.947
        c-3.088,0-6.097-1.597-7.772-4.456l-60.278-103.333H350.714z"
      />
    </g>
    <g>
      <path
        style={{ fill: '#707487' }}
        d="M376.946,494.035H135.055c-2.141,0-3.482-2.314-2.417-4.172l5.953-10.387
        c1.207-2.106,3.448-3.405,5.876-3.405h223.069c2.427,0,4.669,1.299,5.876,3.405l5.953,10.387
        C380.428,491.72,379.087,494.035,376.946,494.035z"
      />
      <path
        style={{ fill: '#707487' }}
        d="M357.319,458.105H154.681c-2.131,0-3.474-2.295-2.429-4.153l5.83-10.36
        c1.199-2.132,3.456-3.452,5.903-3.452h184.033c2.447,0,4.702,1.319,5.903,3.452l5.83,10.36
        C360.793,455.809,359.45,458.105,357.319,458.105z"
      />
      <path
        style={{ fill: '#707487' }}
        d="M338.217,422.175H173.784c-2.139,0-3.481-2.311-2.42-4.169l7.881-13.796h153.509l7.881,13.796
        C341.698,419.864,340.356,422.175,338.217,422.175z"
      />
      <path
        style={{ fill: '#707487' }}
        d="M103.29,512c-1.535,0-3.097-0.395-4.517-1.224c-4.281-2.5-5.729-8-3.237-12.285l62.877-107.789
        c2.508-4.281,8.018-5.729,12.289-3.233c4.281,2.5,5.729,8,3.237,12.285l-62.877,107.789C109.387,510.403,106.378,512,103.29,512z"
      />
      <path
        style={{ fill: '#707487' }}
        d="M408.71,512c-3.088,0-6.097-1.597-7.772-4.456l-62.877-107.789
        c-2.492-4.285-1.044-9.785,3.237-12.285c4.289-2.496,9.781-1.049,12.289,3.233l62.877,107.789
        c2.491,4.285,1.044,9.785-3.237,12.285C411.807,511.605,410.246,512,408.71,512z"
      />
    </g>
    <path
      style={{ fill: '#00AAF0' }}
      d="M372.772,35.93l-10.517-21.034C357.691,5.767,348.359,0,338.153,0H173.848
      c-10.207,0-19.538,5.767-24.102,14.896L139.229,35.93v8.982h233.544V35.93z"
    />
    <path
      style={{ fill: '#00C3FF' }}
      d="M381.755,35.93H130.246c-14.883,0-26.947,12.065-26.947,26.947v323.368
      c0,14.883,12.065,26.947,26.947,26.947h251.509c14.883,0,26.947-12.065,26.947-26.947V62.877
      C408.702,47.994,396.638,35.93,381.755,35.93z"
    />
    <path
      style={{ fill: '#00AAF0' }}
      d="M175.158,350.316c-9.922,0-17.965-8.043-17.965-17.965V62.877c0-14.883,12.065-26.947,26.947-26.947
      h-53.895c-14.883,0-26.947,12.065-26.947,26.947v323.368c0,14.883,12.065,26.947,26.947,26.947h251.509
      c14.883,0,26.947-12.065,26.947-26.947v-35.93H175.158z"
    />
    <path
      style={{ fill: '#464655' }}
      d="M303.414,374.309c-1.444-8.662-8.938-15.012-17.72-15.012h-59.388
      c-8.783,0-16.277,6.348-17.72,15.012l-6.481,38.884h107.789L303.414,374.309z"
    />
    <path
      style={{ fill: '#707487' }}
      d="M363.79,215.579H148.211c-4.961,0-8.982-4.022-8.982-8.982v-89.825c0-4.961,4.022-8.982,8.982-8.982
      H363.79c4.961,0,8.982,4.022,8.982,8.982v89.825C372.772,211.557,368.751,215.579,363.79,215.579z"
    />
    <path
      style={{ fill: '#FFDC64' }}
      d="M282.948,85.333h-53.895c-7.439,0-13.474-6.031-13.474-13.474s6.035-13.474,13.474-13.474h53.895
      c7.439,0,13.474,6.031,13.474,13.474C296.422,79.303,290.387,85.333,282.948,85.333z"
    />
    <circle style={{ fill: '#FFFFFF' }} cx="157.194" cy="305.404" r="17.965" />
    <circle style={{ fill: '#5B5D6E' }} cx="157.194" cy="368.281" r="13.474" />
    <circle style={{ fill: '#FFFFFF' }} cx="354.808" cy="305.404" r="17.965" />
    <circle style={{ fill: '#5B5D6E' }} cx="354.808" cy="368.281" r="13.474" />
    <path
      style={{ fill: '#00AAF0' }}
      d="M300.913,332.351h-89.825c-4.965,0-8.982-4.022-8.982-8.982c0-4.961,4.017-8.982,8.982-8.982h89.825
      c4.965,0,8.982,4.022,8.982,8.982C309.895,328.329,305.878,332.351,300.913,332.351z"
    />
    <path
      style={{ fill: '#5B5D6E' }}
      d="M264.983,399.719h-17.965c-7.439,0-13.474-6.031-13.474-13.474s6.035-13.474,13.474-13.474h17.965
      c7.439,0,13.474,6.031,13.474,13.474S272.422,399.719,264.983,399.719z"
    />
    <path
      style={{ fill: '#00AAF0' }}
      d="M300.913,296.421h-89.825c-4.965,0-8.982-4.022-8.982-8.982s4.017-8.982,8.982-8.982h89.825
      c4.965,0,8.982,4.022,8.982,8.982S305.878,296.421,300.913,296.421z"
    />
    <path
      style={{ fill: '#5B5D6E' }}
      d="M309.895,107.789H148.211c-4.961,0-8.982,4.022-8.982,8.982v89.825c0,4.961,4.022,8.982,8.982,8.982
      h53.895l44.912-44.912v44.912h17.965v-62.877L309.895,107.789z"
    />
    <g>
      <circle style={{ fill: '#FFFFFF' }} cx="157.194" cy="71.86" r="13.474" />
      <circle style={{ fill: '#FFFFFF' }} cx="354.808" cy="71.86" r="13.474" />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </Icon>
)

export default React.memo(Train)
