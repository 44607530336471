import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import InternalLink from '../utils/internal-link'

const LinkElement = styled.a`
  border-radius: 4px;
  color: ${({ theme }) => theme.white};
  box-sizing: border-box;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;

  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.white};
      color: ${theme.brandColor};
      border: 2px solid ${theme.secondaryColor};
      padding: ${theme.spacingSmall} ${theme.spacing} ${theme.spacingSmall} ${theme.spacing};
    `};

  ${({ filledBg, theme }) =>
    filledBg &&
    css`
      background-color: ${theme.buttonBackground};
      color: ${theme.buttonColor};
      border-color: ${theme.buttonBackground};
      color: red;
    `} &:hover {
    cursor: pointer;
  }
`

const LinkButton = ({ children, route, params, filledBg, onClick }) => (
  <InternalLink route={route} params={params} passHref>
    <LinkElement filledBg={filledBg} onClick={onClick}>
      {children}
    </LinkElement>
  </InternalLink>
)

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string.isRequired,
  params: PropTypes.shape(),
  filledBg: PropTypes.bool,
  onClick: PropTypes.func,
}

LinkButton.defaultProps = {
  params: {},
  filledBg: false,
  onClick: null,
}

export default LinkButton
