// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Taxi = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>taxi</title>
      <path d="M13.47 11.623c-0.095 0.19 0.001 0.346 0.214 0.346h4.634c0.212 0 0.309-0.155 0.214-0.346l-0.427-0.854c-0.095-0.19-0.346-0.346-0.559-0.346h-3.090c-0.212 0-0.464 0.155-0.559 0.346l-0.426 0.854zM26.814 17.375c0 0-1.158 0-1.931 0-0.13 0-0.235 0.058-0.325 0.147l-1.102-2.642c-0.508-1.219-1.887-2.139-3.209-2.139h-8.457c-1.316 0-2.7 0.913-3.218 2.122l-1.137 2.653c-0.088-0.086-0.191-0.141-0.318-0.141-0.773 0-1.931 0-1.931 0s-0.386 0-0.386 0.386c0 0.386 0.386 1.158 0.386 1.158s0.157 0.386 0.542 0.386c0.097 0 0.281 0 0.502 0-0.334 0.41-0.516 0.947-0.467 1.537l0.388 4.65c0.043 0.524 0.263 1.006 0.593 1.395-0.009 0.046-0.014 0.094-0.014 0.142v1.931c0 0.425 0.348 0.773 0.773 0.773h1.931c0.425 0 0.773-0.347 0.773-0.773v-1.159h11.586v1.158c0 0.425 0.348 0.773 0.773 0.773h1.931c0.425 0 0.773-0.347 0.773-0.773v-1.931c0-0.049-0.006-0.096-0.014-0.142 0.33-0.389 0.55-0.871 0.593-1.395l0.388-4.65c0.049-0.59-0.133-1.128-0.467-1.537 0.23 0 0.422 0 0.522 0 0.386 0 0.523-0.386 0.523-0.386s0.386-0.771 0.386-1.158c0-0.386-0.386-0.386-0.386-0.386zM8.855 23.617c-0.781 0-1.414-0.634-1.414-1.415s0.634-1.415 1.414-1.415c0.781 0 1.414 0.634 1.414 1.415s-0.634 1.415-1.414 1.415zM19.792 22.302l-0.246 0.574c-0.251 0.586-0.978 1.065-1.615 1.065h-3.862c-0.638 0-1.364-0.479-1.615-1.065l-0.246-0.574c-0.251-0.586 0.065-1.065 0.702-1.065h6.179c0.638 0 0.953 0.479 0.702 1.065zM9.52 18.533l1.182-2.758c0.152-0.355 0.702-0.718 1.088-0.718h8.458c0.382 0 0.922 0.36 1.070 0.713l1.151 2.763h-12.949zM23.145 23.617c-0.781 0-1.414-0.634-1.414-1.415s0.634-1.415 1.414-1.415c0.781 0 1.414 0.634 1.414 1.415s-0.634 1.415-1.414 1.415z" />
    </g>
  </Icon>
)

export default React.memo(Taxi)
