// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Pharmacy = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>pharmacy</title>
      <path d="M24.26 10.213h-11.83c-1.61 0-2.94 1.33-2.94 2.94v0.84h-3.57v-4.62c0-0.28-0.28-0.56-0.56-0.56s-0.56 0.28-0.56 0.56v19.32c0 0.28 0.28 0.56 0.56 0.56s0.56-0.28 0.56-0.56v-4.62h3.57v0.84c0 1.61 1.33 2.94 2.94 2.94h11.83c1.61 0 2.94-1.33 2.94-2.94v-11.83c0-1.61-1.33-2.87-2.94-2.87v0zM5.92 15.113h3.57v7.91h-3.57c0-0.070 0-7.91 0-7.91zM23.56 20.783c0 0.28-0.28 0.56-0.56 0.56h-2.31v2.31c0 0.28-0.28 0.56-0.56 0.56h-3.57c-0.28 0-0.56-0.28-0.56-0.56v-2.31h-2.31c-0.28 0-0.56-0.28-0.56-0.56v-3.57c0-0.28 0.28-0.56 0.56-0.56h2.31v-2.31c0-0.28 0.28-0.56 0.56-0.56h3.57c0.28 0 0.56 0.28 0.56 0.56v2.31h2.31c0.28 0 0.56 0.28 0.56 0.56v3.57z" />
    </g>
  </Icon>
)

export default React.memo(Pharmacy)
