import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import styledNormalize from 'styled-normalize'

const generateFontFaces = (fontFaces) => {
  let fontFacesStr = ''
  const len = fontFaces.length
  for (let i = 0; i < len; i += 1) {
    fontFacesStr += `
      @font-face {
        font-family: ${fontFaces[i].fontFamily};
        font-display: ${fontFaces[i].fontDisplay};
        format(${fontFaces[i].fontDisplay});
        src: url(${fontFaces[i].fontSrc});
        font-weight: ${fontFaces[i].fontWeight};
        font-style: ${fontFaces[i].fontStyle};
      }
    `
  }
  return fontFacesStr
}

// we dropped injectGlobal in wait for createGlobalStyle which lets ut make a component.
// All of this is because the order of this css. (Global css is printed last)
// export default injectGlobal`
export default createGlobalStyle`
  ${styledNormalize}
  ${({ theme }) =>
    theme.fontFaces &&
    css`
      ${generateFontFaces(theme.fontFaces)};
    `};

  html {
    height: 100%;
  }
  body {
    height: 100%;

    &[data-lock-scroll="true"] {
      overflow-y: hidden;
    }
  }

  #simplified-login-widget {
    > div {
      z-index: 10;
    }
  }
`
