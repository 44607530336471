import React from 'react'
import PropTypes from 'prop-types'
import FocusTrap from 'react-focus-lock'
import { FocusOn } from 'react-focus-on'

import { useTranslation } from '../../../../../../i18n'
import { trackEvent } from '../../../../../utils/analytics'

import {
  FocusWrapper,
  MenuNavigationContent,
  MenuList,
  MenuItem,
  LinkWrapper,
  LinkText,
  LinkIcon,
  MenuHeader,
} from '../../style'
import { House, MarkerFill, Lifestyle } from '../../../../poi-icons'
import { Exchange } from '../../../../icons'
import InternalLink from '../../../../../utils/internal-link'
import { Button } from '../../../../../ui'
import { getIcon } from '../../util'

const PopOverNav = ({ wide, isOpen, handleExit, showTravelTime, showCompareBox, categories }) => {
  const { t } = useTranslation()

  return (
    <MenuNavigationContent wide={wide} in={isOpen} unmountOnExit timeout={400}>
      <FocusTrap>
        <FocusOn onClickOutside={handleExit} onEscapeKey={handleExit} scrollLock={false}>
          <FocusWrapper>
            <MenuList>
              <MenuItem role="presentation">
                <InternalLink route="oversikt" passHref>
                  <LinkWrapper
                    role="menuitem"
                    onClick={() => {
                      trackEvent(`Clicked menuitem`, 'menu', t('common:menu.overview'))
                    }}
                  >
                    <LinkIcon>
                      <House size="small" />
                    </LinkIcon>
                    <LinkText>{t('common:menu.overview')}</LinkText>
                  </LinkWrapper>
                </InternalLink>
              </MenuItem>
              {showTravelTime ? (
                <MenuItem role="presentation">
                  <InternalLink route="travel-time-redirect" passHref>
                    <LinkWrapper
                      role="menuitem"
                      onClick={() => {
                        trackEvent(`Clicked menuitem`, 'menu', t('common:menu.travel-time'))
                      }}
                    >
                      <LinkIcon>
                        <Lifestyle size="small" />
                      </LinkIcon>
                      <LinkText>{t('common:menu.travel-time')}</LinkText>
                    </LinkWrapper>
                  </InternalLink>
                </MenuItem>
              ) : null}
              {showCompareBox ? (
                <MenuItem role="presentation">
                  <InternalLink route="comparison-redirect" passHref>
                    <LinkWrapper
                      role="menuitem"
                      onClick={() => {
                        trackEvent(`Clicked menuitem`, 'menu', t('common:menu.comparison'))
                      }}
                    >
                      <LinkIcon>
                        <Exchange size="small" />
                      </LinkIcon>
                      <LinkText>{t('common:menu.comparison')}</LinkText>
                    </LinkWrapper>
                  </InternalLink>
                </MenuItem>
              ) : null}
              <MenuItem role="presentation">
                <InternalLink route="map" passHref>
                  <LinkWrapper
                    role="menuitem"
                    onClick={() => {
                      trackEvent(`Clicked menuitem`, 'menu', t('common:menu.map'))
                    }}
                  >
                    <LinkIcon>
                      <MarkerFill size="small" />
                    </LinkIcon>
                    <LinkText>{t('common:menu.map')}</LinkText>
                  </LinkWrapper>
                </InternalLink>
              </MenuItem>
            </MenuList>
            <MenuList role="menubar" ariaLabel="Category navigation">
              <MenuHeader>{t('common:menu.readMoreAbout')}</MenuHeader>
              {categories &&
                categories.length > 0 &&
                categories.map((item, index) => (
                  <MenuItem role="presentation" key={item.id}>
                    <InternalLink route={item.id} passHref>
                      <LinkWrapper
                        role="menuitem"
                        onClick={() => {
                          trackEvent(`Clicked menuitem`, 'menu', item.name)
                        }}
                      >
                        <LinkIcon>{getIcon(item.id)}</LinkIcon>
                        <LinkText isLast={index === categories.length - 1}>{item.name}</LinkText>
                      </LinkWrapper>
                    </InternalLink>
                  </MenuItem>
                ))}
            </MenuList>
          </FocusWrapper>
        </FocusOn>
      </FocusTrap>
    </MenuNavigationContent>
  )
}

PopOverNav.propTypes = {
  wide: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleExit: PropTypes.func.isRequired,
  showTravelTime: PropTypes.bool.isRequired,
  showCompareBox: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
}

export default PopOverNav
