import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Head from 'next/head'

import { Button } from '../ui'

import { trackEvent } from '../utils/analytics'
import { withTranslation } from '../../i18n'

const ErrorWrapper = styled.div`
  padding: ${props => props.theme.spacing};
`

const ErrorTitle = styled.h2``

const ErrorText = styled.p`
  margin-bottom: ${props => props.theme.spacing};
`

const ErrorMessage = styled.pre`
  background: ${({ theme }) => theme.boxBGColor};
  overflow: hidden;
  white-space: pre-wrap;
  padding: ${props => props.theme.spacing};
  margin-bottom: ${props => props.theme.spacing};
`

class ErrorComponent extends React.Component {
  componentDidMount() {
    const { type } = this.props
    trackEvent('Showed error', 'error', type)
  }

  render() {
    const { title, text, message, headText, type, link, t } = this.props
    return (
      <ErrorWrapper>
        <Head>
          <title>{headText || title}</title>
        </Head>
        <ErrorTitle>{title}</ErrorTitle>
        <ErrorText>{text}</ErrorText>
        {message ? <ErrorMessage>{message}</ErrorMessage> : null}
        {type === 'request-timeout' || type === 'invalid-json' || type === 'COULD_NOT_READ_DATA' ? (
          <Button
            filledBg
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.location.reload()
              }
              trackEvent('Clicked reload page from error', 'error', type)
            }}
          >
            {t('common:error.tryReload')}
          </Button>
        ) : null}
        {type === 'no-comparison-data' && link ? (
          <p>Gå tilbake og <a href={link}>endre adresse for sammenligning her</a>.</p>
        ) : null}

      </ErrorWrapper>
    )
  }
}

ErrorComponent.propTypes = {
  headText: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  t: PropTypes.func.isRequired,
}

ErrorComponent.defaultProps = {
  headText: '',
  message: '',
  type: '',
  link: '',
}

export default withTranslation('common')(ErrorComponent)
