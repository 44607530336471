import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Location = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g id="Bounding_Boxes">
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
    <g id="Outline">
      <g id="ui_x5F_spec_x5F_header" />
      <g>
        <path
          d="M12,2C8.13,2,5,5.13,5,9c0,5.25,7,13,7,13s7-7.75,7-13C19,5.13,15.87,2,12,2z M7,9c0-2.76,2.24-5,5-5s5,2.24,5,5
          c0,2.88-2.88,7.19-5,9.88C9.92,16.21,7,11.85,7,9z"
        />
        <circle cx="12" cy="9" r="2.5" />
      </g>
    </g>
  </Icon>
)

export default React.memo(Location)
