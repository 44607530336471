// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Bike = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <style>
      {`
      .bus-st0{fill:#DDB74B;}
      .bus-st1{fill:#76CDCE;}
      .bus-st2{fill:#FC2B2B;}
      .bus-st3{fill:#F7CF52;}
      .bus-st4{fill:#FFFFFF;}
      .bus-st5{fill:#4D4D4D;}
      .bus-st6{fill:#666666;}
      .bus-st7{fill:#CCCCCC;}
      .bus-st8{fill:#96E0DE;}`}
    </style>
    <g>
      <path
        className="bus-st0"
        d="M358.1,56.7v32H52.5v-32c0-11.2,9.1-20.4,20.4-20.4h264.9C349,36.3,358.1,45.4,358.1,56.7z"
      />
      <rect x="52.5" y="88.7" className="bus-st1" width="305.6" height="148.3" />
      <path
        className="bus-st2"
        d="M30.5,114.5V190H10.6C4.8,190,0,185.2,0,179.3v-54.2c0-5.9,4.8-10.6,10.6-10.6
        C10.6,114.5,30.5,114.5,30.5,114.5z"
      />
      <path
        className="bus-st2"
        d="M410.6,125.1v54.2c0,5.9-4.8,10.6-10.6,10.6h-19.8v-75.5H400C405.8,114.5,410.6,119.3,410.6,125.1z"
      />
      <rect x="329.1" y="261.2" className="bus-st3" width="29" height="28.5" />
      <rect x="52.5" y="261.2" className="bus-st3" width="29" height="28.5" />
      <path
        className="bus-st4"
        d="M329.1,261.2v28.5h-60.7c0-7.9,3.2-15,8.4-20.1c5.2-5.2,12.3-8.4,20.1-8.4H329.1z"
      />
      <path
        className="bus-st4"
        d="M142.2,289.7H81.5v-28.5h32.2C129.5,261.2,142.2,273.9,142.2,289.7z"
      />
      <path
        className="bus-st5"
        d="M264.5,350v4.5c0,11-8.9,19.9-19.9,19.9h-64.8c-11,0-19.9-8.9-19.9-19.9V350H264.5z"
      />
      <path
        className="bus-st6"
        d="M365.7,350v37.4c0,4.9-4,8.9-8.9,8.9h-34.1c-4.9,0-8.9-4-8.9-8.9V350H365.7z"
      />
      <path
        className="bus-st6"
        d="M96.8,350v37.4c0,4.9-4,8.9-8.9,8.9H53.8c-4.9,0-8.9-4-8.9-8.9V350H96.8z"
      />
      <path
        className="bus-st7"
        d="M396,334.1L396,334.1c0,8.8-7.1,15.9-15.9,15.9h-56.4h-53.8H155.7H87H30.6c-8.8,0-15.9-7.1-15.9-15.9l0,0
        c0-8.8,7.1-15.9,15.9-15.9H380C388.8,318.2,396,325.3,396,334.1z"
      />
      <path
        className="bus-st2"
        d="M380.1,190v128.2H30.5V190v-75.5V56.7c0-23.4,19-42.4,42.4-42.4h264.9c23.4,0,42.4,19,42.4,42.4v57.8V190z
         M358.1,289.7v-28.5h-29h-32.2c-7.9,0-15,3.2-20.1,8.4c-5.2,5.1-8.4,12.3-8.4,20.1h60.7H358.1z M358.1,237V88.7v-32
        c0-11.2-9.1-20.4-20.4-20.4H72.8c-11.2,0-20.4,9.1-20.4,20.4v32V237H358.1z M81.5,289.7h60.7c0-15.7-12.8-28.5-28.5-28.5H81.5h-29
        v28.5H81.5z"
      />
      <polygon className="bus-st8" points="358.1,88.7 358.1,237 280.7,237 132.4,88.7" />
      <path
        className="bus-st3"
        d="M358.1,56.7v32H132.4L80,36.3h257.8C349,36.3,358.1,45.4,358.1,56.7z"
      />
    </g>
  </Icon>
)

export default React.memo(Bike)
