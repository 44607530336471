// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Drive = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g transform="translate(0, -6)">
      <title>drive</title>
      <path d="M4.86 26c0.574-1.928 2.359-3.333 4.473-3.333s3.9 1.406 4.473 3.333h4.386c0.574-1.928 2.359-3.333 4.473-3.333s3.9 1.406 4.473 3.333h0.871c0.715 0 1.322-0.597 1.322-1.333v-4c0-0.741-0.592-1.333-1.322-1.333h-24.022c-0.715 0-1.322 0.597-1.322 1.333v4c0 0.741 0.592 1.333 1.322 1.333h0.871zM9.333 12.667c-4.315 0-4.533 6.667-4.533 6.667h21.867c0 0-2.995-6.667-6.667-6.667-4 0-6.352 0-10.667 0zM10.667 14c2.667 0 6.263 0 9.467 0 1.449 0 3.867 5.333 3.867 5.333h-16.4c0 0 0.4-5.333 3.067-5.333zM22.667 30c1.473 0 2.667-1.194 2.667-2.667s-1.194-2.667-2.667-2.667c-1.473 0-2.667 1.194-2.667 2.667s1.194 2.667 2.667 2.667zM9.333 30c1.473 0 2.667-1.194 2.667-2.667s-1.194-2.667-2.667-2.667c-1.473 0-2.667 1.194-2.667 2.667s1.194 2.667 2.667 2.667zM14.667 14h1.333v5.6h-1.333z" />
    </g>
  </Icon>
)

export default React.memo(Drive)
