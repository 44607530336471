// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Train = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>train</title>
      <path d="M20.631 30.728c-0.463 0-1.066-0.306-1.34-0.679l-0.548-0.747c-0.274-0.374-0.119-0.679 0.344-0.679h1.263c0.463 0 1.066 0.306 1.34 0.679l0.548 0.747c0.274 0.374 0.119 0.679-0.344 0.679h-1.263zM11.79 28.623c-0.463 0-1.066 0.306-1.34 0.679l-0.548 0.747c-0.274 0.374-0.119 0.679 0.344 0.679h1.263c0.463 0 1.066-0.306 1.34-0.679l0.548-0.747c0.274-0.374 0.119-0.679-0.344-0.679h-1.263zM21.932 8.194c-0.936-0.209-1.881-0.365-2.83-0.474-0.358-0.588-1.044-0.992-1.839-0.992h-2.526c-0.796 0-1.482 0.404-1.839 0.992-0.949 0.109-1.894 0.265-2.83 0.473-1.382 0.327-2.489 1.843-2.489 3.206 0 4.197 0 8.394 0 12.591 0 1.363 1.106 2.879 2.489 3.206 3.906 0.871 7.958 0.871 11.865 0 1.382-0.327 2.489-1.843 2.489-3.206 0-4.197 0-8.394 0-12.591-0.001-1.362-1.107-2.879-2.49-3.206zM14.914 7.991h2.21c0.5 0 0.643 0.283 0.643 0.631s-0.143 0.631-0.643 0.631h-2.21c-0.5 0-0.683-0.283-0.683-0.631s0.183-0.631 0.683-0.631zM11.579 24.060c-0.852 0-1.542-0.691-1.542-1.542s0.691-1.542 1.542-1.542c0.852 0 1.542 0.691 1.542 1.542s-0.691 1.542-1.542 1.542zM12.875 18.939c-0.23 0-0.541-0.138-0.685-0.362-1.327-2.075-2.174-4.391-1.958-6.286 0.022-0.205 0.278-0.411 0.547-0.471 3.832-0.819 6.609-0.814 10.441 0.005 0.269 0.060 0.526 0.278 0.547 0.483 0.215 1.895-0.63 4.192-1.958 6.267-0.144 0.222-0.455 0.362-0.685 0.362-2.082 0.001-4.166 0.001-6.249 0.001zM20.421 24.060c-0.852 0-1.542-0.691-1.542-1.542s0.691-1.542 1.542-1.542c0.852 0 1.542 0.691 1.542 1.542s-0.69 1.542-1.542 1.542z" />
    </g>
  </Icon>
)

export default React.memo(Train)
