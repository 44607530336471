// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const AirportTrain = props => (
  <Icon viewBox="0 0 33 32" {...props}>
    <g>
      <title>airport_train</title>
      <path d="M17.069 27.931c-0.334 0-0.769-0.221-0.967-0.491l-0.395-0.541c-0.198-0.271-0.086-0.491 0.248-0.491h0.911c0.334 0 0.769 0.221 0.967 0.491l0.395 0.541c0.198 0.271 0.086 0.491-0.248 0.491h-0.911zM10.693 26.407c-0.334 0-0.769 0.221-0.967 0.491l-0.395 0.541c-0.198 0.271-0.086 0.491 0.248 0.491h0.911c0.334 0 0.769-0.221 0.967-0.491l0.395-0.541c0.198-0.271 0.086-0.491-0.248-0.491h-0.911zM17.956 11.641c-0.675-0.151-1.357-0.264-2.041-0.343-0.258-0.426-0.753-0.718-1.326-0.718h-1.822c-0.574 0-1.069 0.292-1.326 0.718-0.684 0.079-1.366 0.192-2.041 0.342-0.997 0.237-1.795 1.334-1.795 2.32v9.111c0 0.986 0.798 2.083 1.795 2.32 2.817 0.63 5.74 0.63 8.558 0 0.997-0.237 1.795-1.334 1.795-2.32v-9.111c-0.001-0.986-0.799-2.083-1.796-2.32v0.001zM12.955 11.495h1.594c0.361 0 0.464 0.205 0.464 0.457s-0.103 0.457-0.464 0.457h-1.594c-0.361 0-0.493-0.205-0.493-0.457s0.132-0.457 0.493-0.457v0zM10.549 23.122c-0.615 0-1.112-0.5-1.112-1.116s0.498-1.116 1.112-1.116c0.615 0 1.112 0.5 1.112 1.116s-0.498 1.116-1.112 1.116v0zM11.484 19.416c-0.166 0-0.39-0.1-0.494-0.262-0.957-1.501-1.568-3.177-1.412-4.549 0.016-0.148 0.2-0.297 0.395-0.341 2.764-0.593 4.767-0.589 7.531 0.004 0.194 0.043 0.379 0.201 0.395 0.349 0.155 1.371-0.454 3.033-1.412 4.535-0.104 0.161-0.328 0.262-0.494 0.262-1.502 0.001-3.005 0.001-4.507 0.001v0.001zM16.927 23.122c-0.615 0-1.112-0.5-1.112-1.116s0.498-1.116 1.112-1.116c0.615 0 1.112 0.5 1.112 1.116s-0.498 1.116-1.112 1.116v0zM20.464 13.995l3.501 1.631c0.582 0.272 1.241 0.176 1.719-0.187 0 0 1.1-0.846 3.848-2.941 0.583-0.443 0.19-0.993-0.434-0.876-1.146 0.217-2.697 0.513-4.141 0.79l-3.469-1.616c1.689-1.284 3.998-3.041 5.607-4.268l-1.496-0.697c-3.116 0.943-6.512 1.964-8.969 2.701l-5.267-2.454c-2.909-1.355-4.764-0.896-5.154-0.062-0.353 0.755 0.022 1.347 0.999 1.802l4.821 2.247c0.258-0.127 0.513-0.216 0.719-0.216h1.822c0.812 0 1.689 0.759 1.689 0.759 0.72 0.055 1.147 0.165 1.848 0.321 1.311 0.311 2.358 1.689 2.358 3.031v0.035z" />
    </g>
  </Icon>
)

export default React.memo(AirportTrain)
