// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Bicycle = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <rect
        x="167.724"
        y="52.966"
        width="176.552"
        height="17.655"
        style={{
          fill: '#D24146',
        }}
      />
      <path
        d="M317.793,282.483c4.875,0,8.828-3.953,8.828-8.828v-8.828c0-9.751-7.904-17.655-17.655-17.655
	H203.034c-9.751,0-17.655,7.904-17.655,17.655v8.828c0,4.875,3.953,8.828,8.828,8.828H317.793z"
        style={{
          fill: '#8C5F56',
        }}
      />
      <g>
        <path
          d="M97.103,26.483h40.384c8.271,0,15.53,5.87,17.263,13.956l17.508,81.725
		c3.469,16.163,17.991,27.905,34.529,27.905h31.556v132.414h35.31V150.069h31.556c16.538,0,31.06-11.742,34.53-27.905l17.508-81.725
		c1.732-8.086,8.991-13.956,17.262-13.956h40.384V8.828h-40.384c-16.539,0-31.06,11.742-34.53,27.905l-17.508,81.725
		c-1.732,8.086-8.991,13.956-17.263,13.956h-98.422c-8.271,0-15.53-5.87-17.263-13.956l-17.508-81.725
		c-3.469-16.163-17.991-27.905-34.529-27.905H97.103V26.483z"
          style={{
            fill: '#E15050',
          }}
        />
        <path
          d="M335.448,300.138h26.483c14.626,0,26.483,11.857,26.483,26.483l0,0
		c0,14.626-11.857,26.483-26.483,26.483h-26.483c-4.875,0-8.828-3.953-8.828-8.828v-35.31
		C326.621,304.09,330.573,300.138,335.448,300.138z"
          style={{
            fill: '#E15050',
          }}
        />
      </g>
      <path
        d="M361.931,317.793h-44.138c-9.737,0-17.655,7.923-17.655,17.655v35.31h-26.483v17.655h26.483
	c9.737,0,17.655-7.923,17.655-17.655v-35.31h44.138c4.875,0,8.828-3.948,8.828-8.828
	C370.759,321.741,366.806,317.793,361.931,317.793z"
        style={{
          fill: '#5B5D6E',
        }}
      />
      <rect
        x="88.276"
        y="406.069"
        width="326.621"
        height="17.655"
        style={{
          fill: '#707487',
        }}
      />
      <circle
        cx="256"
        cy="158.897"
        r="35.31"
        style={{
          fill: '#FFE182',
        }}
      />
      <path
        d="M88.276,512L88.276,512c-9.751,0-17.655-7.904-17.655-17.655V335.448
	c0-9.751,7.904-17.655,17.655-17.655l0,0c9.751,0,17.655,7.904,17.655,17.655v158.897C105.931,504.096,98.027,512,88.276,512z"
        style={{
          fill: '#FFC855',
        }}
      />
      <path
        d="M88.276,450.207c-9.75,0-17.655-7.905-17.655-17.655v61.793c0,9.75,7.905,17.655,17.655,17.655
	s17.655-7.905,17.655-17.655v-61.793C105.931,442.302,98.026,450.207,88.276,450.207z"
        style={{
          fill: '#FFB44B',
        }}
      />
      <path
        d="M423.724,512L423.724,512c-9.751,0-17.655-7.904-17.655-17.655V335.448
	c0-9.751,7.904-17.655,17.655-17.655l0,0c9.751,0,17.655,7.904,17.655,17.655v158.897C441.379,504.096,433.475,512,423.724,512z"
        style={{
          fill: '#FFC855',
        }}
      />
      <path
        d="M423.724,450.207c-9.75,0-17.655-7.905-17.655-17.655v61.793c0,9.75,7.905,17.655,17.655,17.655
	c9.75,0,17.655-7.905,17.655-17.655v-61.793C441.379,442.302,433.474,450.207,423.724,450.207z"
        style={{
          fill: '#FFB44B',
        }}
      />
      <path
        d="M176.552,459.034h-26.483c-14.626,0-26.483-11.857-26.483-26.483l0,0
	c0-14.626,11.857-26.483,26.483-26.483h26.483c4.875,0,8.828,3.953,8.828,8.828v35.31
	C185.379,455.082,181.427,459.034,176.552,459.034z"
        style={{
          fill: '#E15050',
        }}
      />
      <path
        d="M211.862,370.759c-9.737,0-17.655,7.923-17.655,17.655v35.31h-44.138
	c-4.875,0-8.828,3.948-8.828,8.828c0,4.879,3.953,8.828,8.828,8.828h44.138c9.737,0,17.655-7.923,17.655-17.655v-35.31h26.483
	v-17.655H211.862z"
        style={{
          fill: '#5B5D6E',
        }}
      />
      <path
        d="M256,512L256,512c-14.626,0-26.483-11.857-26.483-26.483V291.31
	c0-14.626,11.857-26.483,26.483-26.483l0,0c14.626,0,26.483,11.857,26.483,26.483v194.207C282.483,500.143,270.626,512,256,512z"
        style={{
          fill: '#FFC855',
        }}
      />
      <path
        d="M256,459.034c-14.626,0-26.483-11.857-26.483-26.483v52.966C229.517,500.143,241.374,512,256,512
	s26.483-11.857,26.483-26.483v-52.966C282.483,447.178,270.626,459.034,256,459.034z"
        style={{
          fill: '#FFB44B',
        }}
      />
      <g>
        <path
          d="M61.793,35.31h52.966c4.875,0,8.828-3.953,8.828-8.828V8.828c0-4.875-3.953-8.828-8.828-8.828
		H61.793c-4.875,0-8.828,3.953-8.828,8.828v17.655C52.966,31.358,56.918,35.31,61.793,35.31z"
          style={{
            fill: '#FFC855',
          }}
        />
        <path
          d="M397.241,35.31h52.966c4.875,0,8.828-3.953,8.828-8.828V8.828c0-4.875-3.953-8.828-8.828-8.828
		h-52.966c-4.875,0-8.828,3.953-8.828,8.828v17.655C388.414,31.358,392.366,35.31,397.241,35.31z"
          style={{
            fill: '#FFC855',
          }}
        />
      </g>
      <path
        d="M247.172,158.897c0-14.812,9.139-27.448,22.069-32.692c-4.095-1.661-8.552-2.618-13.241-2.618
	c-19.501,0-35.31,15.809-35.31,35.31s15.809,35.31,35.31,35.31c4.69,0,9.146-0.958,13.241-2.618
	C256.311,186.345,247.172,173.707,247.172,158.897z"
        style={{
          fill: '#FFD269',
        }}
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(Bicycle)
