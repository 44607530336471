// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Home = props => (
  <Icon viewBox="0 0 495.2 495.2" {...props}>
    <g>
      <rect
        x="360"
        y="47.6"
        width="80"
        height="152"
        style={{
          fill: '#680B09',
        }}
      />
      <rect
        x="352"
        y="31.6"
        width="96"
        height="16"
        style={{
          fill: '#C43D27',
        }}
      />
      <g>
        <rect
          y="431.6"
          width="488"
          height="32"
          style={{
            fill: '#207700',
          }}
        />
        <polygon
          points="56,362 72,354 88,362 88,448.4 72,456.4 56,448.4 	"
          style={{
            fill: '#207700',
          }}
        />
        <polygon
          points="0,362 16,354 32,362 32,448.4 16,456.4 0,448.4 	"
          style={{
            fill: '#207700',
          }}
        />
      </g>
      <rect
        x="16"
        y="391.6"
        width="56"
        height="16"
        style={{
          fill: '#239B02',
        }}
      />
      <rect
        x="112"
        y="223.6"
        width="328"
        height="224"
        style={{
          fill: '#FFFFFF',
        }}
      />
      <polyline
        points="112,223.6 440,223.6 440,447.6 "
        style={{
          fill: '#E8F9FC',
        }}
      />
      <path
        d="M256,431.6V282c0-7.2-2.4-10.4-9.6-10.4H172c-7.2,0-12,4-12,10.4v149.6H256z"
        style={{
          fill: '#FCA12A',
        }}
      />
      <path
        d="M256,359.6V282c0-7.2-2.4-10.4-9.6-10.4H172c-7.2,0-12,4-12,10.4"
        style={{
          fill: '#E58613',
        }}
      />
      <path
        d="M246.4,352.4c0,7.2-5.6,13.6-13.6,13.6c-7.2,0-13.6-5.6-13.6-13.6c0-7.2,5.6-13.6,13.6-13.6
	C240,338.8,246.4,344.4,246.4,352.4z"
        style={{
          fill: '#DB5435',
        }}
      />
      <path
        d="M242.4,342.8c5.6,4.8,4.8,13.6,0,19.2s-13.6,4.8-19.2,0"
        style={{
          fill: '#C43D27',
        }}
      />
      <path
        d="M344,311.6h48V282c0-7.2-8-10.4-15.2-10.4H344V311.6z"
        style={{
          fill: '#0E75AF',
        }}
      />
      <path
        d="M336,311.6v-40h-36c-7.2,0-12,4-12,10.4v29.6H336z"
        style={{
          fill: '#1EA4C4',
        }}
      />
      <path
        d="M336,319.6h-48v31.2c0,7.2,4.8,8.8,12,8.8h36V319.6z"
        style={{
          fill: '#0E75AF',
        }}
      />
      <path
        d="M344,319.6v40h32.8c7.2,0,15.2-1.6,15.2-8.8v-31.2H344z"
        style={{
          fill: '#1EA4C4',
        }}
      />
      <polygon
        points="495.2,223.6 276,52.4 57.6,223.6 "
        style={{
          fill: '#C43D27',
        }}
      />
      <polygon
        points="495.2,223.6 276,52.4 164.8,223.6 "
        style={{
          fill: '#AA1E0F',
        }}
      />
      <g>
        <polygon
          points="119.2,175.6 433.6,175.6 413.6,159.6 139.2,159.6 	"
          style={{
            fill: '#C43D27',
          }}
        />
        <polygon
          points="190.4,119.6 362.4,119.6 352,111.6 200.8,111.6 	"
          style={{
            fill: '#C43D27',
          }}
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(Home)
