// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Padlock = props => (
  <Icon viewBox="0 0 482.4 482.4" {...props}>
    <g>
      <path
        d="M398.4,162.4h-47.2C351.2,98.4,308,48,244.8,48S129.6,98.4,129.6,162.4H82.4
	c0-88,72.8-161.6,161.6-161.6S398.4,74.4,398.4,162.4z"
        style={{
          fill: '#D6D6D6',
        }}
      />
      <path
        d="M244.8,0c88.8,0,153.6,74.4,153.6,162.4h-47.2c0-64-43.2-115.2-106.4-115.2"
        style={{
          fill: '#A8A8A8',
        }}
      />
      <path
        d="M436.8,419.2c0,36.8-25.6,63.2-62.4,63.2H115.2c-36.8,0-70.4-27.2-70.4-63.2V212
	c0-36.8,33.6-65.6,70.4-65.6h260c36.8,0,62.4,28.8,62.4,65.6v207.2H436.8z"
        style={{
          fill: '#FCA12A',
        }}
      />
      <path
        d="M372.8,145.6c36.8,0,64,29.6,64,66.4v207.2c0,36.8-25.6,63.2-62.4,63.2H115.2
	c-36.8,0-67.2-28-67.2-64.8"
        style={{
          fill: '#E0861B',
        }}
      />
      <path
        d="M436.8,419.2v-32l-152-153.6l-64,170.4l81.6,78.4h72.8C411.2,482.4,436.8,455.2,436.8,419.2z"
        style={{
          fill: '#CE6D0D',
        }}
      />
      <path
        d="M305.6,275.2c0-32.8-27.2-59.2-60-59.2s-60.8,26.4-60.8,59.2c0,22.4,12,41.6,28,51.2v60
	c0,16,16,28.8,32,28.8c16,0,32-12.8,32-28.8v-60C292.8,316,305.6,296.8,305.6,275.2z"
        style={{
          fill: '#4C5254',
        }}
      />
      <path
        d="M246.4,415.2c16,0,30.4-12.8,30.4-28.8v-60c16-10.4,28.8-29.6,28.8-51.2c0-32.8-26.4-59.2-59.2-59.2"
        style={{
          fill: '#2F423F',
        }}
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </Icon>
)

export default React.memo(Padlock)
