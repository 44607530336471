import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'next/router'
import base from '../styles/base'

import { getNabolagTheme } from '../store/nabolag/selectors'

const defaultTheme = base

const mapStateToProps = state => {
  const apiTheme = getNabolagTheme(state)
  const usingCustomTheme = Object.keys(apiTheme).length > 0

  return {
    theme: {
      ...defaultTheme,
      ...apiTheme,
    },
    usingCustomTheme,
  }
}

export const withTheme = Component => {
  const ExtendedTheme = props => <Component {...props} />
  ExtendedTheme.getInitialProps = async ctx => ({
    ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
  })

  return compose(
    withRouter,
    connect(mapStateToProps),
  )(ExtendedTheme)
}
