import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from '../components/layout/media'

const Button = styled.button`
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonColor};
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  user-select: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ${({ stripped }) =>
    stripped &&
    `
    background: transparent;
  `}

  ${({ stretch }) =>
    stretch &&
    `
    width: 100%;
    display: block;
    border-radius: 0;
    color: inherit;
  `}

  ${({ nopaddingSides }) =>
    nopaddingSides &&
    `
    padding-left: 0;
    padding-right: 0;
  `}

  ${({ alignLeft }) =>
    alignLeft &&
    `
    text-align: left;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.54;
    pointer-events: none;
    cursor: not-allowed;
  `}

  ${({ noPaddingRight }) =>
    noPaddingRight &&
    `
    padding-right: 0;
  `}

  ${({ noPadding }) =>
    noPadding &&
    `
    padding: 0;
  `}
`

const ButtonClass = ({ children, stripped, isLink, ...rest }) => (
  <Button {...rest}>
    {children} {isLink} {stripped}
  </Button>
)

ButtonClass.propTypes = {
  children: PropTypes.node.isRequired,
  stripped: PropTypes.bool,
  isLink: PropTypes.bool,
}

ButtonClass.defaultProps = {
  stripped: false,
  isLink: false,
}

export default Button
