import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PoiTypeNames, PoiTypesNameMapping } from '../models/PoiTypes'
import {
  Activities,
  Airplane,
  AirportBus,
  AirportTrain,
  Anchor,
  Attraction,
  Bike,
  Buss,
  LivingEnvironment,
  CrossCountry,
  ElectricCar,
  FerryWharf,
  FoodNightlife,
  GasStation,
  GroceryStore,
  Gym,
  KioskVideo,
  Hotel,
  LightRail,
  Kindergarden,
  MarkerFill as Marker,
  Parkering,
  Places,
  Pharmacy,
  Posten,
  PrimarySchool,
  School,
  ShoppingMall,
  SkiLift,
  SkiResort,
  SkiSlope,
  SportsFacilities,
  Subway,
  TrafficGateway,
  Train,
  Taxi,
  Vinmonopol,
} from '../components/poi-icons'

const IconWrapper = styled.div`
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `};
`

function getPoiIconByTypeId(typeId) {
  switch (PoiTypesNameMapping[typeId]) {
    case PoiTypeNames.BUS:
      return <Buss size="small" />
    case PoiTypeNames.KINDERGARTEN:
      return <Kindergarden size="small" />
    case PoiTypeNames.FITNESS_CENTER:
      return <Gym size="small" />
    case PoiTypeNames.FOOD_ENTERTAINMENT:
      return <FoodNightlife size="small" />
    case PoiTypeNames.PRIMARY_SCHOOL:
    case PoiTypeNames.ELEMENTARY_SCHOOL:
      return <PrimarySchool size="small" />
    case PoiTypeNames.HIGH_SCHOOL:
      return <School size="small" />
    case PoiTypeNames.SPORT_FACILITY:
      return <SportsFacilities size="small" />
    case PoiTypeNames.ACTIVITIES:
      return <Activities size="small" />
    case PoiTypeNames.ATTRACTIONS:
      return <Attraction size="small" />
    case PoiTypeNames.HOTEL:
      return <Hotel size="small" />
    case PoiTypeNames.GROCERIES:
      return <GroceryStore size="small" />
    case PoiTypeNames.KIOSK:
      return <KioskVideo size="small" />
    case PoiTypeNames.PHARMACY:
      return <Pharmacy size="small" />
    case PoiTypeNames.LIGHT_RAIL:
      return <LightRail size="small" />
    case PoiTypeNames.SHOPPING_MALL:
      return <ShoppingMall size="small" />
    case PoiTypeNames.TAXI:
      return <Taxi size="small" />
    case PoiTypeNames.SUBWAY:
      return <Subway size="small" />
    case PoiTypeNames.VINMONOPOL:
      return <Vinmonopol size="small" />
    case PoiTypeNames.POSTEN:
      return <Posten size="small" />
    case PoiTypeNames.AIRPORT:
    case PoiTypeNames.AIRPORT_REGIONAL:
      return <Airplane size="small" />
    case PoiTypeNames.AIRPORT_BUS:
      return <AirportBus size="small" />
    case PoiTypeNames.AIRPORT_TRAIN:
      return <AirportTrain size="small" />
    case PoiTypeNames.PLACES:
      return <Places size="small" />
    case PoiTypeNames.CHARGER:
      return <ElectricCar size="small" />
    case PoiTypeNames.BICYCLE:
      return <Bike size="small" />
    case PoiTypeNames.GAS_STATION:
      return <GasStation size="small" />
    case PoiTypeNames.FERRY:
      return <FerryWharf size="small" />
    case PoiTypeNames.TRAIN_STATION:
    case PoiTypeNames.CENTRAL_STATION:
      return <Train size="small" />
    case PoiTypeNames.SKI_LIFT:
      return <SkiLift size="small" />
    case PoiTypeNames.SKI_SLOPE:
      return <SkiSlope size="small" />
    case PoiTypeNames.SKI_RESORT:
      return <SkiResort size="small" />
    case PoiTypeNames.CROSS_COUNTRY:
      return <CrossCountry size="small" />
    case PoiTypeNames.PARKING_GARAGE:
      return <Parkering size="small" />
    case PoiTypeNames.BUSINESS_LARGE:
      return <LivingEnvironment size="small" />
    case PoiTypeNames.TRAFFIC_GATEWAY:
      return <TrafficGateway size="small" />
    case PoiTypeNames.HARBOUR:
      return <Anchor size="small" />
    default:
      return <Marker size="small" />
  }
}

const PoiIcon = ({ typeId, color }) => (
  <IconWrapper color={color}>{getPoiIconByTypeId(typeId)}</IconWrapper>
)

PoiIcon.propTypes = {
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
}

PoiIcon.defaultProps = {
  color: '#477474',
}
export default PoiIcon
