// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Bowling = props => (
  <Icon width="48" height="54" viewBox="0 0 48 54" {...props}>
    <path
      d="M18.801 33.4992C18.801 38.9815 16.7333 45.5914 13.0056 49.695C12.7235 50.0085 12.3163 50.1964 11.8462 50.1964C11.8462 50.1964 10.6245 50.1964 9.40269 50.1964H6.95913C6.52048 50.1964 6.08224 50.0085 5.80014 49.695C2.04104 45.5914 0.00474737 38.9815 0.00474737 33.4992C-0.0580333 29.3638 0.474787 25.1975 3.23143 22.0022C3.41936 21.7829 6.70883 21.5635 6.92815 21.3442C7.67989 20.5925 5.11077 19.7466 5.51844 18.8693C5.98807 17.8668 6.23879 16.8016 6.27018 15.7367C6.27018 15.2039 9.3717 14.7029 9.27753 14.1705C9.18336 13.6377 5.9257 13.1362 5.73776 12.6038C5.42427 11.7265 4.98603 10.8496 4.35944 10.0351C3.26241 8.56263 2.88654 6.57852 3.32519 4.69917C3.92039 2.22423 6.176 0 9.40269 0C12.6294 0 14.885 2.22423 15.4802 4.69917C15.9188 6.57852 15.543 8.56263 14.4463 10.0351C13.8198 10.8496 13.3811 11.7265 13.068 12.6038C12.8801 13.1362 9.62201 13.6377 9.52784 14.1701C9.43408 14.7025 12.5356 15.2039 12.5356 15.7367C12.5666 16.8016 12.8177 17.8668 13.2873 18.8693C13.6946 19.7461 11.1259 20.5925 11.8776 21.3442C12.097 21.5631 15.3864 21.7824 15.5744 22.0022C18.331 25.1975 18.8634 29.3638 18.801 33.4992Z"
      fill="#DBDBDB"
    />
    <path
      d="M13.0646 12.6039C12.6888 13.6377 12.5008 14.703 12.5322 15.7368H6.26679C6.26679 14.703 6.10984 13.6377 5.73438 12.6039H13.0646Z"
      fill="#F3654D"
    />
    <path
      d="M15.573 22.0022H3.23047C3.4184 21.7829 3.57495 21.5635 3.79427 21.3442C4.54601 20.5925 5.10981 19.7466 5.51748 18.8693H13.2864C13.6936 19.7466 14.2574 20.5925 15.0092 21.3442C15.2285 21.5635 15.385 21.7829 15.573 22.0022Z"
      fill="#F3654D"
    />
    <path
      d="M46.9963 33.4992C46.9963 38.9815 44.9291 45.5914 41.201 49.695C40.9188 50.0085 40.5116 50.1964 40.042 50.1964C40.042 50.1964 38.8202 50.1964 37.5984 50.1964H35.1549C34.7162 50.1964 34.2776 50.0085 33.9954 49.695C30.2364 45.5914 28.2001 38.9815 28.2001 33.4992C28.1373 29.3638 28.6701 25.1975 31.4267 22.0022C31.6151 21.7829 34.9041 21.5635 35.1235 21.3442C35.8752 20.5925 33.3065 19.7466 33.7138 18.8693C34.1838 17.8668 34.4341 16.8016 34.4655 15.7367C34.4655 15.2039 37.567 14.7029 37.4733 14.1705C37.3791 13.6377 34.121 13.1362 33.9331 12.6038C33.62 11.7265 33.1813 10.8496 32.5548 10.0351C31.4581 8.56263 31.0823 6.57852 31.5209 4.69917C32.1161 2.22423 34.3717 0 37.5984 0C40.8251 0 43.0807 2.22423 43.6759 4.69917C44.1145 6.57852 43.7387 8.56263 42.6421 10.0351C42.0155 10.8496 41.5768 11.7265 41.2637 12.6038C41.0754 13.1362 37.8177 13.6377 37.7236 14.1705C37.6298 14.7029 40.7309 15.2039 40.7309 15.7367C40.7623 16.8016 41.013 17.8668 41.4831 18.8693C41.8903 19.7466 39.3212 20.5925 40.0733 21.3442C40.2927 21.5635 43.5817 21.7829 43.7697 22.0022C46.5263 25.1975 47.0591 29.3638 46.9963 33.4992Z"
      fill="#DBDBDB"
    />
    <path
      d="M41.2638 12.6039C40.888 13.6377 40.7 14.703 40.7314 15.7368H34.466C34.466 14.703 34.3095 13.6377 33.9336 12.6039H41.2638Z"
      fill="#F3654D"
    />
    <path
      d="M43.7687 22.0022H31.4258C31.6141 21.7829 31.7707 21.5635 31.99 21.3442C32.7417 20.5925 33.3055 19.7466 33.7128 18.8693H41.4817C41.889 19.7466 42.4532 20.5925 43.2049 21.3442C43.4242 21.5635 43.5808 21.7829 43.7687 22.0022Z"
      fill="#F3654D"
    />
    <path
      d="M23.4996 28.2676C16.5762 28.2676 10.9688 33.875 10.9688 40.7984C10.9688 47.7214 16.5762 53.4337 23.4996 53.4337C30.423 53.4337 36.0305 47.7214 36.0305 40.7984C36.0305 33.875 30.423 28.2676 23.4996 28.2676Z"
      fill="#526675"
    />
    <path
      d="M28.1954 36.0993C28.1954 36.9644 27.4938 37.6656 26.6288 37.6656C25.7637 37.6656 25.0625 36.9644 25.0625 36.0993C25.0625 35.2343 25.7637 34.5331 26.6288 34.5331C27.4938 34.5331 28.1954 35.2343 28.1954 36.0993Z"
      fill="#292929"
    />
    <path
      d="M25.0665 42.3647C25.0665 43.2416 24.3771 43.931 23.4998 43.931C22.6226 43.931 21.9336 43.2416 21.9336 42.3647C21.9336 41.4874 22.6226 40.7985 23.4998 40.7985C24.3771 40.7985 25.0665 41.4874 25.0665 42.3647Z"
      fill="#292929"
    />
    <path
      d="M31.3278 42.3647C31.3278 43.2298 30.6266 43.931 29.7616 43.931C28.8965 43.931 28.1953 43.2298 28.1953 42.3647C28.1953 41.4996 28.8965 40.7985 29.7616 40.7985C30.6266 40.7985 31.3278 41.4996 31.3278 42.3647Z"
      fill="#292929"
    />
  </Icon>
)

export default Bowling
