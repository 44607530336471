// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const KioskVideo = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>kiosk-video</title>
      <path d="M13.946 17.788v2.239c0 0.411-0.336 0.747-0.746 0.747s-0.746-0.336-0.746-0.747v-2.239c0-0.411 0.336-0.747 0.746-0.747s0.746 0.336 0.746 0.747zM18.8 17.041c-0.41 0-0.746 0.336-0.746 0.747v2.239c0 0.411 0.336 0.747 0.746 0.747s0.746-0.336 0.746-0.747v-2.239c0-0.411-0.335-0.747-0.746-0.747zM27.2 10.646c0-0.458-0.185-0.854-0.537-1.146l-3.527-2.94c-0.279-0.233-0.592-0.346-0.956-0.346h-13.027c-0.412 0-0.766 0.146-1.056 0.438l-2.858 2.859c-0.292 0.29-0.438 0.644-0.438 1.055v1.622c0.001 0.825 0.669 1.494 1.494 1.494 0.693 0 1.276-0.472 1.444-1.112 0.134-0.506 0.862-0.506 0.994 0 0.168 0.64 0.751 1.112 1.444 1.112s1.276-0.472 1.444-1.112c0.134-0.506 0.861-0.506 0.994 0 0.168 0.64 0.751 1.112 1.444 1.112s1.276-0.472 1.444-1.112c0.134-0.506 0.861-0.506 0.994 0 0.168 0.64 0.751 1.112 1.444 1.112s1.276-0.472 1.444-1.112c0.134-0.506 0.861-0.506 0.994 0 0.168 0.64 0.751 1.112 1.444 1.112s1.276-0.472 1.444-1.112c0.134-0.506 0.861-0.506 0.994 0 0.168 0.64 0.751 1.112 1.444 1.112 0.824 0 1.493-0.669 1.493-1.494v-1.541zM5.921 25.627c-0.206 0-0.374 0.168-0.374 0.374v1.493c0 0.206 0.174 0.374 0.373 0.374h20.16c0.199 0 0.374-0.174 0.374-0.374v-1.493c0-0.2-0.168-0.374-0.374-0.374h-0.746v-10.854c-0.612-0.088-1.165-0.39-1.568-0.837-0.092 0.102-0.192 0.197-0.298 0.283v11.407h-6.907v-11.222c-0.207-0.129-0.396-0.286-0.56-0.469-0.163 0.182-0.352 0.339-0.56 0.469v11.222h-6.906v-11.407c-0.106-0.086-0.206-0.181-0.299-0.283-0.403 0.447-0.956 0.749-1.567 0.837v10.854h-0.746z" />
    </g>
  </Icon>
)

export default React.memo(KioskVideo)
