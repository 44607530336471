// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Harbour = props => (
  <Icon viewBox="0 0 60 60" {...props}>
    <style>{'.st0{clip-path:url(#SVGID_2_);}.st1{fill:#56A0BF;}.st2{fill:#88C9DB;}'}</style>
    <g>
      <defs>
        <rect id="SVGID_1_" width="60" height="60"/>
      </defs>
      <clipPath id="SVGID_2_">
        <rect id="SVGID_1_" width="60" height="60"/>
      </clipPath>
      <g className="st0">
        <path className="st1" d="M47.63,30.98c0,4.23-5.14,9.37-5.14,9.37l3.31,0.18c-0.71,1.37-1.78,2.72-3,3.98
			c-3.35,3.47-9.23,1.16-9.32-3.67L33.3,30.57c-0.04-1.94,1.47-3.56,3.41-3.68c1.9-0.11,3.8-0.28,5.69-0.48
			c1.1-0.12,1.93-1.04,1.93-2.15v-2.59c0-1.1-0.83-2.03-1.93-2.15c-2-0.22-4-0.39-6-0.5c-2.08-0.12-3.06-2.64-1.59-4.12
			c0.12-0.12,0.24-0.23,0.36-0.33c1.85-1.58,3-3.96,2.9-6.61C37.91,3.6,34.35,0.1,30,0c-0.01,0-0.03,0-0.04,0
			c-4.63-0.08-8.41,3.65-8.41,8.26c0,2.61,1.21,4.94,3.1,6.45c0.01,0.01,0.03,0.02,0.04,0.03c1.71,1.39,0.79,4.16-1.41,4.29
			c-1.25,0.07-2.5,0.17-3.75,0.29c-0.65,0.06-1.3,0.13-1.95,0.2c-1.09,0.12-1.91,1.05-1.91,2.14v2.59c0,1.1,0.83,2.02,1.93,2.15
			c1.9,0.21,3.8,0.37,5.69,0.48c1.94,0.12,3.44,1.73,3.41,3.68l-0.19,10.28c-0.09,4.83-5.96,7.14-9.32,3.67
			c-1.22-1.26-2.29-2.61-3-3.98l3.31-0.18c0,0-5.14-5.14-5.14-9.37c0,0-5.34,4.78-4.51,12.49L10.9,42c0,0,1.47,4.41,4.96,7.9
			C21.09,55.13,27.43,53.94,30,60c2.57-6.06,8.91-4.87,14.14-10.1c3.49-3.49,4.96-7.9,4.96-7.9l3.04,1.47
			C52.97,35.76,47.63,30.98,47.63,30.98z M30,11.99c-0.06,0-0.12,0-0.18,0c-2.06,0-3.73-1.67-3.73-3.73c0-2.06,1.67-3.73,3.73-3.73
			c0.06,0,0.12,0,0.18,0c1.97,0.1,3.54,1.72,3.54,3.72C33.54,10.26,31.97,11.89,30,11.99z"/>
        <path className="st2" d="M26.09,8.26c0,2.06,1.67,3.73,3.73,3.73c0.06,0,0.12,0,0.18-0.01V60c-2.57-6.06-8.91-4.87-14.14-10.1
			C12.37,46.41,10.9,42,10.9,42l-3.04,1.47c-0.83-7.71,4.51-12.49,4.51-12.49c0,4.22,5.14,9.37,5.14,9.37l-3.31,0.18
			c0.71,1.37,1.78,2.72,3,3.99c3.35,3.47,9.23,1.16,9.32-3.67l0.19-10.28c0.03-1.94-1.47-3.56-3.41-3.68
			c-1.9-0.11-3.8-0.28-5.69-0.48c-1.1-0.12-1.93-1.04-1.93-2.15v-2.59c0-1.1,0.82-2.02,1.91-2.14c0.65-0.07,1.3-0.14,1.95-0.2
			c1.25-0.12,2.5-0.21,3.75-0.29c2.2-0.13,3.12-2.9,1.41-4.29c-0.01-0.01-0.03-0.02-0.04-0.03c-1.89-1.51-3.1-3.84-3.1-6.45
			c0-4.61,3.78-8.34,8.41-8.26c0.01,0,0.03,0,0.04,0v4.54c-0.06,0-0.12,0-0.18,0C27.76,4.54,26.09,6.21,26.09,8.26z"/>
      </g>
    </g>
  </Icon>
)

export default React.memo(Harbour)
