// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Lifestyle = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>lifestyle</title>
      <path d="M11.257 8.218c-0.403-0.348-0.927-0.559-1.501-0.559-1.271 0-2.3 1.030-2.3 2.301 0 0.344 0.078 0.67 0.214 0.964 0.995-1.128 2.213-2.054 3.587-2.705zM16 28.544c5.68 0 10.284-4.604 10.284-10.284 0-5.086-3.693-9.307-8.544-10.134v-1.927h0.892c0.295 0 0.534-0.316 0.534-0.705s-0.239-0.705-0.534-0.705h-5.264c-0.295 0-0.535 0.316-0.535 0.705s0.239 0.705 0.535 0.705h0.892v1.927c-0.866 0.148-1.694 0.403-2.472 0.753-1.407 0.633-2.645 1.571-3.63 2.732-1.522 1.793-2.442 4.113-2.442 6.649 0 5.68 4.605 10.284 10.284 10.284zM9.941 12.251c0.579-0.583 1.241-1.084 1.967-1.482 1.216-0.667 2.61-1.048 4.092-1.048 4.709 0 8.539 3.831 8.539 8.539s-3.83 8.54-8.539 8.54c-4.709 0-8.54-3.831-8.54-8.54-0-2.342 0.948-4.466 2.48-6.010zM10.837 23.424c0.33 0.33 0.692 0.629 1.081 0.892 0.194 0.131 0.395 0.253 0.602 0.365 1.035 0.562 2.22 0.882 3.48 0.882 0.158 0 0.314-0.005 0.469-0.015 0.775-0.049 1.518-0.219 2.209-0.491 0.276-0.109 0.545-0.235 0.803-0.375 0.207-0.112 0.408-0.234 0.602-0.365 0.388-0.263 0.75-0.561 1.081-0.892 1.321-1.321 2.139-3.147 2.139-5.163s-0.817-3.842-2.139-5.163c-0.33-0.331-0.692-0.629-1.081-0.892-0.194-0.131-0.395-0.253-0.602-0.366-0.259-0.141-0.527-0.265-0.803-0.375-0.691-0.272-1.434-0.443-2.209-0.492-0.155-0.010-0.311-0.015-0.469-0.015-1.26 0-2.446 0.319-3.48 0.881-0.207 0.112-0.408 0.235-0.602 0.366-0.389 0.262-0.751 0.561-1.081 0.892-1.321 1.321-2.139 3.147-2.139 5.163s0.818 3.842 2.139 5.164zM16 12.45v5.471l-5.5 2.114c-0.974-3.792 1.689-7.584 5.5-7.584z" />
    </g>
  </Icon>
)

export default React.memo(Lifestyle)
