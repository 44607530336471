import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const CrossCountrySkiing = props => (
  <Icon viewBox="0 0 37 55" {...props}>
    <path
      d="M13 55C11.8956 55 11 52.8768 11 50.2586V4.7414C11 2.12319 11.8956 0 13 0C14.1044 0 15 2.12319 15 4.7414V50.2586C15 52.8768 14.1044 55 13 55Z"
      fill="#F3654D"
    />
    <path
      d="M13 25C13.5525 25 14 25.8055 14 26.8V33.0955C14 33.595 13.775 34 13.4975 34H12.5025C12.225 34 12 33.595 12 33.0955V26.8C11.9999 25.8055 12.4475 25 13 25Z"
      fill="#526675"
    />
    <path
      d="M2.93478 55.0001C2.41784 55.0001 2 54.5834 2 54.0679V52.2034C2 51.6879 2.41784 51.2712 2.93478 51.2712C3.45173 51.2712 3.86957 51.6879 3.86957 52.2034V54.0679C3.86957 54.5834 3.45173 55.0001 2.93478 55.0001Z"
      fill="#C1C4C6"
    />
    <path
      d="M3 52C2.44699 52 2 51.5662 2 51.0294V19.9706C2 19.4338 2.44699 19 3 19C3.55301 19 4 19.4338 4 19.9706V51.0294C4 51.5662 3.55301 52 3 52Z"
      fill="#C1C4C6"
    />
    <path
      d="M5 52H1C0.448029 52 0 51.7013 0 51.3333V50.6667C0 50.2987 0.448029 50 1 50H5C5.55197 50 6 50.2987 6 50.6667V51.3333C6 51.7013 5.55197 52 5 52Z"
      fill="#C1C4C6"
    />
    <path
      d="M5 20H1V1.90477C1 0.852407 1.89502 0 3 0C4.10498 0 5 0.852407 5 1.90477V20Z"
      fill="#526675"
    />
    <path
      d="M33.9348 55.0001C33.4178 55.0001 33 54.5834 33 54.0679V52.2034C33 51.6879 33.4178 51.2712 33.9348 51.2712C34.4517 51.2712 34.8696 51.6879 34.8696 52.2034V54.0679C34.8696 54.5834 34.4517 55.0001 33.9348 55.0001Z"
      fill="#C1C4C6"
    />
    <path
      d="M34 52C33.447 52 33 51.5662 33 51.0294V19.9706C33 19.4338 33.447 19 34 19C34.553 19 35 19.4338 35 19.9706V51.0294C35 51.5662 34.553 52 34 52Z"
      fill="#C1C4C6"
    />
    <path
      d="M36 52H32C31.448 52 31 51.7013 31 51.3333V50.6667C31 50.2987 31.448 50 32 50H36C36.552 50 37 50.2987 37 50.6667V51.3333C37 51.7013 36.552 52 36 52Z"
      fill="#C1C4C6"
    />
    <path
      d="M36 20H32V1.90477C32 0.852407 32.895 0 34 0C35.105 0 36 0.852407 36 1.90477V20Z"
      fill="#526675"
    />
    <rect x="11" y="50" width="4" height="5" fill="#F3654D" />
    <path d="M11 17L15 13V15L11 19V17Z" fill="#F3654D" />
    <path d="M11 15L15 11V13L11 17V15Z" fill="#F1F9FF" />
    <rect x="21" y="50" width="4" height="5" fill="#F3654D" />
    <path
      d="M23 55C21.8956 55 21 52.8768 21 50.2586V4.7414C21 2.12319 21.8956 0 23 0C24.1044 0 25 2.12319 25 4.7414V50.2586C25 52.8768 24.1044 55 23 55Z"
      fill="#F3654D"
    />
    <path
      d="M23 25C23.5525 25 24 25.8055 24 26.8V33.0955C24 33.595 23.775 34 23.4975 34H22.5025C22.225 34 22 33.595 22 33.0955V26.8C21.9999 25.8055 22.4475 25 23 25Z"
      fill="#526675"
    />
    <path d="M21 15L25 11V13L21 17V15Z" fill="#F1F9FF" />
  </Icon>
)

export default CrossCountrySkiing
