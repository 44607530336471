// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const House = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>house</title>
      <path d="M14.8 26.667v-6.909h3.4v6.909h6.8v-10.364l-8.5-8.636-8.5 8.636v10.364z" />
    </g>
  </Icon>
)

export default React.memo(House)
