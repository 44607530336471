// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Walker = props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g transform="matrix(1.25 0 0 -1.25 0 45)">
      <g>
        <g>
          <g>
            <path
              style={{ fill: '#F7DECE' }}
              d="M156.465-1.103c-13.733-3.038,11.992-17.397,7.782-31.164c-2.867-9.535,2.56,0,11.378,0
              c0,0,7.134-14.598,4.813-36.705c-2.355-22.073,28.126-29.764,30.458-7.657c2.321,22.084-15.758,33.997-5.814,51.2
              C228.885,15.748,156.465-1.103,156.465-1.103"
            />
            <path
              style={{ fill: '#F7DECE' }}
              d="M232.514-9.511c0-25.134-20.378-45.511-45.511-45.511s-45.511,20.378-45.511,45.511
              S161.87,36,187.003,36S232.514,15.622,232.514-9.511"
            />
          </g>
          <path
            style={{ fill: '#292F33' }}
            d="M280.415-18.272c-11.799,0.58-23.222,1.923-42.735,27.056
            c-12.971,16.725-25.088,27.443-55.068,27.17c-31.835-0.307-58.493-27.386-38.07-40.937c8.909-5.905,16.384-3.106,30.686-7.111
            c22.471-6.292,25.884-30.197,46.182-35.567C261.152-58.151,292.225-18.852,280.415-18.272"
          />
          <path
            style={{ fill: '#EEC2AD' }}
            d="M251.811-202.82c-6.292,0-11.378,5.086-11.378,11.378c0,51.428-53.032,105.21-53.567,105.745
            c-4.426,4.449-4.426,11.651,0.023,16.088c4.449,4.437,11.639,4.426,16.077-0.011c2.458-2.458,60.223-60.928,60.223-121.822
            C263.189-197.734,258.103-202.82,251.811-202.82"
          />
          <path
            style={{ fill: '#2A6797' }}
            d="M160.789-362.108c-12.572,0-22.756,10.194-22.756,22.756v79.644c0,3.959,1.035,7.851,3.004,11.287
            l45.511,79.644c6.224,10.911,20.139,14.723,31.039,8.465c10.911-6.235,14.7-20.127,8.465-31.039l-42.507-74.399v-73.603
            C183.544-351.914,173.35-362.108,160.789-362.108"
          />
          <path
            style={{ fill: '#4289C1' }}
            d="M263.2-327.975c-6.679,0-13.289,2.924-17.772,8.545l-45.511,56.889
            c-2.287,2.833-3.834,6.178-4.551,9.739l-11.378,56.889c-2.469,12.322,5.518,24.326,17.84,26.783
            c12.379,2.492,24.326-5.53,26.783-17.84l10.297-51.473l42.052-52.565c7.851-9.808,6.258-24.132-3.561-31.983
            C273.213-326.337,268.195-327.975,263.2-327.975"
          />
          <path
            style={{ fill: '#9268CA' }}
            d="M240.433-180.064c0,0,0-11.378-22.756-11.378h-45.511
            c-22.756,68.267-4.085,136.533,22.756,136.533c34.133,0,34.133-45.511,45.511-102.4"
          />
          <path
            style={{ fill: '#F7DECE' }}
            d="M183.544-100.42c-0.011-0.512-0.751-51.792-40.96-81.954l0.114-0.159
            c-2.765-2.071-4.665-5.222-4.665-8.943c0-6.292,5.097-11.378,11.378-11.378c2.56,0,4.813,1.001,6.713,2.435l0.114-0.159
            c49.493,37.126,50.062,97.564,50.062,100.124L183.544-100.42z"
          />
          <g>
            <path
              style={{ fill: '#292F33' }}
              d="M299.382-317.257c3.083,3.083,1.82,7.691-1.468,10.695c-5.268,4.813-7.657,6.531-7.657,6.531
              c-2.901,1.911-9.933,4.745-12.857,1.832c-21.311-21.22-37.592-51.553-38.184-62.123c-0.353-6.235,7.339-9.728,11.708-5.359
              C263.815-352.79,288.209-328.419,299.382-317.257"
            />
            <path
              style={{ fill: '#292F33' }}
              d="M182.577-372.496c4.358-0.034,6.758,4.107,6.576,8.567c-0.273,7.122-0.728,10.024-0.728,10.024
              c-0.671,3.413-3.584,10.411-7.726,10.445c-30.06,0.262-63.101-9.455-71.031-16.452c-4.688-4.142-1.775-12.06,4.403-12.106
              C132.31-372.132,166.785-372.383,182.577-372.496"
            />
          </g>
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </Icon>
)

export default React.memo(Walker)
