import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const TransportLong = props => (
  <Icon width="60" height="58" viewBox="0 0 60 58" {...props}>
    <path
      d="M53.8143 18.1427H40.1258C39.6175 18.1427 39.2057 17.7307 39.2057 17.2225C39.2057 16.7144 39.6175 16.3024 40.1258 16.3024H53.8142C55.8514 16.3024 57.6518 14.9305 58.1924 12.9662C58.3273 12.4764 58.8335 12.1883 59.3238 12.3233C59.8137 12.4581 60.1016 12.9647 59.9668 13.4545C59.2071 16.2148 56.6771 18.1427 53.8143 18.1427Z"
      fill="#99A5AD"
    />
    <path
      d="M19.8741 18.1427H6.1857C3.32274 18.1427 0.792745 16.2148 0.0332065 13.4545C-0.101623 12.9645 0.186316 12.4581 0.676195 12.3233C1.16632 12.1883 1.67264 12.4764 1.80759 12.9662C2.34801 14.9305 4.1484 16.3024 6.18582 16.3024H19.8742C20.3825 16.3024 20.7943 16.7144 20.7943 17.2225C20.7943 17.7307 20.3824 18.1427 19.8741 18.1427Z"
      fill="#99A5AD"
    />
    <path
      d="M30 8.0168C29.4917 8.0168 29.0798 7.60483 29.0798 7.09667V0.920132C29.0798 0.411974 29.4917 0 30 0C30.5082 0 30.9201 0.411974 30.9201 0.920132V7.09667C30.9201 7.60483 30.5082 8.0168 30 8.0168Z"
      fill="#99A5AD"
    />
    <path
      d="M41.4138 9.99877H34.395C33.8867 9.99877 33.4749 9.5868 33.4749 9.07864C33.4749 8.57048 33.8867 8.15851 34.395 8.15851H41.4138C41.922 8.15851 42.3339 8.57048 42.3339 9.07864C42.3339 9.5868 41.9219 9.99877 41.4138 9.99877Z"
      fill="#99A5AD"
    />
    <path
      d="M25.6045 9.99877H18.5858C18.0775 9.99877 17.6656 9.5868 17.6656 9.07864C17.6656 8.57048 18.0775 8.15851 18.5858 8.15851H25.6045C26.1128 8.15851 26.5247 8.57048 26.5247 9.07864C26.5247 9.5868 26.1128 9.99877 25.6045 9.99877Z"
      fill="#99A5AD"
    />
    <path
      d="M30 27.3484C35.5923 27.3484 40.1258 22.8149 40.1258 17.2225C40.1258 11.6302 35.5923 7.09668 30 7.09668C24.4076 7.09668 19.8741 11.6302 19.8741 17.2225C19.8741 22.8149 24.4076 27.3484 30 27.3484Z"
      fill="#DFDFDD"
    />
    <path
      d="M35.4843 14.1856L33.4023 16.2688H26.5983L24.5151 14.1856C25.4683 13.2324 26.6068 12.5797 27.8141 12.2264C28.5269 12.0178 29.263 11.9135 30.0003 11.9135C31.9841 11.9135 33.9691 12.6705 35.4843 14.1856Z"
      fill="#99A5AD"
    />
    <path
      d="M48.3476 26.8665C51.0107 26.8665 53.1696 24.7076 53.1696 22.0445C53.1696 19.3814 51.0107 17.2225 48.3476 17.2225C45.6845 17.2225 43.5256 19.3814 43.5256 22.0445C43.5256 24.7076 45.6845 26.8665 48.3476 26.8665Z"
      fill="#99A5AD"
    />
    <path
      d="M11.6522 26.8665C14.3153 26.8665 16.4742 24.7076 16.4742 22.0445C16.4742 19.3814 14.3153 17.2225 11.6522 17.2225C8.98914 17.2225 6.83026 19.3814 6.83026 22.0445C6.83026 24.7076 8.98914 26.8665 11.6522 26.8665Z"
      fill="#99A5AD"
    />
    <path
      d="M11.6523 25.0145C13.2925 25.0145 14.6222 23.6848 14.6222 22.0445C14.6222 20.4043 13.2925 19.0746 11.6523 19.0746C10.012 19.0746 8.68231 20.4043 8.68231 22.0445C8.68231 23.6848 10.012 25.0145 11.6523 25.0145Z"
      fill="white"
    />
    <path
      d="M48.3476 25.0145C49.9879 25.0145 51.3176 23.6848 51.3176 22.0445C51.3176 20.4043 49.9879 19.0746 48.3476 19.0746C46.7074 19.0746 45.3777 20.4043 45.3777 22.0445C45.3777 23.6848 46.7074 25.0145 48.3476 25.0145Z"
      fill="white"
    />
    <path
      d="M35.4843 14.1856L33.4023 16.2688H30.0003V11.9135C31.9841 11.9135 33.9691 12.6705 35.4843 14.1856Z"
      fill="#99A5AD"
    />
    <path
      d="M29.9999 24.4825C32.6573 24.4825 34.8115 22.3283 34.8115 19.671C34.8115 17.0136 32.6573 14.8594 29.9999 14.8594C27.3426 14.8594 25.1884 17.0136 25.1884 19.671C25.1884 22.3283 27.3426 24.4825 29.9999 24.4825Z"
      fill="white"
    />
    <path
      d="M30.0003 20.591C29.9402 20.591 29.8788 20.5848 29.8199 20.5726C29.761 20.5615 29.7034 20.5431 29.6482 20.521C29.593 20.4977 29.539 20.4695 29.4887 20.4364C29.4384 20.402 29.3918 20.364 29.3487 20.3211C29.307 20.2794 29.2678 20.2315 29.2346 20.1812C29.2015 20.1321 29.1733 20.0781 29.1499 20.0229C29.1268 19.9677 29.1096 19.9088 29.0972 19.8512C29.0862 19.7911 29.08 19.7309 29.08 19.6708C29.08 19.6107 29.0862 19.5506 29.0972 19.4905C29.1096 19.4328 29.1266 19.3739 29.1499 19.3187C29.1733 19.2635 29.2016 19.2095 29.2346 19.1605C29.2678 19.1102 29.307 19.0623 29.3487 19.0206C29.3918 18.9777 29.4383 18.9396 29.4887 18.9053C29.539 18.8722 29.593 18.8439 29.6482 18.8206C29.7034 18.7986 29.761 18.7801 29.8199 18.7691C29.9389 18.7446 30.0604 18.7446 30.1794 18.7691C30.2382 18.7801 30.2959 18.7986 30.351 18.8206C30.4075 18.8439 30.4603 18.8722 30.5106 18.9053C30.5608 18.9396 30.6076 18.9777 30.6505 19.0206C30.6934 19.0623 30.7315 19.1102 30.7646 19.1605C30.7978 19.2095 30.8259 19.2635 30.8492 19.3187C30.8724 19.3739 30.8897 19.4328 30.902 19.4905C30.9144 19.5506 30.9204 19.6107 30.9204 19.6708C30.9204 19.7309 30.9143 19.7911 30.902 19.8512C30.8897 19.9088 30.8726 19.9677 30.8492 20.0229C30.8259 20.0781 30.7977 20.1321 30.7646 20.1812C30.7315 20.2315 30.6934 20.2794 30.6505 20.3211C30.6076 20.364 30.5609 20.402 30.5106 20.4364C30.4603 20.4695 30.4075 20.4977 30.351 20.521C30.2959 20.5431 30.2382 20.5615 30.1794 20.5726C30.1205 20.5848 30.0592 20.591 30.0003 20.591Z"
      fill="#DFDFDD"
    />
    <path
      d="M39.8514 36.163V38.1359H20.8517V36.163C20.8517 35.6268 21.2886 35.19 21.8247 35.19H38.8784C39.4154 35.19 39.8514 35.6268 39.8514 36.163Z"
      fill="#FFD400"
    />
    <path
      d="M41.7031 36.163V49.5258C41.7031 51.0922 40.6586 52.4145 39.2285 52.8345C38.9205 52.9245 38.5939 52.9734 38.2555 52.9734H37.4298H23.2733H22.4476C22.1092 52.9734 21.7827 52.9245 21.4746 52.8345C20.0445 52.4145 19 51.0922 19 49.5258V36.163C19 34.6033 20.2651 33.3382 21.8247 33.3382H26.1898H34.5142H38.8784C40.4389 33.3382 41.7031 34.6033 41.7031 36.163ZM39.8514 45.0378V38.1359V36.163C39.8514 35.6268 39.4154 35.19 38.8784 35.19H21.8247C21.2886 35.19 20.8517 35.6268 20.8517 36.163V38.1359V45.0378C20.8517 45.9182 21.568 46.6337 22.4476 46.6337H38.2555C39.1359 46.6337 39.8514 45.9182 39.8514 45.0378ZM39.508 49.8035C39.508 49.1765 38.9996 48.6672 38.3717 48.6672C37.7446 48.6672 37.2354 49.1765 37.2354 49.8035C37.2354 50.4314 37.7446 50.9398 38.3717 50.9398C38.9996 50.9398 39.508 50.4314 39.508 49.8035ZM23.4677 49.8035C23.4677 49.1765 22.9593 48.6672 22.3314 48.6672C21.7035 48.6672 21.1951 49.1765 21.1951 49.8035C21.1951 50.4314 21.7035 50.9398 22.3314 50.9398C22.9593 50.9398 23.4677 50.4314 23.4677 49.8035Z"
      fill="#40BAF3"
    />
    <path
      d="M39.8514 38.1359V45.0379C39.8514 45.9183 39.136 46.6337 38.2555 46.6337H22.4476C21.568 46.6337 20.8517 45.9183 20.8517 45.0379V38.1359H39.8514V38.1359Z"
      fill="#9DD2FB"
    />
    <path
      d="M27.7478 38.1359H20.8517V36.163C20.8517 35.6268 21.2886 35.19 21.8247 35.19H24.8018L27.7478 38.1359Z"
      fill="#DDB74B"
    />
    <path
      d="M38.3717 48.6672C38.9996 48.6672 39.508 49.1765 39.508 49.8035C39.508 50.4314 38.9996 50.9398 38.3717 50.9398C37.7446 50.9398 37.2354 50.4314 37.2354 49.8035C37.2354 49.1765 37.7446 48.6672 38.3717 48.6672Z"
      fill="white"
    />
    <path d="M33.1296 31L34.5142 33.3382H26.1898L27.5744 31H33.1296Z" fill="#027A96" />
    <path
      d="M22.3314 48.6672C22.9593 48.6672 23.4677 49.1765 23.4677 49.8035C23.4677 50.4314 22.9593 50.9398 22.3314 50.9398C21.7035 50.9398 21.1951 50.4314 21.1951 49.8035C21.1951 49.1765 21.7035 48.6672 22.3314 48.6672Z"
      fill="white"
    />
    <path d="M39.2813 54.6924H21.4119V56.46H39.2813V54.6924Z" fill="#B3B3B3" />
    <path
      d="M39.2698 52.9734L41.6492 58H39.3186L37.4298 52.9734H38.2555C38.5939 52.9734 38.9205 52.9246 39.2285 52.8345L39.2698 52.9734Z"
      fill="#E6E6E6"
    />
    <path
      d="M23.2733 52.9734L21.3854 58H19.0547L21.4333 52.9734L21.4746 52.8345C21.7826 52.9246 22.1092 52.9734 22.4476 52.9734H23.2733Z"
      fill="#E6E6E6"
    />
    <path
      d="M39.8514 38.1359V45.0379C39.8514 45.9183 39.136 46.6337 38.2556 46.6337H36.2464L27.7478 38.1359H39.8514Z"
      fill="#BAECFD"
    />
  </Icon>
)

export default TransportLong
