// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const Exchange = props => (
  <Icon viewBox="0 0 98.5 92.8" {...props}>
    <path d="M4.6,28.1h43.4l-13,13c-1.4,1.4-1.4,3.7,0,5.1c0.7,0.7,1.6,1.1,2.6,1.1s1.9-0.4,2.6-1.1L59.4,27c1.4-1.4,1.4-3.7,0-5.1 L40.2,2.7c-1.4-1.4-3.7-1.4-5.1,0s-1.4,3.7,0,5.1l13,13H4.6c-2,0-3.6,1.6-3.6,3.6S2.6,28.1,4.6,28.1z" />
    <path
      d="M93.5,64.2H50.1l13-13c1.4-1.4,1.4-3.7,0-5.1c-1.4-1.4-3.7-1.4-5.1,0L38.8,65.3c-0.2,0.2-0.3,0.4-0.5,0.6
	c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4s-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.5-0.1,0.7l0,0l0,0
	c0,0.2,0,0.5,0.1,0.7c0,0.1,0.1,0.2,0.1,0.3C38,69,38,69.1,38,69.2c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.2,0.3,0.4,0.5,0.6L58,89.6c0.7,0.7,1.6,1.1,2.6,1.1s1.9-0.4,2.6-1.1c1.4-1.4,1.4-3.7,0-5.1l-13-13h43.4c2,0,3.6-1.6,3.6-3.6
	C97.1,65.9,95.5,64.2,93.5,64.2z"
    />
  </Icon>
)

export default React.memo(Exchange)
