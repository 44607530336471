// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const LightRail = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>light-rail</title>
      <path d="M13.926 7.715h-0.414c-0.336 0-0.786 0.056-1.023 0.321-0.198 0.222-0.18 0.516-0.179 0.519 0.030 0.266-0.162 0.506-0.429 0.537-0.018 0.002-0.036 0.002-0.054 0.002-0.244 0-0.454-0.184-0.482-0.431-0.008-0.074-0.065-0.73 0.421-1.274 0.382-0.427 0.97-0.644 1.746-0.644h4.977c0.776 0 1.364 0.217 1.746 0.644 0.486 0.544 0.43 1.201 0.421 1.274-0.028 0.248-0.238 0.431-0.482 0.431-0.018 0-0.036-0.001-0.054-0.002-0.264-0.030-0.455-0.266-0.43-0.53 0.002-0.021 0.018-0.311-0.186-0.533-0.189-0.205-0.541-0.314-1.016-0.314h-0.414zM21.894 30.745c0.463 0 0.618-0.306 0.344-0.679l-0.548-0.747c-0.274-0.374-0.877-0.679-1.34-0.679h-1.262c-0.463 0-0.618 0.306-0.344 0.679l0.548 0.747c0.274 0.374 0.877 0.679 1.34 0.679h1.262zM11.79 28.639c-0.463 0-1.066 0.306-1.34 0.679l-0.548 0.747c-0.274 0.374-0.119 0.679 0.344 0.679h1.263c0.463 0 1.066-0.306 1.34-0.679l0.548-0.747c0.274-0.374 0.119-0.679-0.344-0.679h-1.263zM24.421 13.816c0 4.197 0 5.994 0 10.191 0 1.363-1.106 2.879-2.489 3.206-3.906 0.871-7.958 0.871-11.865 0-1.382-0.327-2.489-1.843-2.489-3.206 0-4.197 0-5.994 0-10.191 0-1.363 1.106-2.879 2.489-3.206 0.936-0.209 1.881-0.365 2.83-0.473 0.358-0.588 1.043-0.992 1.839-0.992h2.526c0.796 0 1.482 0.404 1.839 0.992 0.949 0.109 1.894 0.264 2.83 0.474 1.383 0.326 2.49 1.842 2.49 3.206zM13.122 23.334c0-0.852-0.691-1.542-1.542-1.542s-1.542 0.691-1.542 1.542c0 0.852 0.691 1.542 1.542 1.542s1.542-0.69 1.542-1.542zM19.125 19.755c0.23 0 0.541-0.14 0.685-0.362 1.327-2.075 2.174-4.372 1.958-6.267-0.022-0.205-0.278-0.424-0.547-0.483-3.832-0.819-6.609-0.824-10.441-0.005-0.269 0.060-0.526 0.266-0.547 0.471-0.215 1.894 0.63 4.21 1.958 6.286 0.144 0.222 0.456 0.362 0.685 0.362 2.082-0.001 4.166-0.001 6.249-0.001zM21.964 23.334c0-0.852-0.691-1.542-1.542-1.542s-1.542 0.691-1.542 1.542c0 0.852 0.691 1.542 1.542 1.542s1.542-0.69 1.542-1.542z" />
    </g>
  </Icon>
)

export default React.memo(LightRail)
