// THIS IS AN AUTOMATED FILE, PLEASE DO NOT EDIT
import React from 'react'
import Icon from '../../../ui/svg-wrapper'

const LivingEnvironment = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <title>living-environment</title>
      <path d="M25.867 26.187v-14.558l-6.019-3.57v18.198h-1.12v-8.329c0-0.91-0.7-1.61-1.61-1.61h-3.779v9.099h-0.42v-12.249c-0.28-0.49-0.77-0.84-1.4-0.84h-3.5c-0.91 0-1.61 0.7-1.61 1.61v12.389h-1.61v1.54h22.398v-1.54c0.070-0.14-1.33-0.14-1.33-0.14zM23.278 12.048h1.82v1.82h-1.82v-1.82zM23.278 15.198h1.82v1.82h-1.82v-1.82zM23.278 18.418h1.82v1.82h-1.82v-1.82zM23.278 21.567h1.82v1.82h-1.82v-1.82zM20.688 12.048h1.82v1.82h-1.82v-1.82zM20.688 15.198h1.82v1.82h-1.82v-1.82zM20.688 18.418h1.82v1.82h-1.82v-1.82zM20.688 21.567h1.82v1.82h-1.82v-1.82zM10.19 14.008h1.61v1.61h-1.61v-1.61zM10.19 16.878h1.61v1.61h-1.61v-1.61zM10.19 19.747h1.61v1.61h-1.61v-1.61zM10.19 22.617h1.61v1.61h-1.61v-1.61zM7.53 14.008h1.61v1.61h-1.61v-1.61zM7.53 16.878h1.61v1.61h-1.61v-1.61zM7.53 19.747h1.61v1.61h-1.61v-1.61zM7.53 22.617h1.61v1.61h-1.61v-1.61z" />
    </g>
  </Icon>
)

export default React.memo(LivingEnvironment)
