import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import PropTypes from 'prop-types'

import generateURL from './generateURL'

const InternalLink = ({ children, params, route, ...props }) => {
  const router = useRouter()
  const { query } = router
  let routeHref = ''
  let routeAs = ''
  const allParams = {
    ...query,
    ...params,
  }

  // we are doing this to support the legacy
  switch (route) {
    case 'index':
      routeHref = '/'
      routeAs = '/'
      break
    case 'oversikt':
      routeHref = '/[id]'
      routeAs = `/${allParams.id}`
      break
    case 'aboutNoId':
      routeHref = '/om'
      routeAs = '/om'
      break
    case 'about':
      routeHref = '/[id]/om'
      routeAs = `/${allParams.id}/om`
      break
    case 'termsNoId':
      routeHref = '/brukervilkar'
      routeAs = '/brukervilkar'
      break
    case 'terms':
      routeHref = '/[id]/brukervilkar'
      routeAs = `/${allParams.id}/brukervilkar`
      break
    case 'privacy':
      routeHref = '/[id]/personvern'
      routeAs = `/${allParams.id}/personvern`
      break
    case 'sourcesNoId':
      routeHref = '/kilder'
      routeAs = '/kilder'
      break
    case 'sources':
      routeHref = '/[id]/kilder'
      routeAs = `/${allParams.id}/kilder`
      break
    case 'map':
      if (allParams.filter) {
        routeHref = '/[id]/kart/[filter]'
        routeAs = `/${allParams.id}/kart/${allParams.filter}`
      } else {
        routeHref = '/[id]/kart'
        routeAs = `/${allParams.id}/kart`
      }
      break
    case 'address':
      routeHref = '/[id]/adressesok'
      routeAs = `/${allParams.id}/adressesok`
      break
    case 'comparison':
      routeHref = '/[id]/sammenlign/[finnkode]/[comparisonId]'
      routeAs = `/${allParams.id}/sammenlign/${allParams.finnkode}/${allParams.comparisonId}`
      break
    case 'comparison-redirect':
      routeHref = '/[id]/sammenlign'
      routeAs = `/${allParams.id}/sammenlign`
      break

    case 'travel-time':
      if (allParams.toAddressId) {
        routeHref = '/[id]/reisetid/[toAddressId]'
        routeAs = `/${allParams.id}/reisetid/${allParams.toAddressId}`
      } else {
        routeHref = '/[id]/reisetid'
        routeAs = `/${allParams.id}/reisetid`
      }
      break
    case 'travel-time-redirect':
      routeHref = '/[id]/reisetid'
      routeAs = `/${allParams.id}/reisetid`
      break
    case 'address-travel-time':
      routeHref = '/[id]/reisetid-adressesok'
      routeAs = `/${allParams.id}/reisetid-adressesok`
      break
    case 'family':
      routeHref = '/[id]/familie'
      routeAs = `/${allParams.id}/familie`
      break
    case 'transport':
      routeHref = '/[id]/transport'
      routeAs = `/${allParams.id}/transport`
      break
    case 'people':
      routeHref = '/[id]/menneskene'
      routeAs = `/${allParams.id}/menneskene`
      break
    case 'leisure':
      routeHref = '/[id]/fritid'
      routeAs = `/${allParams.id}/fritid`
      break
    case 'activities':
      routeHref = '/[id]/aktiviteter'
      routeAs = `/${allParams.id}/aktiviteter`
      break
    case 'shopping':
      routeHref = '/[id]/handel'
      routeAs = `/${allParams.id}/handel`
      break
    case 'environment':
      routeHref = '/[id]/bomiljo'
      routeAs = `/${allParams.id}/bomiljo`
      break
    case 'businesses':
      routeHref = '/[id]/bedriftsinfo'
      routeAs = `/${allParams.id}/bedriftsinfo`
      break

    default:
      routeHref = '/'
      routeAs = '/'
  }

  const as = generateURL(routeAs, allParams)
  const href = generateURL(routeHref, allParams)

  return (
    <Link {...props} href={href} as={as}>
      {children}
    </Link>
  )
}

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  route: PropTypes.string.isRequired,
}

InternalLink.defaultProps = {
  params: {},
}

export default InternalLink
