import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InputWrapper = styled.div`
  position: relative;
`
export const InputEl = styled.input`
  appearance: none !important;
  font-size: 18px;
  height: 43px;
  padding: 6px 10px 6px 10px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.borderColor};
  color: ${({ theme }) => theme.inputColor};

  ${({ stretch }) =>
    stretch &&
    `
      width: 100%;
    `};

  ${({ rounded }) =>
    rounded &&
    `
      border-radius: 4px;
    `};
`

const Input = ({ stretch, onChange, onKeyDown, readOnly, ...rest }) => (
  <InputWrapper>
    <InputEl stretch onChange={onChange} onKeyDown={onKeyDown} readOnly={readOnly} {...rest} />
  </InputWrapper>
)

Input.propTypes = {
  stretch: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  readOnly: PropTypes.bool,
  'aria-label': PropTypes.string.isRequired,
}

Input.defaultProps = {
  stretch: false,
  readOnly: false,
  onKeyDown: () => {},
  onChange: () => {},
}

export default Input
